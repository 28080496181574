
import React from 'react';
import { Button } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import TopNavbar from '../HeaderAndFooter/TopHeader';
import LogoComponent from '../HeaderAndFooter/PageLogo';
import SocialMediaSidebar from '../MenuBar/SocialMediaSidebar';
import MenuBar from '../MenuBar/MenuBar';
import Footer from '../HeaderAndFooter/Footer';
import ReactPlayer from "react-player";


const AntiAvest = () => {
  const handleDownload = () => {
    // window.location.href = 'https://www.avast.com/en-us/free-antivirus-download';
    window.location.href = 'SoftwareHere/homesoft/_Easypcsoft_Avast_Antivirus_2020_Premier_20.1.2397.zip';
  };



    const handleyoutubevideos = () => {
    
      window.location.href = 'https://youtu.be/_oksvYAwv5g';
    };
  

  



  return (
    <div className="background-image" >
      <TopNavbar />
      <LogoComponent />
      <SocialMediaSidebar />

      <div className="main-layout" >
        <MenuBar />
      </div>

      <div className="container" style={{ marginTop: '50px' }}>
        <div className="text-area" >
          <div className='text-mainArea'> 
          <h2 style={{ textAlign: 'center', color: '#d7ce06', marginTop: '10px' }}>Overview of Avast Antivirus for Windows</h2>

            <p style={{ paddingLeft: '5px' }}>
            Avast Antivirus is an excellent security solution widely used to protect Windows devices from malware, viruses, and ransomware, among other online threats. The solution is endowed with a stream of features that ensure comprehensive security and are user-friendly.
            </p>
            
            <h2 style={{ paddingLeft: '20px' , color: '#d7ce06' }}>Key Features</h2>
            <ul>
              <li><strong>Real-Time Protection:</strong> Scans for malware and threats as they emerge..</li>
              <li><strong>Behavior Shield:</strong> Watches applications for suspicious behavior.</li>
              <li><strong>Ransomware Shield:</strong> Protects files and folders from unauthorized access.</li>
              <li><strong>Web Shield:</strong> Scans URLs and websites to block malicious links.</li>
              <li><strong>Email Shield:</strong> Checks incoming and outgoing emails for threats.</li>
              <li><strong>Firewall:</strong> Keeps network traffic under observation and restricts unauthorized access.</li>
              <li><strong>Wi-Fi Inspector:</strong> scans for vulnerabilities on your network.</li>
              <li><strong>Software Updater:</strong>  Alerts you when any of the installed software has outdated versions.</li>
              <li><strong>Browser Cleanup:</strong> Uninstalls unwanted toolbars and extensions.</li>
              <li><strong>VPN:</strong> lets you browse securely with a private internet.</li>
              <li><strong>Game Mode:</strong> allows optimized system resources for gaming.</li>
              <li><strong>Sandbox:</strong> It allows running suspicious applications safely.</li>
            </ul>

            <h2 style={{ paddingLeft: '20px' , color: '#d7ce06' }}>System Requirements</h2>
            <ul>
              <li><strong>Operating System:</strong> Windows 10, Windows 8/8.1, Windows 7 (SP1)</li>
              <li><strong>Processor:</strong> 1.5 GHz or faster</li>
              <li><strong>RAM:</strong> Minimum of 2 GB (4 GB recommended)</li>
              <li><strong>Free Disk Space:</strong> At least 2.5 GB for installation</li>
              <li><strong>Display:</strong> 1024 x 768 resolution or higher</li>
              <li><strong>Internet Connection:</strong> Required for installation and updates</li>
            </ul>
            <img 
        src='/images/MostP/Avast Antivirus for Windows.jpg' 
        alt="window11 logo" 
        style={{
          width: '600px',
          height: '400px',
          display: 'block', // Ensures block-level behavior for centering
          marginLeft: 'auto', 
          marginRight: 'auto' 
        }}
        
      />
            <h2 style={{ paddingLeft: '20px', paddingTop: '10px' , color: '#d7ce06' }}>Conclusion</h2>
            <p  style={{ paddingLeft: '5px' }}>
            Avast antivirus gives strong security protection with many advanced features securing your Windows. It is well suitable for not only casual use but also for advanced safety protection.
            </p>

            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                startIcon={<DownloadIcon />}
                onClick={handleDownload}
                style={{
                  backgroundColor: '#0078D4',
                  color: '#fff',
                  padding: '10px 20px',
                  fontSize: '16px',
                  borderRadius: '8px',
                  textTransform: 'none',
                  marginBottom: '10px',
                }}
              >
                Avast Antivirus for Windows
              </Button>

             
            </div>
            <p  style={{ paddingLeft: '5px' }}>
              If the application is not installed on your Windows system, click the button below to proceed with the installation.
            </p>
            {/* <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                startIcon={<DownloadIcon />}
                onClick={handleyoutubevideos}
                style={{
                  backgroundColor: 'green',
                  color: '#fff',
                  padding: '10px 20px',
                  marginLeft: '10px',
                  fontSize: '16px',
                  borderRadius: '8px',
                  textTransform: 'none',
                  marginBottom: '20px',
                }}
              >
                How to download and install 
              </Button>

              </div> */}



 <div >
      <h2 style={{ paddingTop: '20px' , paddingLeft: '20px' ,  color: '#4f4bff' }}>  How to download and install Check the below video </h2>
      <ReactPlayer
      style={{ paddingTop: '20px'  }}
        url="https://youtu.be/_oksvYAwv5g"
        controls={true} // Show controls for the player
        width="100%"    // Set the width of the player
        height="500px"  // Set the height of the player
      />
    </div>



          </div>
        </div>
        <div className="empty-area">

                {/* google add sence adds here */}

                <div className="container" style={{ marginTop: '50px' }}>
                <div className="text-area" >
                {/* <div className='text-mainArea'> 








                  </div> */}
                  </div>
                  </div>



        </div>
      </div>
      <Footer />
    </div>
  );
}

export default AntiAvest;
