import React from 'react';
import { Button } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import TopNavbar from '../HeaderAndFooter/TopHeader';
import LogoComponent from '../HeaderAndFooter/PageLogo';
import SocialMediaSidebar from '../MenuBar/SocialMediaSidebar';
import MenuBar from '../MenuBar/MenuBar';
import Footer from '../HeaderAndFooter/Footer';
import ReactPlayer from "react-player";


const AvgAnti= () => {



    const handleDownload = () => {
      // Your download logic here
      // window.location.href = 'https://www.avg.com/en-us/free-antivirus-download';

      window.location.href = 'SoftwareHere/homesoft/_Easypcsoft_AVG_2016_Antivirus_v16.101_Build_7752.zip';
    };


    // const avgDownloadvideos = () => {
    //   // Your download logic here
    //   // window.location.href = 'https://www.avg.com/en-us/free-antivirus-download';

    //   window.location.href = 'https://youtu.be/nLM1ppzQlvc';
    // };



    


  return (
    <div class="background-image">
   <TopNavbar/>
   <LogoComponent />
   <SocialMediaSidebar />


    <div  className="main-layout" >
    <MenuBar />
    </div>

    <div className="container" style={{ marginTop: '50px' }}>
      <div className="text-area">
    <div className='text-mainArea'> 

    <h2 style={{ textAlign: 'center', color: '#0bb412', marginTop: '10px' }}>Overview of AVG AntiVirus for Windows</h2>
      <p style={{ paddingLeft: '5px' }}>
      AVG AntiVirus is the most popular security solution designed to protect Windows devices from various online threats, such as viruses, malware, ransomware, and phishing attacks. With a user-friendly interface and a robust set of features, AVG aims at delivering comprehensive protection for everyday users.
      </p>
      
      <h2 style={{ paddingLeft: '20px' , color: '#0bb412' }}>Key Features</h2>
      <ul>
        <li><strong>Real-Time Protection:</strong> Constant scanning for malware and other threats in real time ensures that your system is safe always.</li>
        <li><strong>Behavior Shield:</strong> Watches application behavior for the detection of any suspicious activities from new threats as well.</li>
        <li><strong>Ransomware Protection:</strong> Protects the important files and documents against unauthorized access or encryption from ransomware.</li>
        <li><strong>Email Protection:</strong> Scans incoming and outgoing emails for malicious attachments and links to help secure your communications.</li>
        <li><strong>Web Protection:</strong> Protects privacy by scanning both incoming and outgoing emails for malicious attachments and links.</li>
        <li><strong>Network Security:</strong> Serves as an intrusion detection and prevention system by monitoring network traffic and blocking intrusions and other threats from the outside.</li>
        <li><strong>Update Scanner:</strong> Reminds your users about availability of software updates in order to fix security holes. </li>
        <li><strong>Safe Eraser:</strong> Prevent sensitive files from being recovered through means of permanent erasure.</li>
        <li><strong>Computer Performance:</strong> Provides users with tools for cleaning up free space on their computers and speeding up their processes.</li>
        <li><strong>VPN (Virtual Private Network):</strong> Such protection acts as a security layer and bigger privacy protection when browsing as it encrypts connections.</li>
      </ul>

      <h2 style={{ paddingLeft: '20px' , color: '#0bb412' }}>System Requirements</h2>
      <ul>
        <li><strong>Operating System:</strong> Windows 10, Windows 8/8.1, Windows 7 (SP1)</li>
        <li><strong>Processor:</strong> 1.5 GHz or faster</li>
        <li><strong>RAM:</strong> Minimum of 2 GB (4 GB recommended)</li>
        <li><strong>Free Disk Space:</strong> At least 2 GB for installation</li>
        <li><strong>Display:</strong> 1024 x 768 resolution or higher</li>
        <li><strong>Internet Connection:</strong> Required for installation and updates</li>
      </ul>

      <img 
      src='/images/MostP/AVG AntiVirus.jpeg' 
        alt="window11 logo" 
        style={{
          width: '600px',
          height: '400px',
          display: 'block', // Ensures block-level behavior for centering
          marginLeft: 'auto', 
          marginRight: 'auto' 
        }}
        
      />




      <h2 style={{ paddingLeft: '20px' , paddingTop: '10px' , color: '#0bb412' }}>Conclusion</h2>
      <p style={{ paddingLeft: '5px' }}>
      AVG AntiVirus Free has a rather impressive set of features that aims towards the average user. Its functionality is perfect for anyone looking for a trustworthy antivirus especially in its free or premium version that has added security features. With AVG installed, users will not worry about using the internet with their devices.
      </p>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
      <Button
      variant="contained"
      color="primary"
      size="large"
      startIcon={<DownloadIcon />}
      onClick={handleDownload}
      style={{
        backgroundColor: '#0078D4',
        color: '#fff',
        padding: '10px 20px',
        fontSize: '16px',
        borderRadius: '8px',
        textTransform: 'none',
        marginBottom: '10px',
      }}
    >
     AVG AntiVirus for Windows
    </Button>
    </div>

    <p style={{ paddingLeft: '5px' }}>
    In case you have not installed the application on your Windows system, click the button below to go ahead with the installation steps.
            </p>
            {/* <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                startIcon={<DownloadIcon />}
                onClick={avgDownloadvideos}

                
                style={{
                  backgroundColor: 'green',
                  color: '#fff',
                  padding: '10px 20px',
                  marginLeft: '10px',
                  fontSize: '16px',
                  borderRadius: '8px',
                  textTransform: 'none',
                  marginBottom: '20px',
                }}
              >
                How to download and install 
              </Button>
              </div> */}


           <div >
      <h2 style={{ paddingTop: '20px' , paddingLeft: '20px' ,  color: '#4f4bff' }}>  How to download and install Check the below video </h2>
      <ReactPlayer
      style={{ paddingTop: '20px'  }}
        url="https://youtu.be/nLM1ppzQlvc"
        controls={true} // Show controls for the player
        width="100%"    // Set the width of the player
        height="500px"  // Set the height of the player
      />
    </div>






      </div>
     


      </div>
      <div className="empty-area"></div>

                {/* google add sence adds here */}


    </div>
  <Footer />
  </div>
  );
}

export default AvgAnti ;
