import React from 'react';
import TopNavbar from '../../HeaderAndFooter/TopHeader';
import LogoComponent from '../../HeaderAndFooter/PageLogo';
import SocialMediaSidebar from '../../MenuBar/SocialMediaSidebar';
import MenuBar from '../../MenuBar/MenuBar';
import Footer from '../../HeaderAndFooter/Footer';
import './css/windows10sp1.css';
import { Button } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';



const OSZorin17= () => {



    const handleDownload = () => {
      // Your download logic here
      window.location.href = 'https://zorin.com/os/download/';

      // Example link
    };





  return (
    <div class="background-image">
   <TopNavbar />
   <LogoComponent />
   <SocialMediaSidebar />


    <div  className="main-layout" >
    <MenuBar />
    </div>

    <div className="container" style={{ marginTop: '50px' }}>
      <div className="text-area">
    <div className='text-mainArea'> 

    <h2 style={{ textAlign: 'center', color: '#0b86aa', marginTop: '10px' }} >Overview of Zorin OS 17 Features and Requirements</h2>
      <p style={{ paddingLeft: '5px' }}>
        Zorin OS 17 is a user-friendly Linux distribution designed to provide a smooth and modern computing experience, especially for those transitioning from Windows or macOS. Known for its sleek design, ease of use, and powerful performance, Zorin OS is built to cater to both beginners and advanced users, offering an accessible introduction to Linux.
      </p>

      <h2 style={{ paddingLeft: '20px' , color: '#0b86aa' }}>Key Features:</h2>
      <ul>
        <li>
          <strong style={{ color: '#0b86aa' }}>Windows and macOS Familiarity:</strong> Zorin OS 17 offers a desktop environment with layouts that resemble Windows and macOS, making it easy for users migrating from those platforms to feel comfortable.
        </li>
        <li>
          <strong style={{ color: '#0b86aa' }}>Zorin Appearance:</strong> The Zorin Appearance tool allows users to customize the desktop layout to look and function like Windows, macOS, or a classic Linux interface, offering flexibility in the user experience.
        </li>
        <li>
          <strong style={{ color: '#0b86aa' }}>Performance Optimization:</strong> Zorin OS is optimized to run fast even on older hardware, providing a lightweight and responsive experience while reducing resource usage.
        </li>
        <li>
          <strong style={{ color: '#0b86aa' }}>Zorin Connect:</strong> A feature that allows users to integrate their Android devices with their desktop, enabling them to sync notifications, share files, send texts, and control media playback.
        </li>
        <li>
          <strong style={{ color: '#0b86aa' }}>Software and App Support:</strong> Zorin OS 17 comes pre-installed with essential software and has access to a vast repository of Linux apps via the Software Center, Snap, and Flatpak.
        </li>
        <li>
          <strong style={{ color: '#0b86aa' }}>Gaming and Productivity:</strong> Supports gaming through Steam, Lutris, Wine, and PlayOnLinux to run both Linux and Windows applications and games.
        </li>
        <li>
          <strong style={{ color: '#0b86aa' }}>Security and Privacy:</strong> Built-in security features include automatic security updates, a firewall, and encryption support.
        </li>
        <li>
          <strong style={{ color: '#0b86aa' }}>Touchscreen Support:</strong> Enhanced touchscreen support for tablet and hybrid devices.
        </li>
        <li>
          <strong style={{ color: '#0b86aa' }}>Custom Desktop Layouts:</strong> Zorin OS Pro edition includes multiple desktop layouts, including advanced options for professional environments.
        </li>
      </ul>
      <img 
        src='/images/operatingsystem/zorinos17largejpeg.jpeg' 
        alt="Windows 11 Logo" 
        style={{
          width: '600px',
          height: '400px',
          display: 'block', // Ensures block-level behavior for centering
          marginLeft: 'auto', 
          marginRight: 'auto' ,
        }} />

      <h2 style={{ paddingLeft: '20px' , color: '#0b86aa' }}>System Requirements:</h2>
      <ul>
        <li>Processor: 1 GHz dual-core processor or better.</li>
        <li>RAM: 2 GB for the Lite version, 4 GB for Core and Pro versions (8 GB recommended).</li>
        <li>Storage: 15 GB for Lite, 20 GB for Core, 30 GB for Pro (more for additional apps and data).</li>
        <li>Graphics: Capable of 800x600 resolution (1024x768 recommended).</li>
        <li>Display: Minimum 800x600 resolution.</li>
      </ul>

      <h2 style={{ paddingLeft: '20px' , color: '#0b86aa' }}>Performance and Reception:</h2>
      <p style={{ paddingLeft: '5px' }}>
        Zorin OS 17 is praised for its sleek design, easy-to-use interface, and seamless integration of both Windows and Linux applications. It offers a smooth and responsive user experience on both modern and older hardware.
      </p>

      <h2 style={{ paddingLeft: '20px' , color: '#0b86aa' }}>Conclusion:</h2>
      <p style={{ paddingLeft: '5px' }}>
        Zorin OS 17 stands out for its polished design, customizable desktop environments, and user-friendly approach. With performance optimizations, security features, and support for both productivity and gaming, Zorin OS 17 is a great option for users of all levels.
      </p>

      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>

      <Button
      variant="contained"
      color="primary"
      size="large"
      startIcon={<DownloadIcon />}
      onClick={handleDownload}
      style={{
        backgroundColor: '#0078D4',
        color: '#fff',
        padding: '10px 20px',
        fontSize: '16px',
        borderRadius: '8px',
        textTransform: 'none',
      }}
    >
      Download Zorin OS 17
    </Button>

</div>


<p  style={{ paddingLeft: '5px' }}>
              If the application is not installed on your Windows system, click the button below to proceed with the installation.
            </p>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                startIcon={<DownloadIcon />}
               
                style={{
                  backgroundColor: 'green',
                  color: '#fff',
                  padding: '10px 20px',
                  marginLeft: '10px',
                  fontSize: '16px',
                  borderRadius: '8px',
                  textTransform: 'none',
                  marginBottom: '20px',
                }}
              >
                How to download and install 
              </Button>

              </div>





      </div>
      </div>
      <div className="empty-area"></div>
    </div>
  <Footer />
  </div>
  );
}

export default OSZorin17 ;
