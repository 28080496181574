import React, { useState } from "react";
import './css/operatingsysyemCat.css';
import { useNavigate } from "react-router-dom";



// Sample data (You can replace this with dynamic data later)
const categories = [


  {
    id:1,
    image: 'images/operatingsystem/window11neww.png',
    title: 'Free Download Windows 11',
    time: '2025-01-15',
    details: 'Windows 11 is Microsofts latest operating system, designed with a modern and intuitive user interface that prioritizes productivity and simplicity. It also introduces a focused Start menu and a redesigned taskbar. and a more streamlined layout Key features include improved multitasking with Snap Layouts, improved virtual desktops. and integration with Microsoft Teams.'
  },
  {
    id: 2,
    image: 'images/operatingsystem/window10new.png',
    title: 'Free Download Windows 10 ',
    time: '2025-05-15',
    details: 'Windows 10 April 2023 Free Download OEM RTM Latest Version This is a full bootable ISO image of Windows 10 April 2023 Free Download.'
  },
  {
    id: 3,
    image: 'images/operatingsystem/windows8new.jpg',
    title: 'Free Download Windows 8 ',
    time: '2024-11-24',
    details: 'Windows 8 introduces a modern user interface with live tiles. Optimized for touch screens and tablets. It also marked the beginning of the Windows Store, which allowed users to download apps directly to their devices.'
  }
  ,
  {
    id: 4,
    image: 'images/operatingsystem/windows7new.jpg',
    title: 'Free Download Windows  7 SP2',
    time: '2024-10-01',
    details: ' Windows 7 Service Pack 2 (SP2) is a cumulative update that includes all previously released updates and patches for Windows 7. It improves system stability, security, and performance. At the same time, compatibility with newer hardware and software is guaranteed...'
  }
  

  ,
  {
    id: 5,
    image: 'images/operatingsystem/windowsb7newb.jpg',
    title: 'Free Download Windows  7 SP1',
    time: '2024-09-05',
    details: 'Windows 7 Service Pack 1 (SP1) is an important update for Windows 7 that includes security updates. Debugging and various performance improvements Since its first launch It increases the stability and compatibility of the system and provides users with the latest updated features for the operating system...'
  },


  
  {
    id: 6,
    image: 'images/operatingsystem/ManjaroLinexnew11.jpg',
    title: 'Free Download Manjaro Linux ',
    time: '2024-09-06',
    details: 'Manjaro Linux is an easy-to-use Arch-based distribution. which presents the launch model modern software and state-of-the-art experiences It has multiple desktop environments and an easy-to-use installation process. Making it easy for both beginners and advanced users..'
  },
  {
    id: 7,
    image: 'images/operatingsystem/zorinnew1.jpg',
    title: ' Free Download Zorin OS 17 ',
    time: '2024-09-05',
    details: 'Zorin OS 17 is an easy-to-use Linux distribution designed for those switching from Windows or macOS, offering a familiar interface with an emphasis on performance and security. It provides a modern and customizable desktop environment. and supports a variety of hardware...'
  },
  {
    id: 8,
    image: 'images/operatingsystem/cenosnew1.jpg',
    title: 'Free Download CentOS Stream ',
    time: '2024-09-04',
    details: 'CentOS Stream is a rolling release that serves as the upstream development branch for Red Hat Enterprise Linux (RHEL), providing users with a continuously evolving platform. It provides the latest features and updates before being integrated into future versions of RHEL.'
  }
  ,
  {   
    id: 9,
    image: 'images/operatingsystem/MacOsMojave.jpg',
    title: 'Free Download  Mac OS Mojave  VMWare Image ',
    time: '2024-09-04',
    details: 'macOS Mojave  introduced Dark Mode, offering a darker color scheme system-wide, improving focus and reducing eye strain.It also included features like Dynamic Desktop, Stacks for desktop organization, and major updates to Finder, Safari, and security enhancements.'
  }
  

  ,
  {
    id: 10,
    image: 'images/operatingsystem/osxloin.jpg',
    title: 'Free Download Mac OS X Lion',
    time: '2024-09-04',
    details: 'Mac OS It also introduces Mission Control, which combines Exposé, Dashboard, and Spaces, creating a single view of open windows and... apps.'
  }
];





// Component to display individual category information
const CategoryCard = ({ category, onClick }) => {


  return (
    <div className="category-card" onClick={onClick}>
      <img src={category.image} alt={category.title} className="category-image" />
      <div className="category-info">
        <h3 className="title">{category.title}</h3>
        <p className="category-time">Added on: {category.time}</p>
        <p>{category.details}</p>
      </div>
    </div>
  );
};





// Main Component to display the list of categories
const OperatingSysyemCategoryList = () => {
  const navigate = useNavigate();  // React Router's navigation hook

  const handleCardClick = (categoryId) => {
    if (categoryId === 1) {
      navigate("/windows11new"); // Navigate to the Windows 7 page
    } else if (categoryId === 2) {
      navigate("/windows10sp1"); // Navigate to the Windows 10 page
    }else if (categoryId === 3) {
      navigate("/windows8"); // Navigate to the Windows 10 page
    }else if (categoryId === 4) {
      navigate("/windows7Sp2"); 
    }
    else if (categoryId === 5) {
      navigate("/windowsSP1"); // Navigate to the Windows 10 page
    }
    else if (categoryId === 6) {
      navigate("/ManjaroLinex"); // Navigate to the Windows 10 page
    }
    else if (categoryId === 7) {
      navigate("/ZorinOS17"); // Navigate to the Windows 10 page
    }
    else if (categoryId === 8) {
      navigate("/CentOSStream"); // Navigate to the Windows 10 page
    }
    else if (categoryId === 9) {
      navigate("/Mojave"); // Navigate to the Windows 10 page
    }

    else if (categoryId === 10) {
      navigate("/OSXLion"); // Navigate to the Windows 10 page
    }

    
    // Add more conditions for other categories if needed
  };

  return (
    <div className="Main-category">
    <div className="category-list">
      {categories.map((category) => (
        <CategoryCard key={category.id} category={category} onClick={() => handleCardClick(category.id)} />
      ))}


     {/* {selectedCategoryId === 1 && <Windows7UltimateSP1 />} 
     {selectedCategoryId === 2 && <Windows10sp1 />}  */}
    </div>
   
    </div>
  );
};

export default OperatingSysyemCategoryList;




