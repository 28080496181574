import React from 'react';
import TopNavbar from '../../HeaderAndFooter/TopHeader';
import LogoComponent from '../../HeaderAndFooter/PageLogo';
import SocialMediaSidebar from '../../MenuBar/SocialMediaSidebar';
import MenuBar from '../../MenuBar/MenuBar';
import Footer from '../../HeaderAndFooter/Footer';
import './css/windows10sp1.css';
import { Button } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';



const Windows7SP2= () => {



    const handleDownload = () => {
      // Your download logic here
      window.location.href = 'https://www.microsoft.com/en-us/software-download/windows7';


      // Example link
    };





  return (
    <div class="background-image">
   <TopNavbar />
   <LogoComponent />
   <SocialMediaSidebar />


    <div  className="main-layout" >
    <MenuBar />
    </div>

    <div className="container" style={{ marginTop: '50px' }}>
      <div className="text-area">
    <div className='text-mainArea'> 

    <h2 style={{ textAlign: 'center', color: '#d607ea', marginTop: '20px' }}> Windows 7 SP2: Features and Requirements</h2>
      <p style={{ paddingLeft: '5px' }}>
        Windows 7 Service Pack 2 (SP2), though not officially released, would have represented a hypothetical second major update to Windows 7, building on the improvements and features introduced in Service Pack 1 (SP1). Here’s a conceptual overview based on the nature of service packs and the features typically included in such updates:
      </p>
      <h2 style={{ paddingLeft: '20px' , color: '#d607ea' }}>Key Features:</h2>
      <ul>
        <li><strong>Enhanced Security Updates:</strong> Service Pack 2 would include critical security updates to protect against vulnerabilities, including patches for newly discovered threats and exploits.</li>
        <li><strong>Performance Improvements:</strong> The update would likely include optimizations to improve system performance, stability, and efficiency, addressing any issues reported by users since the release of SP1.</li>
        <li><strong>Compatibility Enhancements:</strong> SP2 would enhance compatibility with newer hardware and software, including updates for drivers and integration with emerging technologies and standards.</li>
        <li><strong>Bug Fixes:</strong> Various bug fixes and stability improvements would be included to resolve known issues from SP1, leading to a more reliable operating system experience.</li>
        <li><strong>Updated Features:</strong> While Windows 7 SP2 would not introduce major new features, it might include updates to existing functionalities, enhancing usability and user experience.</li>
        <li><strong>Improved Support:</strong> Updates to system tools and utilities to provide better support for common tasks and enhance the overall user experience.</li>
      </ul>
      <h2 style={{ paddingLeft: '20px' , color: '#d607ea' }}>System Requirements:</h2>
      <ul>
        <li><strong>Processor:</strong> 1 GHz or faster with 32-bit (x86) or 64-bit (x64) architecture.</li>
        <li><strong>RAM:</strong> 1 GB for 32-bit or 2 GB for 64-bit.</li>
        <li><strong>Storage:</strong> 16 GB of available disk space for 32-bit or 20 GB for 64-bit.</li>
        <li><strong>Graphics Card:</strong> DirectX 9 graphics device with WDDM 1.0 or higher driver.</li>
        <li><strong>Display:</strong> Minimum 800x600 resolution.</li>
        <li><strong>Internet Connection:</strong> Required for downloading updates and certain features.</li>
      </ul>
      <h2 style={{ paddingLeft: '20px' , color: '#d607ea' }}>Performance and Reception:</h2>
      <p style={{ paddingLeft: '5px' }}>
        Windows 7 was widely praised for its performance, stability, and user-friendly interface. The introduction of a second service pack would have further refined and stabilized the operating system, continuing its reputation as a reliable and efficient platform for both personal and professional use.
      </p>
      <h2 style={{ paddingLeft: '20px' , color: '#d607ea' }}>Conclusion:</h2>
      <p style={{ paddingLeft: '5px' }}>
        While Windows 7 SP2 was never officially released, the concept of a second service pack would have aimed to build upon the solid foundation established by Windows 7 SP1, delivering essential updates, performance enhancements, and improved security features to maintain the operating system's robustness and user satisfaction.
      </p>

      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>

      <Button
      variant="contained"
      color="primary"
      size="large"
      startIcon={<DownloadIcon />}
      onClick={handleDownload}
      style={{
        backgroundColor: '#0078D4',
        color: '#fff',
        padding: '10px 20px',
        fontSize: '16px',
        borderRadius: '8px',
        textTransform: 'none',
        marginBottom: '20px',
      }}
    >
      Download Windows 7 SP2
    </Button>


    </div>



    <p  style={{ paddingLeft: '5px' }}>
              If the application is not installed on your Windows system, click the button below to proceed with the installation.
            </p>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                startIcon={<DownloadIcon />}
               
                style={{
                  backgroundColor: 'green',
                  color: '#fff',
                  padding: '10px 20px',
                  marginLeft: '10px',
                  fontSize: '16px',
                  borderRadius: '8px',
                  textTransform: 'none',
                  marginBottom: '20px',
                }}
              >
                How to download and install 
              </Button>

              </div>



      </div>
      </div>
      <div className="empty-area"></div>
    </div>
  <Footer />
  </div>
  );
}

export default Windows7SP2 ;
