import React from 'react';
import TopNavbar from '../../HeaderAndFooter/TopHeader';
import LogoComponent from '../../HeaderAndFooter/PageLogo';
import SocialMediaSidebar from '../../MenuBar/SocialMediaSidebar';
import MenuBar from '../../MenuBar/MenuBar';
import Footer from '../../HeaderAndFooter/Footer';
import '../css/NukeStudioOverview.css';
import { Button } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';



const DVDFabToolkit2024= () => {



    const handleDownload = () => {
      // Your download logic here
      window.location.href = 'https://dvdfab-toolkit.apponic.com/'; // Example link
    };





  return (
    <div class="background-image">
   <TopNavbar />
   <LogoComponent />
   <SocialMediaSidebar />


    <div  className="main-layout" >
    <MenuBar />
    </div>

    <div className="container" style={{ marginTop: '50px' }}>
      <div className="text-area">
    <div className='text-mainArea'> 
    <h1 style={{ paddingLeft: '30px' , color: '#0fbf5f', marginTop: '20px'  }}>Overview of DVDFab Toolkit 2024</h1>
      <p style={{ paddingLeft: '5px' }}>
      DVDFab Toolkit 2024 is a multi-purpose multimedia software suite designed for video editing, conversion, and enhancement. It provides users with a comprehensive set of tools to manage media files. This makes it suitable for both beginners and advanced users.
      </p>

      <h2 style={{ paddingLeft: '20px' , color: '#f49c0b' }}>Key Features</h2>
      <ul className="features-list">
        <li>
          <strong style={{  color: '#0c6dc9' }}>Video Conversion:</strong> Convert videos to various formats including MP4, AVI, MKV, and more.
        </li>
        <li>
          <strong style={{  color: '#0c6dc9' }}>Video Editing:</strong> Edit videos with tools for cutting, merging, cropping, and rotating.
        </li>
        <li>
          <strong style={{  color: '#0c6dc9' }}>Audio Extraction:</strong> Extract audio tracks from video files and save them in formats like MP3 or AAC.
        </li>
        <li>
          <strong style={{  color: '#0c6dc9' }}>Subtitle Support:</strong> Add, edit, or remove subtitles in your videos.
        </li>
        <li>
          <strong style={{  color: '#0c6dc9' }}>Batch Processing:</strong> Process multiple files simultaneously for improved workflow efficiency.
        </li>
        <li>
          <strong style={{  color: '#0c6dc9' }}>Video Enhancements:</strong> Apply filters, adjust brightness and contrast, and use color correction tools.
        </li>
        <li>
          <strong style={{  color: '#0c6dc9' }}>GIF Maker:</strong> Create GIFs from video clips for easy sharing on social media.
        </li>
        <li>
          <strong style={{  color: '#0c6dc9' }}>Screen Recorder:</strong> Record your screen activity for tutorials, presentations, or gameplay.
        </li>
        <li>
          <strong style={{  color: '#0c6dc9' }}>User-Friendly Interface:</strong> Intuitive design allows easy navigation and access to features.
        </li>
        <li>
          <strong style={{  color: '#0c6dc9' }}>Frequent Updates:</strong> Regular updates ensure compatibility with the latest formats and devices.
        </li>
      </ul>

      <h2 style={{ paddingLeft: '20px' , color: '#f49c0b' }}>Use Cases</h2>
      <ul className="use-cases-list">
        <li><strong style={{  color: '#0c6dc9' }}>Content Creators:</strong> Ideal for YouTubers and social media influencers.</li>
        <li><strong style={{  color: '#0c6dc9' }}>Educators:</strong> Perfect for teachers creating instructional videos.</li>
        <li><strong style={{  color: '#0c6dc9' }}>Casual Users:</strong> Great for individuals editing personal videos.</li>
        <li><strong style={{  color: '#0c6dc9' }}>Professionals:</strong> Useful for filmmakers or videographers needing efficient tools.</li>
      </ul>


      <img 
        src='/images/MultiMedia/DVDFab Toolkit 2024pic.png' 
        alt="Windows 11 Logo" 
        style={{
          width: '600px',
          height: '400px',
          display: 'block', // Ensures block-level behavior for centering
          marginLeft: 'auto', 
          marginRight: 'auto' ,
        }} 
      />





      <h2 style={{ paddingLeft: '20px' , color: '#f49c0b' }}>Conclusion</h2>
      <p style={{ paddingLeft: '5px' }}>
      DVDFab Toolkit 2024 is an all-in-one solution for managing and enhancing multimedia files. Its powerful features and user-friendly design cater to a wide range of users. Whether it's a personal project or for professional use, DVDFab Toolkit gives you the tools you need to efficiently create stunning video content.
      </p>



      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}> 
      <Button
      variant="contained"
      color="primary"
      size="large"
      startIcon={<DownloadIcon />}
      onClick={handleDownload}
      style={{
        backgroundColor: '#0078D4',
        color: '#fff',
        padding: '10px 20px',
        fontSize: '16px',
        borderRadius: '8px',
        textTransform: 'none',
        marginBottom: '20px',
      }}
    >
      Free Download DVDFab Toolkit 2024
    </Button>

</div>



<p style={{ paddingLeft: '5px' }}>
              If the application is not installed on your Windows system, click the button below to proceed with the installation.
            </p>


<div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}> 

    <Button
      variant="contained"
      color="primary"
      size="large"
      startIcon={<DownloadIcon />}
     
      style={{
        backgroundColor: '#47f40b',
        color: '#fff',
        padding: '10px 20px',
        marginLeft: '5px',
        fontSize: '16px',
        borderRadius: '8px',
        textTransform: 'none',
        marginBottom: '20px',
      }}
    >
  Offical Buy Games Teaser AEP
    </Button>

</div>



      </div>
      </div>
      <div className="empty-area"></div>
    </div>
  <Footer />
  </div>
  );
}

export default DVDFabToolkit2024;
