import React, { useState } from "react";
import '../OperatingSystem/css/operatingsysyemCat.css';
import { useNavigate } from "react-router-dom";



// Sample data (You can replace this with dynamic data later)
const categories = [


  {
    id:1,
    image: 'images/Development/androidnew.png',
    title: 'Free Download Android Studio 2024 ',
    time: '2024-09-11',
    details: 'Android Studio is the official integrated development environment (IDE) for developing Android apps, providing tools for building, testing, and debugging applications. Supports features such as code editing, performance profiling. and various layout editing tools This makes the entire app development process more efficient.'
  },
  {
    id: 2,
    image: 'images/Development/phprunning1.jpg',
    title: 'Free Download PHPRunner Enterprise 2024  ',
    time: '2024-09-10',
    details: 'PHPRunner Enterprise is a low-code platform designed to quickly build secure, dynamic web applications. It allows users to create database-driven sites with customizable templates, charts, dashboards, forms, and other features. All this does not require extensive coding knowledge.'
  },
  {
    id: 3,
    image: 'images/Development/Rano.jpg',
    title: 'Free Download Ranorex Studio 2024 ',
    time: '2024-09-04',
    details: 'Ranorex Studio is an end-to-end automated testing platform designed for testing desktop UI, web, and mobile applications. It has easy-to-use reporting features and supports coding in C# and VB.NET, making it suitable for both beginners and advanced testers.'
  }
  ,
  {
    id: 4,
    image: 'images/Development/FileMakerServer.jpg',
    title: 'Free Download FileMaker Server 2024 ',
    time: '2024-09-04',
    details: 'FileMaker Server 2024 is a robust platform for hosting. Manage and share custom FileMaker applications while keeping them secure. scalability and increased efficiency Supports access to multiple platforms Enabling users to work together Access the database from the desktop environment. mobile and web, and they can do it.'
  }
  

  ,
  {
    id: 5,
    image: 'images/Development/RD.png',
    title: 'Free Download JetBrains Rider 2024 ',
    time: '2024-09-04',
    details: 'JetBrains Rider 2024 is a powerful cross-platform IDE designed for .NET development. It provides deep integration with the .NET ecosystem, including ASP.NET, Xamarin, and Unity. It features intelligent code termination. new components and debugging tools for Windows, macOS, etc., increasing productivity between Linux and developers.'
  },



  {
    id: 6,
    image: 'images/Development/WS.png',
    title: 'Free Download JetBrains WebStorm 2024 ',
    time: '2024-09-06',
    details: 'JetBrains WebStorm 2024 is a sophisticated IDE for JavaScript and web development. By providing advanced coding assistance. Debugging and integration with popular frameworks like React, Angular, Vue.js, etc., providing a smooth development experience with tools for testing. Version control and new components to increase productivity.'
  },
  {
    id: 7,
    image: 'images/Development/JData.png',
    title: 'Free Download JetBrains DataGrip 2024 ',
    time: '2024-09-05',
    details: 'JetBrains DataGrip 2024 is a comprehensive database IDE that supports multiple database systems. Provides advanced tools for effective SQL development. Database management and search operations By completing the intelligent code. Schema navigation and powerful data visualization for developers. And DBA database workflows have been streamlined.'
  },
  {
    id: 8,
    image: 'images/Development/RM.png',
    title: 'Free Download JetBrains RubyMine 2024 ',
    time: '2024-09-04',
    details: 'JetBrains RubyMine 2024 is a specialized IDE for Ruby and Rails development, providing intelligent code completion, debugging, and testing tools optimized for the Ruby ecosystem, providing seamless integration with frameworks, databases, and version control. Increase developer productivity on web applications and server-side projects.'
  }
  ,
  {
    id: 9,
    image: 'images/Development/pc.png',
    title: 'Free Download JetBrains PyCharm Pro 2024 ',
    time: '2024-09-04',
    details: 'JetBrains PyCharm Pro 2024 is a professional Python IDE designed for full stack development. It provides powerful tools for web framing. data science and machine learning Includes advanced code help, SQL debugging, Docker, version control. and integrated support Improved development workflow.'
  }
  

  ,
  {
    id: 10,
    image: 'images/Development/Go.png',
    title: 'Free Download JetBrains GoLand 2024 ',
    time: '2024-09-04',
    details: 'JetBrains GoLand 2024 is an advanced IDE for Go development that provides intelligent code analysis, debugging, and testing tools optimized for the Go ecosystem, providing seamless integration with the framework. Version control and cloud services Boost productivity for Go developers working on web, cloud, and system-level applications.'
  }
];





// Component to display individual category information
const CategoryCard = ({ category, onClick }) => {


  return (
    <div className="category-card" onClick={onClick}>
      <img src={category.image} alt={category.title} className="category-image" />
      <div className="category-info">
        <h3>{category.title}</h3>
        <p className="category-time">Added on: {category.time}</p>
        <p>{category.details}</p>
      </div>
    </div>
  );
};





// Main Component to display the list of categories
const DevelopmentCategoryList = () => {
  const navigate = useNavigate();  // React Router's navigation hook

  const handleCardClick = (categoryId) => {
    if (categoryId === 1) {
      navigate("/AndroidStudio2024"); // Navigate to the Windows 7 page
    } else if (categoryId === 2) {
      navigate("/PHPRunner24"); // Navigate to the Windows 10 page
    }
    else if (categoryId === 3) {
      navigate("/RanorexStudio4"); // Navigate to the Windows 10 page
    }
    else if (categoryId === 4) {
      navigate("/FileMakerServer"); // Navigate to the Windows 10 page
    }
    else if (categoryId === 5) {
      navigate("/JetBrainsRider"); // Navigate to the Windows 10 page
    }else if (categoryId === 6) {
      navigate("/JetBrainsWebStorm"); // Navigate to the Windows 10 page
    }
    else if (categoryId === 7) {
      navigate("/JetBrainsDataGrip"); // Navigate to the Windows 10 page
    }else if (categoryId === 8) {
      navigate("/JetBrainsRubyMine"); // Navigate to the Windows 10 page
    }else if (categoryId === 9) {
      navigate("/JetBrainsPyCharm"); // Navigate to the Windows 10 page
    }else if (categoryId === 10) {
      navigate("/JetBrainsGoLand"); // Navigate to the Windows 10 page
    }
    
    
    
  };

  return (
    <div className="Main-category">
    <div className="category-list">
      {categories.map((category) => (
        <CategoryCard key={category.id} category={category} onClick={() => handleCardClick(category.id)} />
      ))}


    
    </div>
   
    </div>
  );
};

export default DevelopmentCategoryList;




