import React from 'react';
import TopNavbar from '../../HeaderAndFooter/TopHeader';
import LogoComponent from '../../HeaderAndFooter/PageLogo';
import SocialMediaSidebar from '../../MenuBar/SocialMediaSidebar';
import MenuBar from '../../MenuBar/MenuBar';
import Footer from '../../HeaderAndFooter/Footer';
import './css/windows10sp1.css';
import { Button } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';



const CentOSStream= () => {



    const handleDownload = () => {
      // Your download logic here
      window.location.href = 'https://www.centos.org/centos-stream/';


      // Example link
    };


  return (
    <div class="background-image">
   <TopNavbar />
   <LogoComponent />
   <SocialMediaSidebar />


    <div  className="main-layout" >
    <MenuBar />
    </div>

    <div className="container" style={{ marginTop: '50px' }}>
      <div className="text-area">
    <div className='text-mainArea'> 

    <h2 style={{ textAlign: 'center', color: '#d209d2', marginTop: '10px' }}>Overview of CentOS Stream Operating System</h2>
      <p style={{ paddingLeft: '5px' }}>
      CentOS Stream is a Linux-based operating system that functions as a rolling distribution. 
        Located between the original CentOS and Red Hat Enterprise Linux (RHEL), it allows users to 
        A preview of what the next mini-release of RHEL will be like, this makes CentOS Stream a perfect fit. 
        For developers and users who want to participate in the next iteration of RHEL.
      </p>

      <h2 style={{ paddingLeft: '20px' , color: '#d209d2' }}>Key Features</h2>
      <ul>
        <li><strong>Rolling Release Model:</strong> Unlike traditional versions, CentOS Stream continuously updates, allowing users to receive new features and improvements more frequently.</li>
        <li><strong>Upstream Development:</strong> CentOS Stream acts as a testing ground for the latest RHEL updates, making it a valuable resource for developers to see and influence what’s coming next.</li>
        <li><strong>Community Collaboration:</strong> Being part of the CentOS community, users can contribute to the development and provide feedback, fostering collaboration and innovation.</li>
        <li><strong>Stability:</strong> While it provides more frequent updates, CentOS Stream maintains the stability and reliability expected from the CentOS family.</li>
        <li><strong>Wide Software Support:</strong> Compatible with a large ecosystem of software and applications, making it suitable for various use cases, including servers, development environments, and desktop applications.</li>
        <li><strong>Container Support:</strong> Offers robust support for containerized applications using technologies like Docker and Podman, making it a great choice for cloud-native environments.</li>
        <li><strong>Security:</strong> Regular updates and community support ensure that security vulnerabilities are addressed promptly.</li>
        <li><strong>Documentation and Support:</strong> Extensive documentation is available, along with community and commercial support options.</li>
      </ul>

      <h2 style={{ paddingLeft: '20px' , color: '#d209d2' }}>System Requirements</h2>
      <ul>
        <li><strong>Operating System:</strong> CentOS Stream is compatible with various hardware, but ideally runs on modern 64-bit processors.</li>
        <li><strong>Processor:</strong> 1 GHz or faster (64-bit architecture recommended).</li>
        <li><strong>RAM:</strong> Minimum of 2 GB (4 GB or more recommended for better performance).</li>
        <li><strong>Disk Space:</strong> At least 20 GB of free disk space for installation (more may be required based on usage).</li>
        <li><strong>Network Connection:</strong> Internet access is recommended for updates and additional software installations.</li>
      </ul>


      <img 
        src='/images/operatingsystem/centos-8-stream-2.jpg' 
        alt="Windows 11 Logo" 
        style={{
          width: '600px',
          height: '400px',
          display: 'block', // Ensures block-level behavior for centering
          marginLeft: 'auto', 
          marginRight: 'auto' ,
        }} 
      />



      <h2 style={{ paddingTop: '20px' , paddingLeft: '20px' , color: '#d209d2' }}>Conclusion</h2>
      <p style={{ paddingLeft: '5px' }}>
        CentOS Stream is an excellent choice for users who want to stay ahead of the curve in Linux development 
        and enterprise environments. Its combination of stability, community support, and rolling updates makes 
        it suitable for both developers and system administrators looking to leverage the latest advancements in RHEL. 
        Whether you're setting up a server, developing applications, or testing features, CentOS Stream provides a solid foundation.
      </p>


      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
      <Button
      variant="contained"
      color="primary"
      size="large"
      startIcon={<DownloadIcon />}
      onClick={handleDownload}
      style={{
        backgroundColor: '#0078D4',
        color: '#fff',
        padding: '10px 20px',
        fontSize: '16px',
        borderRadius: '8px',
        textTransform: 'none',
        marginBottom: '20px',
    
      }}
    >
     CentOS Stream Operating System
    </Button>

</div>

<p  style={{ paddingLeft: '5px' }}>
              If the application is not installed on your Windows system, click the button below to proceed with the installation.
            </p>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                startIcon={<DownloadIcon />}
               
                style={{
                  backgroundColor: 'green',
                  color: '#fff',
                  padding: '10px 20px',
                  marginLeft: '10px',
                  fontSize: '16px',
                  borderRadius: '8px',
                  textTransform: 'none',
                  marginBottom: '20px',
                }}
              >
                How to download and install 
              </Button>

              </div>





      </div>
      </div>
      <div className="empty-area"></div>
    </div>
  <Footer />
  </div>
  );
}

export default CentOSStream;
