import React from 'react';
import TopNavbar from '../../HeaderAndFooter/TopHeader';
import LogoComponent from '../../HeaderAndFooter/PageLogo';
import SocialMediaSidebar from '../../MenuBar/SocialMediaSidebar';
import MenuBar from '../../MenuBar/MenuBar';
import Footer from '../../HeaderAndFooter/Footer';
import './css/windows10sp1.css';
import { Button } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';



const OSXLion= () => {



    const handleDownload = () => {
      // Your download logic here
      window.location.href = 'https://getintopc.com/softwares/operating-systems/mac-os-x-lion-10-7-5-dmg-free-download/';


      // Example link
    };





  return (
    <div class="background-image">
   <TopNavbar />
   <LogoComponent />
   <SocialMediaSidebar />


    <div  className="main-layout" >
    <MenuBar />
    </div>
 
    <div className="container" style={{ marginTop: '50px' }}>
      <div className="text-area">
    <div className='text-mainArea'> 

    <h1 style={{ textAlign: 'center', color: '#eeb400', marginTop: '10px' }}>Overview of macOS X Lion</h1>
      <p style={{ paddingLeft: '5px' }}>
      macOS X Lion (version 10.7) is Apple's eighth major release of an operating system for Mac computers. Introduced in July 2011, Lion introduced several new features aimed at enhancing user experience, performance and integration with iOS devices.
      </p>
      
      <h2 style={{ paddingLeft: '20px' , color: '#eeb400' }}>Key Features</h2>
      <ul>
        <li><strong>Mission Control:</strong> Combines Exposé, Spaces, and Dashboard to provide an overview of all open windows, allowing users to switch between apps and desktops easily.</li>
        <li><strong>Launchpad:</strong> A new way to access applications, similar to the iOS home screen, displaying all installed apps in a grid format for easy launching.</li>
        <li><strong>Full-Screen Apps:</strong> Enables users to run applications in full-screen mode, making it easier to focus on tasks without distractions.</li>
        <li><strong>Auto Resume:</strong> Automatically reopens applications and documents exactly as they were when the user last closed them, providing a seamless experience.</li>
        <li><strong>Versions:</strong> Introduces a versioning system that automatically saves versions of documents, allowing users to browse through previous versions and restore them if needed.</li>
        <li><strong>AirDrop:</strong> A feature that allows users to easily share files wirelessly with other Macs nearby, simplifying the transfer of documents.</li>
        <li><strong>Mail App Enhancements:</strong> A redesigned Mail app with a new conversation view, improved search, and support for full-screen mode.</li>
        <li><strong>Multi-Touch Gestures:</strong> Improved support for gestures on trackpads and Magic Mouse, enabling more intuitive navigation and control.</li>
        <li><strong>Mac App Store Integration:</strong> Direct access to the Mac App Store, allowing users to download and install apps easily.</li>
      </ul>

      <h2 style={{ paddingLeft: '20px' , color: '#eeb400' }}>System Requirements</h2>
      <ul>
        <li><strong>Operating System:</strong> Requires a Mac with Intel-based architecture.</li>
        <li><strong>Processor:</strong> 1 GB of RAM minimum (2 GB recommended).</li>
        <li><strong>Free Disk Space:</strong> At least 7 GB of available storage.</li>
        <li><strong>Display:</strong> 1024 x 768 resolution or higher.</li>
      </ul>

      <img 
        src='/images/operatingsystem/Mac OS X Lion in VirtualBox.jpg' 
        alt="Windows 11 Logo" 
        style={{
          width: '600px',
          height: '400px',
          display: 'block', // Ensures block-level behavior for centering
          marginLeft: 'auto', 
          marginRight: 'auto' ,
        }} 
      />



      <h2 style={{ paddingTop: '20px' , paddingLeft: '20px' , color: '#eeb400' }}>Conclusion</h2>
      <p style={{ paddingLeft: '5px' }}>
      macOS Improved app management and improve user experience Its integration with iOS devices and focus on intuitive controls make it a key update for Mac users, suitable for both casual and professional environments. Whether it's managing applications, sharing files, or improving workflows, Lion provides tools to meet a wide range of user needs.
      </p>



      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
      <Button
      variant="contained"
      color="primary"
      size="large"
      startIcon={<DownloadIcon />}
      onClick={handleDownload}
      style={{
        backgroundColor: '#0078D4',
        color: '#fff',
        padding: '10px 20px',
        fontSize: '16px',
        borderRadius: '8px',
        textTransform: 'none',
        marginBottom: '20px',
      }}
    >
      X Lion Mac Operating System
    </Button>


</div>

<p  style={{ paddingLeft: '5px' }}>
              If the application is not installed on your Windows system, click the button below to proceed with the installation.
            </p>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                startIcon={<DownloadIcon />}
               
                style={{
                  backgroundColor: 'green',
                  color: '#fff',
                  padding: '10px 20px',
                  marginLeft: '10px',
                  fontSize: '16px',
                  borderRadius: '8px',
                  textTransform: 'none',
                  marginBottom: '20px',
                }}
              >
                How to download and install 
              </Button>

              </div>




      </div>
      </div>
      <div className="empty-area"></div>
    </div>
  <Footer />
  </div>
  );
}

export default OSXLion ;
