import React from 'react';

import { Button } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import TopNavbar from '../../HeaderAndFooter/TopHeader';
import LogoComponent from '../../HeaderAndFooter/PageLogo';
import SocialMediaSidebar from '../../MenuBar/SocialMediaSidebar';
import MenuBar from '../../MenuBar/MenuBar';
import Footer from '../../HeaderAndFooter/Footer';


const AdobeAferEffect2018= () => {



    const handleDownload = () => {
      // Your download logic here
      window.location.href = 'https://getintopc.com/softwares/multimedia/adobe-after-effects-cc-2018-free-download-1690359/';


      // Example link
    };





  return (
    <div class="background-image">
   <TopNavbar />
   <LogoComponent/>
   <SocialMediaSidebar />


    <div  className="main-layout" >
    <MenuBar />
    </div>

    <div className="container" style={{ marginTop: '50px' }}>
      <div className="text-area">
    <div className='text-mainArea'> 

    <h2  style={{ paddingLeft: '30px' , color: '#a506b8', marginTop: '10px'  }}>Adobe After Effects Overview</h2>
    <p style={{ paddingLeft: '5px' }}>
    Adobe After Effects is the leading software for creating motion graphics and visual effects. It is widely used in film, television, web and video production. It enables artists to animate, manipulate, and combine media in 2D and 3D space with a powerful set of tools and features. Including keyframe animation, tracking, and seamless integration with other Adobe Creative Cloud applications, After Effects is essential for delivering dynamic imagery and professional results.


      </p>

      <h2 style={{ paddingLeft: '20px' , color: '#a506b8' }}>Key Features</h2>
      <ul>
        <li>
          <strong style={{  color: '#a506b8' }}>Compositing and Animation:</strong> Powerful tools for creating complex animations and compositing layers of video, graphics, and effects.
        </li>
        <li>
          <strong style={{  color: '#a506b8' }}>Motion Graphics Templates:</strong> Use and create motion graphics templates for easy customization in Premiere Pro and After Effects.
        </li>
        <li>
          <strong style={{  color: '#a506b8' }}>3D Integration:</strong> Enhanced 3D capabilities, allowing for the creation of 3D animations and scenes directly within After Effects.
        </li>
        <li>
          <strong style={{  color: '#a506b8' }}>Advanced Keyframe Controls:</strong> Improved keyframe interpolation and motion tracking for precise animation adjustments.
        </li>
        <li>
          <strong style={{  color: '#a506b8' }}>Effects and Presets:</strong> Extensive library of built-in effects and presets to enhance videos, including color correction, blurs, and distortions.
        </li>
        <li>
          <strong style={{  color: '#a506b8' }}>Expressions:</strong> Use expressions to create complex animations and automate repetitive tasks with code-like functionality.
        </li>
        <li>
          <strong style={{  color: '#a506b8' }}>Render Engine:</strong> Adobe’s Media Encoder allows for faster rendering and exporting of projects in various formats.
        </li>
        <li>
          <strong style={{  color: '#a506b8' }}>Collaboration Tools:</strong> Improved sharing and collaboration features for team projects and cloud-based workflows.
        </li>
        <li>
          <strong style={{  color: '#a506b8' }}>GPU Acceleration:</strong> Enhanced performance with GPU acceleration for rendering effects and previews.
        </li>
        <li>
          <strong style={{  color: '#a506b8' }}>Virtual Reality and 360° Editing:</strong> Tools for editing and compositing 360° and VR content, providing immersive experiences.
        </li>
      </ul>

      <h2 style={{ paddingLeft: '20px' , color: '#a506b8' }}>System Requirements</h2>
      <h3 style={{ paddingLeft: '20px' , color: '#a506b8' }}>Minimum Requirements:</h3>
      <ul>
        <li>Operating System: Windows 10 (64-bit) or later; macOS 10.14 (Mojave) or later.</li>
        <li>Processor: Multi-core Intel or AMD processor with 64-bit support.</li>
        <li>RAM: 16 GB (32 GB recommended).</li>
        <li>Graphics Card: GPU with at least 2 GB of VRAM and support for OpenGL 2.0.</li>
        <li>Display: 1280 x 1080 resolution (1920 x 1080 or greater recommended).</li>
        <li>Hard Disk Space: 5 GB of available hard-disk space for installation; additional space required for cache.</li>
      </ul>

      <h3 style={{ paddingLeft: '20px' , color: '#a506b8' }}>Recommended Requirements:</h3>
      <ul>
        <li>Operating System: Windows 11 or latest macOS version.</li>
        <li>Processor: Multi-core Intel or AMD processor (3.0 GHz or faster).</li>
        <li>RAM: 32 GB or more.</li>
        <li>Graphics Card: GPU with 4 GB of VRAM or greater.</li>
        <li>Display: 1920 x 1080 resolution or greater.</li>
        <li>Hard Disk Space: SSD recommended for cache and previews.</li>
      </ul>

      <img 
        src='/images/GraphicDesign/After Effectspic1.avif' 
        alt="Windows 11 Logo" 
        style={{
          width: '600px',
          height: '400px',
          display: 'block', // Ensures block-level behavior for centering
          marginLeft: 'auto', 
          marginRight: 'auto' ,
        }} 
      />



      <h2 style={{ paddingLeft: '20px' , color: '#a506b8' }}>Conclusion</h2>
      <p>
      Adobe After Effects is a powerful tool for video post-production. It offers many features for animation. visual effects and composition of images 
        With an easy-to-use interface and strong performance. Suitable for both beginners and professionals with experience in motion photography and video editing... 
        Make sure your system meets the necessary requirements for optimum performance.
      </p>



      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}> 
      <Button
      variant="contained"
      color="primary"
      size="large"
      startIcon={<DownloadIcon />}
      onClick={handleDownload}
      style={{
        backgroundColor: '#0078D4',
        color: '#fff',
        padding: '10px 20px',
        fontSize: '16px',
        borderRadius: '8px',
        textTransform: 'none',
        marginBottom: '20px',
      }}
    >
  Free DownLoad Adobe After Effects CC 2018
    </Button>

</div>

<p  style={{ paddingLeft: '5px' }}>
              If the application is not installed on your Windows system, click the button below to proceed with the installation.
            </p>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                startIcon={<DownloadIcon />}
               
                style={{
                  backgroundColor: 'green',
                  color: '#fff',
                  padding: '10px 20px',
                  marginLeft: '10px',
                  fontSize: '16px',
                  borderRadius: '8px',
                  textTransform: 'none',
                  marginBottom: '20px',
                }}
              >
                How to download and install 
              </Button>

              </div>





      </div>
      </div>
      <div className="empty-area"></div>
    </div>
  <Footer />
  </div>
  );
}

export default AdobeAferEffect2018 ;
