import React from 'react';

import { Button } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import TopNavbar from '../../HeaderAndFooter/TopHeader';
import LogoComponent from '../../HeaderAndFooter/PageLogo';
import SocialMediaSidebar from '../../MenuBar/SocialMediaSidebar';
import MenuBar from '../../MenuBar/MenuBar';
import Footer from '../../HeaderAndFooter/Footer';



const TopazStudio24= () => {



    const handleDownload = () => {
      // Your download logic here
      window.location.href = 'https://getintopc.com/softwares/graphic-design/topaz-studio-texture-2022-effects-free-download/';


      // Example link
    };





  return (
    <div class="background-image">
   <TopNavbar />
   <LogoComponent/>
   <SocialMediaSidebar />


    <div  className="main-layout" >
    <MenuBar />
    </div>

    <div className="container" style={{ marginTop: '50px' }}>
      <div className="text-area">
    <div className='text-mainArea'> 

    <h2 style={{ paddingLeft: '30px' , color: '#eb0582', marginTop: '10px'  }}>Topaz Studio + Texture Effects Overview </h2>

   <p>
   Topaz Studio + Texture Effects is a powerful combination for photographers and artists. It combines the capabilities of Topaz Studio with Texture Effects, allowing users to quickly add professional textures, tones, and lighting effects to their images. Hundreds of textures and borders with content including professional level Just a few clicks - It's easy to enlarge and edit images with It is. This tool streamlines your workflow and helps bring your creative vision to life efficiently.


   </p>
   
      <h2 style={{ paddingLeft: '20px' , color: '#eb0582' }}>Key Features</h2>
      <ul>
        <li>
          <strong style={{ color: '#eb0582' }}>Comprehensive Editing Tools:</strong> A range of powerful editing tools for adjusting exposure, color, tone, and detail to enhance images.
        </li>
        <li>
          <strong style={{ color: '#eb0582' }}>Texture Effects:</strong> Specialized tools to add textures and artistic effects to photos, allowing for unique artistic expressions.
        </li>
        <li>
          <strong style={{ color: '#eb0582' }}>AI-Powered Enhancements:</strong> Utilizes artificial intelligence to automatically enhance images, including noise reduction and sharpening.
        </li>
        <li>
          <strong style={{ color: '#eb0582' }}>Layer-Based Editing:</strong> Supports non-destructive layer editing, enabling users to work on multiple layers without losing original image quality.
        </li>
        <li>
          <strong style={{ color: '#eb0582' }}>Customizable Presets:</strong> A library of customizable presets to quickly apply various looks and styles to images.
        </li>
        <li>
          <strong style={{ color: '#eb0582' }}> Brush Tool:</strong> Precision brush tool for selective editing, allowing users to apply effects to specific areas of an image.
        </li>
        <li>
          <strong style={{ color: '#eb0582' }}>Real-Time Preview:</strong> Instant feedback on edits and effects, making it easier to see changes as you apply them.
        </li>
        <li>
          <strong style={{ color: '#eb0582' }}>Integration with Other Software:</strong> Compatible with popular photo editing software like Adobe Photoshop and Lightroom, allowing for a seamless workflow.
        </li>
        <li>
          <strong style={{ color: '#eb0582' }}>User-Friendly Interface:</strong> Intuitive design that makes it accessible for both beginners and experienced photographers.
        </li>
        <li>
          <strong style={{ color: '#eb0582' }}>Export Options:</strong> Multiple file formats for exporting images, ensuring versatility for different usage needs.
        </li>
      </ul>

      <h2 style={{ paddingLeft: '20px' , color: '#eb0582' }}>System Requirements</h2>
      <h3 style={{ paddingLeft: '20px' , color: '#eb0582' }}>Minimum Requirements:</h3>
      <ul>
        <li>Operating System: Windows 10 (64-bit) or later; macOS 10.12 (Sierra) or later.</li>
        <li>Processor: Multi-core processor (Intel or AMD).</li>
        <li>RAM: 4 GB (8 GB recommended).</li>
        <li>Graphics Card: GPU with OpenGL 3.3 support or higher.</li>
        <li>Display: 1280 x 800 resolution or higher.</li>
        <li>Hard Disk Space: 2 GB of available hard-disk space for installation.</li>
      </ul>

      <h3 style={{ paddingLeft: '20px' , color: '#eb0582' }}>Recommended Requirements:</h3>
      <ul>
        <li>Operating System: Windows 10 or later; macOS 10.14 (Mojave) or later.</li>
        <li>Processor: Multi-core processor (3.0 GHz or faster).</li>
        <li>RAM: 8 GB or more.</li>
        <li>Graphics Card: Dedicated graphics card with 2 GB of VRAM or more.</li>
        <li>Display: 1920 x 1080 resolution or greater.</li>
        <li>Hard Disk Space: SSD recommended for improved performance.</li>
      </ul>


      <img 
        src='/images/GraphicDesign/Before-After-Topaz-Studio-2.jpg' 
        alt="Windows 11 Logo" 
        style={{
          width: '600px',
          height: '400px',
          display: 'block', // Ensures block-level behavior for centering
          marginLeft: 'auto', 
          marginRight: 'auto' ,
        }} 
      />

      <h2 style={{ paddingLeft: '20px' , color: '#eb0582' }}>Conclusion</h2>
      <p style={{ paddingLeft: '5px' }}>
      Topaz Studio + Texture Effects offers a powerful set of tools for photographers and digital artists. Combining powerful editing capabilities with unique texture effects... 
        Supports users of all skill levels with an intuitive interface and AI-powered enhancements. 
        Make sure your system meets the necessary requirements for optimum performance.
      </p>

      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>     
 
      <Button
      variant="contained"
      color="primary"
      size="large"
      startIcon={<DownloadIcon />}
      onClick={handleDownload}
      style={{
        backgroundColor: '#0078D4',
        color: '#fff',
        padding: '10px 20px',
        fontSize: '16px',
        borderRadius: '8px',
        textTransform: 'none',
        marginBottom: '20px',
      }}
    >
  Free DownLoad Topaz Studio + Texture Effects
    </Button>

</div>

<p  style={{ paddingLeft: '5px' }}>
              If the application is not installed on your Windows system, click the button below to proceed with the installation.
            </p>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                startIcon={<DownloadIcon />}
               
                style={{
                  backgroundColor: 'green',
                  color: '#fff',
                  padding: '10px 20px',
                  marginLeft: '10px',
                  fontSize: '16px',
                  borderRadius: '8px',
                  textTransform: 'none',
                  marginBottom: '20px',
                }}
              >
                How to download and install 
              </Button>

              </div>




      </div>
      </div>
      <div className="empty-area"></div>
    </div>
  <Footer />
  </div>
  );
}

export default TopazStudio24 ;
