import React from 'react';
import { Button } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import TopNavbar from '../HeaderAndFooter/TopHeader';
import LogoComponent from '../HeaderAndFooter/PageLogo';
import SocialMediaSidebar from '../MenuBar/SocialMediaSidebar';
import MenuBar from '../MenuBar/MenuBar';
import Footer from '../HeaderAndFooter/Footer';
import ReactPlayer from "react-player";


const CCleaner= () => {



    const handleDownload = () => {
      // Your download logic here
      // window.location.href = 'https://www.ccleaner.com/ccleaner/download';
      window.location.href = 'SoftwareHere/homesoft/Easypcsoft_CCleaner_Professional_Plus_6.26.zip';

      // Example link
    };
    // const handlevodeosclick = () => {
    //   // Your download logic here
    //   // window.location.href = 'https://www.ccleaner.com/ccleaner/download';
    //   window.location.href = 'https://youtu.be/HK_kiHpDLlE';

    //   // Example link
    // };




   

  return (
    <div class="background-image">
   <TopNavbar/>
   <LogoComponent />
   <SocialMediaSidebar />


    <div  className="main-layout" >
    <MenuBar />
    </div>

    <div className="container" style={{ marginTop: '50px' }}>
      <div className="text-area">
    <div className='text-mainArea'> 

    <h2 style={{ textAlign: 'center', color: '#f57748', marginTop: '10px' }}>Overview of CCleaner for Windows </h2>
      <p style={{ paddingLeft: '5px' }}>
      CCleaner is a popular system optimization and privacy tool designed for Windows. It facilitates users easy up their PCs by putting off needless documents, optimizing machine overall performance, and protecting person privateness. With its intuitive interface and powerful features, CCleaner is a go-to choice for plenty trying to keep their computer systems going for walks smoothly.

      </p>

      <h2 style={{ paddingLeft: '20px' , color: '#f57748' }}>Key Features</h2>
      <ul>
        <li><strong>System Cleanup:</strong> Delete temporary, archived, and log files to free up disk space and improve system performance.</li>
        <li><strong>Registry Cleaner:</strong>  Scans for and prevents registry problems that can cause system errors and slowdowns.</li>
        <li><strong>Browser Cleaner:</strong> Clear history, cookies, and cache from any web browser. To protect privacy and speed up browsing.</li>
        <li><strong>Uninstall Manager:</strong> Easily uninstall unwanted programs and applications to keep your system organized.</li>
        <li><strong>Startup Manager:</strong> Controls which programs run during system startup. Helps boot up faster and improves performance.</li>
        <li><strong>Duplicate File Finder:</strong> Helps identify and delete duplicate files to save storage space.</li>
        <li><strong>System Restore:</strong> Provides the ability to create and manage restore points. This ensures that users can return to their previous state if necessary.</li>
        <li><strong>Scheduled Cleaning:</strong>Allows users to schedule regular cleaning sessions to maintain peak performance without manual intervention.</li>
        <li><strong>Health Check:</strong> Provides a one-click solution to analyze and optimize system performance with recommended actions.</li>
        <li><strong>Privacy Protection:</strong>  Permanently delete important files and browsing history to ensure user privacy.</li>
      </ul>

      <h2 style={{ paddingLeft: '20px' , color: '#f57748' }}>System Requirements</h2>
      <ul>
        <li><strong>Operating System:</strong> Windows 10, Windows 8/8.1, Windows 7, or later</li>
        <li><strong>Processor:</strong> Intel Pentium 4 or later</li>
        <li><strong>RAM:</strong> Minimum of 1 GB (2 GB recommended)</li>
        <li><strong>Free Disk Space:</strong> At least 50 MB for installation</li>
        <li><strong>Display:</strong> 800 x 600 resolution or higher</li>
        <li><strong>Internet Connection:</strong> Required for installation and updates</li>
      </ul>


      <img 
      src='/images/MostP/Ccleaner.png' 
        alt="window11 logo" 
        style={{
          width: '600px',
          height: '400px',
          display: 'block', // Ensures block-level behavior for centering
          marginLeft: 'auto', 
          marginRight: 'auto' 
        }}
        
      />







      <h2 style={{ paddingTop: '10px' ,paddingLeft: '20px' , color: '#f57748' }}>Conclusion</h2>
      <p style={{ paddingLeft: '5px' }}>
        CCleaner is an essential tool for users looking to optimize their Windows systems and enhance privacy. Its comprehensive features make it suitable for both casual users and advanced users who want to keep their computers in top condition. By regularly cleaning and optimizing your system, CCleaner helps ensure a faster, more efficient computing experience.
      </p>


      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
      <Button
      variant="contained"
      color="primary"
      size="large"
      startIcon={<DownloadIcon />}
      onClick={handleDownload}
      style={{
        backgroundColor: '#0078D4',
        color: '#fff',
        padding: '10px 20px',
        fontSize: '16px',
        borderRadius: '8px',
        textTransform: 'none',
        marginBottom: '10px',
      }}
    >
   CCleaner for Windows
    </Button>


</div>


<p style={{ paddingLeft: '5px' }}>
              If the application is not installed on your Windows system, click the button below to proceed with the installation.
            </p>
            {/* <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                startIcon={<DownloadIcon />}
                onClick={handlevodeosclick}
                style={{
                  backgroundColor: 'green',
                  color: '#fff',
                  padding: '10px 20px',
                  marginLeft: '10px',
                  fontSize: '16px',
                  borderRadius: '8px',
                  textTransform: 'none',
                  marginBottom: '20px',
                }}
              >
                How to download and install 
              </Button>
              </div> */}
              

<div >
      <h2 style={{ paddingTop: '20px' , paddingLeft: '20px' ,  color: '#4f4bff' }}>  How to download and install Check the below video </h2>
      <ReactPlayer
      style={{ paddingTop: '20px'  }}
        url="https://youtu.be/HK_kiHpDLlE"
        controls={true} // Show controls for the player
        width="100%"    // Set the width of the player
        height="500px"  // Set the height of the player
      />
    </div>




      </div>
      </div>
      <div className="empty-area"></div>
    </div>
  <Footer />
  </div>
  );
}

export default CCleaner;
