import React from 'react';
import { Button } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import TopNavbar from '../../HeaderAndFooter/TopHeader';
import LogoComponent from '../../HeaderAndFooter/PageLogo';
import SocialMediaSidebar from '../../MenuBar/SocialMediaSidebar';
import MenuBar from '../../MenuBar/MenuBar';
import Footer from '../../HeaderAndFooter/Footer';



const AndroidStudio2024= () => {



    const handleDownload = () => {
      // Your download logic here
      window.location.href = 'https://developer.android.com/studio';


      // Example link
    };

  return (
    <div class="background-image">
   <TopNavbar />
   <LogoComponent/>
   <SocialMediaSidebar />


    <div  className="main-layout" >
    <MenuBar />
    </div>

    <div className="container" style={{ marginTop: '50px' }}>
      <div className="text-area">
    <div className='text-mainArea'> 

    <h1 style={{ paddingLeft: '30px' , color: '#06c620', marginTop: '10px'  }}>Overview of Android Studio 2024</h1>
      

      <p style={{ paddingLeft: '5px' }}>

Android Studio 2024 introduces many new features and improvements designed to enhance the development experience for Android app creators. It includes an improved user interface. Better performance and a streamlined workflow. Highlights include improved Kotlin support. Improved code editor Improved simulator with faster startup times and strong integration with modern Android frameworks. Additionally, Android Studio 2024 emphasizes improved support for large-scale projects. Custom debugging features and advanced profiling tools, all resulting in faster development cycles and higher quality apps.
      </p>
      <h2 style={{ paddingLeft: '20px' , color: '#06c620' }}>Key Features</h2>
      <ul>
        <li>
          <strong style={{  color: '#06c620' }}>Intelligent Code Editor:</strong> Enhanced code completion, refactoring, and real-time code analysis for improved productivity.
        </li>
        <li>
          <strong style={{  color: '#06c620' }}>Advanced Emulator:</strong> A faster and more reliable Android emulator with improved performance, supporting a wide range of devices and configurations.
        </li>
        <li>
          <strong style={{  color: '#06c620' }}>Jetpack Compose Support:</strong> Simplified UI development with declarative programming, enabling developers to build native UIs using Kotlin.
        </li>
        <li>
          <strong style={{  color: '#06c620' }}>Live Layout Inspector:</strong> Real-time inspection and editing of UI layouts on both emulators and physical devices.
        </li>
        <li>
          <strong style={{  color: '#06c620' }}>Built-in Git Support:</strong> Integrated version control tools for easier code management and collaboration within projects.
        </li>
        <li>
          <strong style={{  color: '#06c620' }}>Gradle Build System:</strong> Improved Gradle build system for faster builds and more efficient project configuration.
        </li>
        <li>
          <strong style={{  color: '#06c620' }}>Firebase Integration:</strong> Simplified integration with Firebase services for analytics, cloud storage, and more.
        </li>
        <li>
          <strong style={{  color: '#06c620' }}>Device File Explorer:</strong> Enhanced tools for exploring device file systems, allowing easier access to app data and resources.
        </li>
        <li>
          <strong style={{  color: '#06c620' }}>Testing Tools:</strong> Comprehensive testing frameworks and tools for unit, integration, and UI testing, improving app quality.
        </li>
        <li>
          <strong style={{  color: '#06c620' }}>New Project Templates:</strong> A variety of updated templates for quicker project setup, tailored to modern development practices.
        </li>
      </ul>

      <h2 style={{ paddingLeft: '20px' , color: '#06c620' }}>System Requirements</h2>
      <h3 style={{ paddingLeft: '20px' , color: '#06c620' }}>Minimum Requirements:</h3>
      <ul>
        <li>Operating System: Windows 10 (64-bit), macOS 11 or later, or a recent version of Linux.</li>
        <li>Processor: 2nd generation Intel Core i5 or equivalent.</li>
        <li>RAM: 4 GB (8 GB recommended).</li>
        <li>Graphics Card: GPU with support for OpenGL 2.0 or higher.</li>
        <li>Display: 1280 x 800 resolution or higher.</li>
        <li>Hard Disk Space: 4 GB of available hard-disk space for installation; SSD recommended for performance.</li>
      </ul>

      <h3 style={{ paddingLeft: '20px' , color: '#06c620' }}>Recommended Requirements:</h3>
      <ul>
        <li>Operating System: Windows 11, macOS 12 or later, or the latest Linux distributions.</li>
        <li>Processor: 3rd generation Intel Core i5 or equivalent.</li>
        <li>RAM: 8 GB or more.</li>
        <li>Graphics Card: Dedicated GPU with 2 GB of VRAM or more.</li>
        <li>Display: 1920 x 1080 resolution or greater.</li>
        <li>Hard Disk Space: SSD recommended for improved performance.</li>
      </ul>


      <img 
        src='/images/Development/Android Studio 2024pic1.jpg' 
        alt="Windows 11 Logo" 
        style={{
          width: '600px',
          height: '400px',
          display: 'block', // Ensures block-level behavior for centering
          marginLeft: 'auto', 
          marginRight: 'auto' ,
        }} 
      />





      <h2 style={{ paddingLeft: '20px' , color: '#06c620' }}>Conclusion</h2>
      <p style={{ paddingLeft: '5px' }}>
      Android Studio 2024 provides a comprehensive development environment for building Android applications, with advanced tools and support for modern programming practices. Its strong capabilities make it suitable for both beginners and experienced developers. Make sure your system meets the necessary requirements for optimum performance.
      </p>


      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>    

      <Button
      variant="contained"
      color="primary"
      size="large"
      startIcon={<DownloadIcon />}
      onClick={handleDownload}
      style={{
        backgroundColor: '#0078D4',
        color: '#fff',
        padding: '10px 20px',
        fontSize: '16px',
        borderRadius: '8px',
        textTransform: 'none',
        marginBottom: '20px',
      }}
    >
  Free DownLoad Android Studio 2024
    </Button>

</div>


<p  style={{ paddingLeft: '5px' }}>
              If the application is not installed on your Windows system, click the button below to proceed with the installation.
            </p>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                startIcon={<DownloadIcon />}
               
                style={{
                  backgroundColor: 'green',
                  color: '#fff',
                  padding: '10px 20px',
                  marginLeft: '10px',
                  fontSize: '16px',
                  borderRadius: '8px',
                  textTransform: 'none',
                  marginBottom: '20px',
                }}
              >
                How to download and install 
              </Button>

              </div>




      </div>
      </div>
      <div className="empty-area"></div>
    </div>
  <Footer />
  </div>
  );
}

export default AndroidStudio2024 ;
