import React from 'react';
import '../css/Mission.css'; 
import TopNavbar from './TopHeader';
import LogoComponent from './PageLogo';
import SocialMediaSidebar from '../MenuBar/SocialMediaSidebar';
import MenuBar from '../MenuBar/MenuBar';
import Footer from './Footer';

const Mission = () => {
  return (

    <div class="background-image">
    <TopNavbar />
    <LogoComponent/>
    <SocialMediaSidebar />
 
 
     <div  className="main-layout" style={{ marginTop: '10px' }}>
     <MenuBar />
     </div>
 
     <div className="container">
       <div className="text-area">
       <div className="mission-container">
      <h1 className="mission-title">Our Mission</h1>
      <p className="mission-description">
        At <span className="highlight">Easy PC Software</span>, our mission is to make technology accessible to everyone. We strive to provide a comprehensive platform for software downloads, featuring everything from operating systems and graphic design tools to development software and games.
      </p>
      <p className="mission-description">
        We prioritize user experience by ensuring that each software can be easily downloaded and installed offline. Whether you’re a professional seeking the latest design software or a gamer excited to explore new titles, our user-friendly site is designed to simplify the process, allowing you to effortlessly access the tools you need.
      </p>
      <p className="mission-description">
        Join us on this journey and discover just how easy it is to enhance your PC experience!
      </p>
    </div>
       </div>
       <div className="empty-area"></div>
     </div>
   <Footer />
   </div>
  );
};

export default Mission;
