import React from 'react';
import TopNavbar from '../../HeaderAndFooter/TopHeader';
import LogoComponent from '../../HeaderAndFooter/PageLogo';
import SocialMediaSidebar from '../../MenuBar/SocialMediaSidebar';
import MenuBar from '../../MenuBar/MenuBar';
import Footer from '../../HeaderAndFooter/Footer';
import '../css/NukeStudioOverview.css';
import { Button } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';



const EvaerVideoRecorderforSkype= () => {



    const handleDownload = () => {
      // Your download logic here
      window.location.href = 'https://www.evaer.com/download.htm'; // Example link
    };





  return (
    <div class="background-image">
   <TopNavbar />
   <LogoComponent />
   <SocialMediaSidebar />


    <div  className="main-layout" >
    <MenuBar />
    </div>

    <div className="container" style={{ marginTop: '50px' }}>
      <div className="text-area">
    <div className='text-mainArea'> 
    <h2 style={{ paddingLeft: '30px' , color: '#0fbf5f', marginTop: '20px'  }}>Overview of Evaer Video Recorder for Skype</h2>
      <p style={{ paddingLeft: '5px' }}>
      Evaer Video Recorder for Skype is a special software designed to record and save Skype video calls. This tool is especially useful for users who want to record important conversations. Create a video course or record a virtual meeting.
      </p>

      <h2 style={{ paddingLeft: '20px' , color: '#f49c0b' }}>Key Features</h2>
      <ul className="features-list">
        <li>
          <strong style={{  color: '#0c6dc9' }}>High-Quality Video Recording:</strong> Record video calls in high-definition (HD) for clear and professional content.
        </li>
        <li>
          <strong style={{  color: '#0c6dc9' }}>Audio Capture:</strong> Capture audio from both sides of the conversation for complete context.
        </li>
        <li>
          <strong style={{  color: '#0c6dc9' }}>Separate Audio Tracks:</strong> Record audio tracks separately for each participant, providing flexibility in editing.
        </li>
        <li>
          <strong style={{  color: '#0c6dc9' }}>Snapshot Functionality:</strong> Take snapshots during calls to capture important moments quickly.
        </li>
        <li>
          <strong style={{  color: '#0c6dc9' }}>User-Friendly Interface:</strong> Start recording with just a few clicks thanks to an intuitive design.
        </li>
        <li>
          <strong style={{  color: '#0c6dc9' }}>Customizable Settings:</strong> Adjust video resolution, audio format, and storage location to suit preferences.
        </li>
        <li>
          <strong style={{  color: '#0c6dc9' }}>Recording Notifications:</strong> Get visual and audio notifications when recording begins and ends.
        </li>
        <li>
          <strong style={{  color: '#0c6dc9' }}>Automatic Recording:</strong> Set up automatic recording for all Skype calls to capture conversations effortlessly.
        </li>
        <li>
          <strong style={{  color: '#0c6dc9' }}>Compatibility:</strong> Works with various versions of Skype, including desktop and mobile.
        </li>
        <li>
          <strong style={{  color: '#0c6dc9' }}>File Management:</strong> Easily organize and manage recorded files, with options to rename, delete, or share.
        </li>
      </ul>

      <h2 style={{ paddingLeft: '20px' , color: '#f49c0b' }}>Use Cases</h2>
      <ul className="use-cases-list">
        <li><strong style={{  color: '#0c6dc9' }}>Business Meetings:</strong> Capture important discussions for future reference.</li>
        <li><strong style={{  color: '#0c6dc9' }}>Interviews:</strong> Record job interviews conducted via Skype for review.</li>
        <li><strong style={{  color: '#0c6dc9' }}>Tutorial Creation:</strong> Create video tutorials by recording instructional calls.</li>
        <li><strong style={{  color: '#0c6dc9' }}>Personal Conversations:</strong> Save memorable video calls with friends and family.</li>
      </ul>

      <img 
        src='/images/MultiMedia/Evaer Video Recorder for Skypepic1.png' 
        alt="Windows 11 Logo" 
        style={{
          width: '600px',
          height: '400px',
          display: 'block', // Ensures block-level behavior for centering
          marginLeft: 'auto', 
          marginRight: 'auto' ,
        }} 
      />



      <h2 style={{ paddingLeft: '20px' , color: '#f49c0b' }}>Conclusion</h2>
      <p style={{ paddingLeft: '5px' }}>
      Evaer Video Recorder for Skype is an essential tool for anyone who wants to easily and efficiently record Skype conversations. With high-quality recording capabilities and an easy-to-use interface. Supports a variety of use cases From business to personal Easily capture and preserve important video communications.
      </p>




      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}> 
      <Button
      variant="contained"
      color="primary"
      size="large"
      startIcon={<DownloadIcon />}
      onClick={handleDownload}
      style={{
        backgroundColor: '#0078D4',
        color: '#fff',
        padding: '10px 20px',
        fontSize: '16px',
        borderRadius: '8px',
        textTransform: 'none',
        marginBottom: '20px',
      }}
    >
      Free Download Evaer Video Recorder for Skype
    </Button>

</div>
<p style={{ paddingLeft: '5px' }}>
              If the application is not installed on your Windows system, click the button below to proceed with the installation.
            </p>


<div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}> 

    <Button
      variant="contained"
      color="primary"
      size="large"
      startIcon={<DownloadIcon />}
     
      style={{
        backgroundColor: '#47f40b',
        color: '#fff',
        padding: '10px 20px',
        marginLeft: '5px',
        fontSize: '16px',
        borderRadius: '8px',
        textTransform: 'none',
        marginBottom: '20px',
      }}
    >
  Offical Buy Games Teaser AEP
    </Button>

</div>



      </div>
      </div>
      <div className="empty-area"></div>
    </div>
  <Footer />
  </div>
  );
}

export default EvaerVideoRecorderforSkype;
