import React, { useState } from "react";
import '../OperatingSystem/css/operatingsysyemCat.css';
import { useNavigate } from "react-router-dom";


const categories = [

  {
    id:1,
    image: 'images/MultiMedia/FoundryNuke .png',
    title: ' Free Download The Foundry Nuke Studio 2024',
    time: '2024-09-12',
    details: 'The Foundry Nuke Studio 2024 is a powerful compositing and finishing software used in visual effects and post-production. It provides advanced tools for node-based compositing, 3D tracking, and color grading, enabling high-quality visual effects and seamless integration with other industry-standard applications.'
  },
  {
    id: 2,
    image: 'images/MultiMedia/GOMPlayer.png',
    title: 'Free Download GOM Player Plus 2024  ',
    time: '2024-09-10',
    details: 'GOM Player Plus 2024 is a versatile media player that supports a wide range of formats, providing smooth playback and advanced features like subtitle support and video effects. Its user-friendly interface and customizable options make it ideal for both casual viewers and multimedia enthusiasts.'
  },
  {
    id: 3,
    image: 'images/MultiMedia/spin3d.png',
    title: 'Free Download NCH Spin 3D Plus 2024 ',
    time: '2024-09-04',
    details: 'NCH Spin 3D Plus 2024 is a powerful 3D object converter that enables users to effortlessly transform 3D models between various formats. With an intuitive interface and advanced features, it simplifies the process of importing, exporting, and optimizing 3D files for diverse applications.'
  }
  ,
  {
    id: 4,
    image: 'images/MultiMedia/ProPresenter.png',
    title: 'Free Download ProPresenter 2024 ',
    time: '2024-09-04',
    details: 'ProPresenter 2024 is a robust presentation software designed for live events, enabling users to create dynamic slideshows with multimedia support. Its intuitive interface and powerful features, such as real-time editing and seamless integration with various media sources, make it ideal for churches, conferences, and broadcast productions.'
  }
  

  ,
  {
    id: 5,
    image: 'images/MultiMedia/profmedia.png',
    title: 'Free Download Leawo Prof. Media 2024 ',
    time: '2024-09-04',
    details: 'Leawo Prof. Media 2024 is an all-in-one media suite that combines tools for video conversion, DVD/Blu-ray burning, and media downloading. With its user-friendly interface and extensive format support, it simplifies the process of managing and enhancing your media collection for both personal and professional use.'
  },



  {
    id: 6,
    image: 'images/MultiMedia/Adobe Bridge.png',
    title: 'Free Download Adobe Bridge 2024 ',
    time: '2024-09-06',
    details: 'Adobe Bridge 2024 is a powerful media asset management tool that streamlines the organization, editing, and sharing of creative assets. With enhanced features for batch processing, metadata management, and seamless integration with other Adobe applications, it helps creatives efficiently manage their workflows and maintain a cohesive project organization.'
  },
  {
    id: 7,
    image: 'images/MultiMedia/CyberLink.png',
    title: 'Free Download CyberLink ColorDirector 2024 ',
    time: '2024-09-05',
    details: 'CyberLink ColorDirector Ultra 2024 is a comprehensive color grading and video enhancement software that offers advanced tools for color correction, grading, and effects. With its intuitive interface and powerful features, users can achieve professional-quality visuals by fine-tuning colors, applying presets, and leveraging keyframe-based adjustments for stunning cinematic results.'
  },
  {
    id: 8,
    image: 'images/MultiMedia/AppleTune.png',
    title: 'Free Download Apple iTunes 2024 ',
    time: '2024-09-04',
    details: 'Apple iTunes 2023 is a multimedia management software that allows users to organize, purchase, and play music, movies, and podcasts. With features like seamless syncing across devices, curated playlists, and access to the Apple Music library, it provides a comprehensive platform for enjoying and managing digital content.'
  }
  ,
  {
    id: 9,
    image: 'images/MultiMedia/RecorderforSkype.png',
    title: 'Free Download Evaer Video Recorder for Skype 2024 ',
    time: '2024-09-04',
    details: 'Evaer Video Recorder for Skype 2024 is a user-friendly software designed to capture high-quality video and audio calls on Skype. With features like multiple recording formats, simultaneous recordings, and built-in editing tools, it ensures users can easily save and share important conversations and meetings.'
  }
  

  ,
  {
    id: 10,
    image: 'images/MultiMedia/DVDFab.png',
    title: 'Free Download DVDFab Toolkit 2024 ',
    time: '2024-09-04',
    details: 'DVDFab Toolkit 2024 is a versatile all-in-one video editing software that offers tools for video conversion, editing, and enhancement. With features like watermark removal, video compression, and batch processing, it provides an intuitive interface for users to easily create and customize their multimedia projects.'
  }
];





// Component to display individual category information
const CategoryCard = ({ category, onClick }) => {


  return (
    <div className="category-card" onClick={onClick}>
      <img src={category.image} alt={category.title} className="category-image" />
      <div className="category-info">
        <h3>{category.title}</h3>
        <p className="category-time">Added on: {category.time}</p>
        <p>{category.details}</p>
      </div>
    </div>
  );
};





// Main Component to display the list of categories
const MultiMediaCategoryList = () => {
  const navigate = useNavigate();  // React Router's navigation hook

  const handleCardClick = (categoryId) => {
    if (categoryId === 1) {
      navigate("/TheFoundryNukeStudio2024"); // Navigate to the Windows 7 page
    } else if (categoryId === 2) {
      navigate("/GOMPlayerPlus2024"); // Navigate to the Windows 10 page
    }else if (categoryId === 3) {
      navigate("/NCHSpin3DPlus2024"); // Navigate to the Windows 10 page
    }else if (categoryId === 4) {
      navigate("/ProPresenter2024"); // Navigate to the Windows 10 page
    }else if (categoryId === 5) {
      navigate("/LeawoProfMedia2024"); // Navigate to the Windows 10 page
    }else if (categoryId === 6) {
      navigate("/AdobeBridge2024"); // Navigate to the Windows 10 page
    }else if (categoryId === 7) {
      navigate("/CyberLinkColorDirector2024"); // Navigate to the Windows 10 page
    }else if (categoryId === 8) {
      navigate("/AppleiTunes2024"); // Navigate to the Windows 10 page
    }else if (categoryId === 9) {
      navigate("/EvaerVideoRecorderforSkype"); // Navigate to the Windows 10 page
    }else if (categoryId === 10) {
      navigate("/DVDFabToolkit2024"); // Navigate to the Windows 10 page
    }



    
    



    // Add more conditions for other categories if needed
  };

  return (
    <div className="Main-category">
    <div className="category-list">
      {categories.map((category) => (
        <CategoryCard key={category.id} category={category} onClick={() => handleCardClick(category.id)} />
      ))}


    
    </div>
   
    </div>
  );
};

export default MultiMediaCategoryList;




