import React from 'react';
import TopNavbar from '../../HeaderAndFooter/TopHeader';
import LogoComponent from '../../HeaderAndFooter/PageLogo';
import SocialMediaSidebar from '../../MenuBar/SocialMediaSidebar';
import MenuBar from '../../MenuBar/MenuBar';
import Footer from '../../HeaderAndFooter/Footer';
import '../css/NukeStudioOverview.css';
import { Button } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';



const ProPresenter2024= () => {



    const handleDownload = () => {
      // Your download logic here
      window.location.href = 'https://getintopc.com/softwares/multimedia/propresenter-2024-free-download/'; // Example link
    };





  return (
    <div class="background-image">
   <TopNavbar />
   <LogoComponent />
   <SocialMediaSidebar />


    <div  className="main-layout" >
    <MenuBar />
    </div>

    <div className="container" style={{ marginTop: '50px' }}>
      <div className="text-area">
    <div className='text-mainArea'> 
    <h1 style={{ paddingLeft: '30px' , color: '#0fbf5f', marginTop: '20px'  }}>Overview of ProPresenter 2024</h1>
      <p style={{ paddingLeft: '5px' }}>
      ProPresenter 2024 is powerful presentation software designed for live events, worship services, and broadcasts. It is a multi-purpose platform for creating and presenting dynamic presentations with multimedia content. With a robust feature set, ProPresenter helps users manage slides, videos, and graphics seamlessly.
      </p>

      <h2 style={{ paddingLeft: '20px' , color: '#f49c0b' }}>Key Features</h2>
      <ul className="features-list">
        <li>
          <strong style={{  color: '#0c6dc9' }}>Multi-Screen Support:</strong> Supports multiple displays for immersive presentations.
        </li>
        <li>
          <strong style={{  color: '#0c6dc9' }}>Flexible Slide Creation:</strong> Create slides with text, images, videos, and audio.
        </li>
        <li>
          <strong style={{  color: '#0c6dc9' }}>Built-In Media Library:</strong> Organize and access multimedia assets quickly.
        </li>
        <li>
          <strong style={{  color: '#0c6dc9' }}>Dynamic Text and Graphics:</strong> Animate text and graphics for engaging transitions.
        </li>
        <li>
          <strong style={{  color: '#0c6dc9' }}>Live Video Integration:</strong> Incorporate live video feeds into presentations.
        </li>
        <li>
          <strong style={{  color: '#0c6dc9' }}>Remote Control and Cueing:</strong> Manage presentations from mobile devices for smooth transitions.
        </li>
        <li>
          <strong style={{  color: '#0c6dc9' }}>Customizable Templates:</strong> Use a variety of templates to create visually appealing presentations.
        </li>
        <li>
          <strong style={{  color: '#0c6dc9' }}>Support for Worship Presentation:</strong> Features for song lyric projection and scripture display.
        </li>
        <li>
          <strong style={{  color: '#0c6dc9' }}>Collaboration Tools:</strong> Multiple users can work on presentations simultaneously.
        </li>
        <li>
          <strong style={{  color: '#0c6dc9' }}>Cross-Platform Compatibility:</strong> Available for both macOS and Windows.
        </li>
      </ul>

      <h2 style={{ paddingLeft: '20px' , color: '#f49c0b' }}>Use Cases</h2>
      <ul className="use-cases-list">
        <li><strong style={{  color: '#0c6dc9' }}>Worship Services:</strong> Project lyrics, scripture, and multimedia during services.</li>
        <li><strong style={{  color: '#0c6dc9' }}>Corporate Presentations:</strong> Create impactful presentations with multimedia content.</li>
        <li><strong style={{  color: '#0c6dc9' }}>Education:</strong> Engage students with visuals and interactive elements in lessons.</li>
        <li><strong style={{  color: '#0c6dc9' }}>Live Events:</strong> Ideal for conferences, concerts, and other live events.</li>
      </ul>


      <img 
        src='/images/MultiMedia/ProPresenter 2024pic1.png' 
        alt="Windows 11 Logo" 
        style={{
          width: '600px',
          height: '400px',
          display: 'block', // Ensures block-level behavior for centering
          marginLeft: 'auto', 
          marginRight: 'auto' ,
        }} 
      />


      <h2 style={{ paddingLeft: '20px' , color: '#f49c0b' }}>Conclusion</h2>
      <p style={{ paddingLeft: '5px' }}>
      ProPresenter 2024 is a versatile and powerful tool for creating and presenting presentations. Extensive feature set which includes support for multiple screens Live video integration and dynamic text animations This makes it essential for church educators and organizational professionals. For your worship service or corporate event, ProPresenter gives you the tools you need to create engaging and stunning presentations.
      </p>

      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
      <Button
      variant="contained"
      color="primary"
      size="large"
      startIcon={<DownloadIcon />}
      onClick={handleDownload}
      style={{
        backgroundColor: '#0078D4',
        color: '#fff',
        padding: '10px 20px',
        fontSize: '16px',
        borderRadius: '8px',
        textTransform: 'none',
        marginBottom: '20px',
      }}
    >
      Free Download ProPresenter 2024
    </Button>

</div>


<p style={{ paddingLeft: '5px' }}>
              If the application is not installed on your Windows system, click the button below to proceed with the installation.
            </p>


<div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}> 

    <Button
      variant="contained"
      color="primary"
      size="large"
      startIcon={<DownloadIcon />}
     
      style={{
        backgroundColor: '#47f40b',
        color: '#fff',
        padding: '10px 20px',
        marginLeft: '5px',
        fontSize: '16px',
        borderRadius: '8px',
        textTransform: 'none',
        marginBottom: '20px',
      }}
    >
  Offical Buy Games Teaser AEP
    </Button>

</div>





      </div>
      </div>
      <div className="empty-area"></div>
    </div>
  <Footer />
  </div>
  );
}

export default ProPresenter2024;
