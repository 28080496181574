import React from 'react';
import '../css/categories.css';
import './NextButton.css';
import { useNavigate } from "react-router-dom";
// Sample data (You can replace this with dynamic data later)
const categories = [
  {
    id:1,
    image: 'images/MostP/Avestt.png',
    title: 'Free Download Avast Antivirus for Windows',
    time: '2025-01-08',
    details: 'Avast Antivirus for Windows is an all-round security solution offering real-time protection against malware, viruses, ransomware, and phishing attacks. This includes system performance optimization, firewall, and Wi-Fi security features to safeguard the data of the users and improve overall system security.'
  },
  {
    id: 2,
    image: 'images/MostP/Chrome.png',
    title: 'Free Download Google Chrome for Windows ',
    time: '2025-01-08',
    details: 'Google Chrome for Windows is a fast and secure web browser, offering intuitive interface, efficient performance, and great support for web standards. These devices have built-in tools for privacy, security and sync, enhancing the user experience with easy browsing and powerful extensions.'
  },
  {
    id: 3,
    image: 'images/MostP/AVGg.png',
    title: 'Free Download AVG AntiVirus for Windows',
    time: '2025-01-08',
    details: 'AVG AntiVirus for Windows is designed as a robust security solution providing real-time virus, malware, spyware, and phishing attacks protection. It allows further tools for email scanning, improved system performance, and enhanced privacy controls to secure your personal data and system performance.'
  }
  ,
  {
    id: 4,
    image: 'images/MostP/VLCC.png',
    title: 'Free Download VLC Media Player for Windows',
    time: '2025-01-08',
    details: 'VLC Media Player for Windows is an all-in-one, open source media player that reads most audio and video formats as well as DVDs, Blu-rays, and streaming protocols. It offers an interface of extreme simplicity while powerful playback features are given without additional codecs.'
  }
  

  ,
  {
    id: 5,
    image: 'images/MostP/Winrar.png',
    title: 'Free Download WinRAR 64-bit for Windows',
    time: '2025-01-08',
    details: 'WinRAR 64-bit for Windows is a powerful file compression and archiving tool, with support for formats such as RAR and ZIP, to help users compress, encrypt, and manage files in the most efficient manner. Advanced compression algorithms, password protection, and multi-volume archive creation are its features.'
  },



  {
    id: 6,
    image: 'images/MostP/cclear.png',
    title: 'Free Download CCleaner for Windows',
    time: '2025-01-08',
    details: 'CCleaner is a Windows System Optimization which is used to clean temporary files, manage startup items and fix registry issues. It has privacy protection features, such as deleting all types of file and browse data, tracking files, and the like.'
  },
  {
    id: 7,
    image: 'images/MostP/whatsapp.png',
    title: 'Free Download WhatsApp Messenger 64-bit for PC Windows',
    time: '2025-01-08',
    details: 'WhatsApp Messenger 64-bit for PC Windows is an application used for texting and making voice and video calls, sharing files. In addition, these are all synchronized via the mobile version of WhatsApp. The App offers a seamless messaging experience with cross-device support and end-to-end encryption.'
  },
  {
    id: 8,
    image: 'images/MostP/anyDesk.png',
    title: 'Free Download AnyDesk for Windows',
    time: '2025-01-08',
    details: 'AnyDesk is one of the smallest remote desktop applications for Windows; it can let users remotely access and control other computers securely from any location, thanks to high speed, low latency, and encryption.'
  }
  ,
  {
    id: 9,
    image: 'images/MostP/Rufus.png',
    title: 'Free Download Rufus for Windows',
    time: '2025-01-08',
    details: 'Rufus for Windows is a lightweight utility that helps in formatting and creating bootable USB drives, making it ideal for installing operating systems or running live systems. It supports a wide range of file systems and disk images, offering fast and reliable performance for creating installation media.'
  }
  

  ,
  {
    id: 10,
    image: 'images/MostP/3utools.png',
    title: 'Free Download 3uTools for Windows',
    time: '2025-01-08',
    details: '3uTools for Windows is a great tool for the management of iOS devices, providing the user with the ability to flash firmware, jailbreak, and manage applications, photos, music, and other files. It provides an intuitive interface and advanced features in the optimization, backup, and customization of devices.'
  }
];








// Component to display individual category information
const CategoryCard = ({ category, onClick }) => {
  return (
    <div className="category-card"  onClick={onClick}>
      <img src={category.image} alt={category.title} className="category-image" />
      <div className="category-info">
        <h3>{category.title}</h3>
        <p className="category-time">Added on: {category.time}</p>
        <p>{category.details}</p>
      </div>
    </div>
  );
};









// Main Component to display the list of categories
const CategoryList = () => {

  const navigate = useNavigate();  // React Router's navigation hook

  const handleCardClick = (categoryId) => {
    if (categoryId === 1) {
      navigate("/AvastAntivirusoft"); // Navigate to the Windows 7 page
    } else if (categoryId === 2) {
      navigate("/GoogleChrome1"); // Navigate to the Windows 10 page
    }else if (categoryId === 3) {
      navigate("/AVGAntiVirus"); // Navigate to the Windows 10 page
    }else if (categoryId === 4) {
      navigate("/VLCMedia"); 
    }
    else if (categoryId === 5) {
      navigate("/WinRAR64bit"); // Navigate to the Windows 10 page
    }
    else if (categoryId === 6) {
      navigate("/CCleaner"); // Navigate to the Windows 10 page
    }
    else if (categoryId === 7) {
      navigate("/WhatsAppMessenger1"); // Navigate to the Windows 10 page
    }
    else if (categoryId === 8) {
      navigate("/AnyDesk"); // Navigate to the Windows 10 page
    }
    else if (categoryId === 9) {
      navigate("/Rufus"); // Navigate to the Windows 10 page
    }
    else if (categoryId === 10) {
      navigate("/ThreeuTools"); // Navigate to the Windows 10 page
    }

  };



  return (
    <div className="Main-category">
    <div className="category-list">
      {categories.map((category) => (
        <CategoryCard  key={category.id} category={category} onClick={() => handleCardClick(category.id)} />
      ))}
    </div>

    <button className="next-button">
      Next Page
      <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2 5L8 5" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M5 2L5 8" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    </button>
  
    </div>
  );
};

export default CategoryList;
