


import React from 'react';
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap';
import '../css/topnav.css'; // Make sure your CSS is linked

const TopNavbar = () => {
  return (
    <Navbar bg="info" expand="lg">
      <Container>
        {/* Navbar Branding and Links */}
        {/* <Navbar.Brand href="#">Brand</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="#home">Home</Nav.Link>
            <Nav.Link href="#features">Features</Nav.Link>
          </Nav>
        </Navbar.Collapse> */}

        {/* Moving Text Line */}
        <div className="moving-text">
          <p>EASY PC Soft is the simplest platform through which a number of software programs can be easily downloaded free of charge. Whatever one needs-to get productive, entertained, or enhance system performance, Easy PC Soft has everything to offer.</p>
        </div>
      </Container>
    </Navbar>
  );
};

export default TopNavbar;
