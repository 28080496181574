import React from 'react';
import { Button } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import TopNavbar from '../HeaderAndFooter/TopHeader';
import LogoComponent from '../HeaderAndFooter/PageLogo';
import SocialMediaSidebar from '../MenuBar/SocialMediaSidebar';
import MenuBar from '../MenuBar/MenuBar';
import Footer from '../HeaderAndFooter/Footer';
import ReactPlayer from "react-player";


const WinRAR= () => {



    const handleDownload = () => {
      // Your download logic here
      // window.location.href = 'https://www.rarlab.com/download.htm';
      window.location.href = 'SoftwareHere/homesoft/Easypcsoft_winrar-x64-701.exe';
      // Example link
    };

    // const openyoutubevides = () => {
    //   // Your download logic here
    //   // window.location.href = 'https://www.rarlab.com/download.htm';
    //   window.location.href = 'https://youtu.be/wI8tLxjRu0M';
    //   // Example link
    // };



    

  return (
    <div class="background-image">
   <TopNavbar/>
   <LogoComponent />
   <SocialMediaSidebar />


    <div  className="main-layout" >
    <MenuBar />
    </div>

    <div className="container" style={{ marginTop: '50px' }}>
      <div className="text-area">
    <div className='text-mainArea'> 

    <h2 style={{ textAlign: 'center', color: '#e7059c', marginTop: '10px' }}>Overview of WinRAR 64-bit for Windows</h2>
      <p style={{ paddingLeft: '5px' }}>
      WinRAR is a powerful file compression and archiving software that is widely used to manage compressed files. It has a wide range of features that make it an essential tool for users who frequently work with large files or need to transfer multiple files efficiently. With support for a wide range of compression formats and a user-friendly interface, WinRAR offers powerful functionality for both casual and professional users.
      </p>

      <h2 style={{ paddingLeft: '20px' , color: '#e7059c' }}>Key Features</h2>
      <ul>
        <li><strong>High Compression Ratio:</strong> WinRAR uses advanced compression algorithms to create smaller archive files, saving disk space and reducing transfer times.</li>
        <li><strong>Supports Multiple Formats:</strong> In addition to its own RAR format, WinRAR can unpack ZIP, 7-Zip, TAR, GZ, and other popular archive formats.</li>
        <li><strong>Split Archives:</strong> Users can split large archives into smaller volumes for easier storage and transfer, making it ideal for email attachments or storage on removable media.</li>
        <li><strong>Encryption:</strong> Provides AES (Advanced Encryption Standard) encryption with a key size of 128 bits, ensuring that your files remain secure.</li>
        <li><strong>Error Recovery:</strong> Includes a recovery record feature that allows users to repair damaged archives, helping to recover lost data.</li>
        <li><strong>Drag-and-Drop Functionality:</strong> The user-friendly interface supports drag-and-drop for easy file management, making it simple to create and extract archives.</li>
        <li><strong>Command Line Interface:</strong> Advanced users can utilize the command line interface for scripting and automating tasks related to archiving.</li>
        <li><strong>Multi-Platform Compatibility:</strong> While primarily for Windows, WinRAR also offers versions for macOS and Linux, allowing cross-platform usage.</li>
        <li><strong>Shell Integration:</strong> Integrates seamlessly with Windows Explorer, providing context menu options for quick access to compression and extraction functions.</li>
        <li><strong>Trial Version:</strong> WinRAR offers a free trial period, allowing users to evaluate its features before purchasing a license.</li>
      </ul>

      <h2 style={{ paddingLeft: '20px' , color: '#e7059c' }}>System Requirements</h2>
      <ul>
        <li><strong>Operating System:</strong> Windows 10, Windows 8/8.1, Windows 7, or later (64-bit versions)</li>
        <li><strong>Processor:</strong> Intel Pentium 4 or later</li>
        <li><strong>RAM:</strong> Minimum of 1 GB (2 GB recommended)</li>
        <li><strong>Free Disk Space:</strong> At least 40 MB for installation</li>
        <li><strong>Display:</strong> 800 x 600 resolution or higher</li>
        <li><strong>Internet Connection:</strong> Required for installation and updates</li>
      </ul>


      <img 
      src='/images/MostP/winrar64bitforwindows.jpeg' 
        alt="window11 logo" 
        style={{
          width: '600px',
          height: '400px',
          display: 'block', // Ensures block-level behavior for centering
          marginLeft: 'auto', 
          marginRight: 'auto' ,
      
        }}
        
      />





      <h2 style={{ paddingLeft: '20px',paddingTop: '10px' , color: '#e7059c' }}>Conclusion</h2>
      <p>
      WinRAR is an crucial device for anyone desiring green document compression and archiving competencies. With its tremendous characteristic set, which include excessive compression ratios, multi-format support, and sturdy security options, it's far appropriate for both informal customers and experts. Whether you want to compress files for garage, share them on-line, or actually manage your data extra efficaciously, WinRAR affords the gear necessary to get the job executed.


      </p>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
      <Button
      variant="contained"
      color="primary"
      size="large"
      startIcon={<DownloadIcon />}
      onClick={handleDownload}
      style={{
        backgroundColor: '#0078D4',
        color: '#fff',
        padding: '10px 20px',
        fontSize: '16px',
        borderRadius: '8px',
        textTransform: 'none',
        marginBottom: '20px',
      }}
    >
     WinRAR 64-bit for Windows
    </Button>
</div>

<p>
              If the application is not installed on your Windows system, click the button below to proceed with the installation.
            </p>
            {/* <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                startIcon={<DownloadIcon />}
                onClick={openyoutubevides}
                style={{
                  backgroundColor: 'green',
                  color: '#fff',
                  padding: '10px 20px',
                  marginLeft: '10px',
                  fontSize: '16px',
                  borderRadius: '8px',
                  textTransform: 'none',
                  marginBottom: '20px',
                }}
              >
                How to download and install 
              </Button>
              </div> */}





    <div >
      <h2 style={{ paddingTop: '20px' , paddingLeft: '20px' ,  color: '#4f4bff' }}>  How to download and install Check the below video </h2>
      <ReactPlayer
      style={{ paddingTop: '20px'  }}
        url="https://youtu.be/wI8tLxjRu0M"
        controls={true} // Show controls for the player
        width="100%"    // Set the width of the player
        height="500px"  // Set the height of the player
      />
    </div>


      </div>
      </div>
      <div className="empty-area"></div>
    </div>
  <Footer />
  </div>
  );
}

export default WinRAR;
