import React from 'react';
import TopNavbar from '../../HeaderAndFooter/TopHeader';
import LogoComponent from '../../HeaderAndFooter/PageLogo';
import SocialMediaSidebar from '../../MenuBar/SocialMediaSidebar';
import MenuBar from '../../MenuBar/MenuBar';
import Footer from '../../HeaderAndFooter/Footer';
import './css/windows10sp1.css';
import { Button } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';



const Mojave= () => {



    const handleDownload = () => {
      // Your download logic here
      window.location.href = 'https://getintopc.com/softwares/operating-systems/mac-os-mojave-10-14-1-vmware-image-free-download/';


      // Example link
    };





  return (
    <div class="background-image">
   <TopNavbar />
   <LogoComponent />
   <SocialMediaSidebar />


    <div  className="main-layout" >
    <MenuBar />
    </div>

    <div className="container" style={{ marginTop: '50px' }}>
      <div className="text-area">
    <div className='text-mainArea'> 

    <h1 style={{ textAlign: 'center', color: '#090460', marginTop: '10px' }}>Overview of macOS Mojave</h1>
      <p style={{ paddingLeft: '8px' }}>
      MacOS Mojave is the fifteenth release of Apple's main operating system for Mac computers, focused on increasing productivity. Privacy and usability, with new features There are many that cater to everyday users and professionals.
      </p>
      
      <h2 style={{ paddingLeft: '20px' , color: '#090460' }}>Key Features</h2>
      <ul>
        <li><strong>Dark Mode:</strong> A new system-wide dark theme that allows users to switch their interface to a darker color palette, making it easier on the eyes, especially in low-light environments.</li>
        <li><strong>Desktop Stacks:</strong> Automatically organizes files on the desktop into stacks based on file type, date, or tags, helping to declutter the workspace.</li>
        <li><strong>Finder Enhancements:</strong> Introduces a new Gallery View, Quick Actions, and improved file sharing options, making it easier to manage and preview files.</li>
        <li><strong>Screen Time:</strong> A feature that allows users to monitor and manage their device usage, helping to promote healthier technology habits.</li>
        <li><strong>Enhanced Privacy Features:</strong> Provides more control over privacy with improved permissions for accessing the camera and microphone, as well as enhanced protection against data tracking.</li>
        <li><strong>News, Stocks, Voice Memos, and Home Apps:</strong> These iOS apps make their debut on macOS, providing a consistent experience across devices.</li>
        <li><strong>Quick Look:</strong> Allows users to preview files in a more interactive way, including videos and images, with added features like markup tools.</li>
        <li><strong>Continuity Camera:</strong> Enables users to take photos or scan documents with their iPhone and have them instantly appear on their Mac.</li>
        <li><strong>Improved Performance:</strong> Optimizations for speed and responsiveness across various applications and system functions.</li>
      </ul>

      <h2 style={{ paddingLeft: '20px' , color: '#090460' }}>System Requirements</h2>
      <ul>
        <li><strong>Operating System:</strong> macOS Mojave is compatible with the following Macs:
          <ul>
            <li>MacBook (Early 2015 or later)</li>
            <li>MacBook Air (2012 or later)</li>
            <li>MacBook Pro (2012 or later)</li>
            <li>Mac Mini (2012 or later)</li>
            <li>iMac (2012 or later)</li>
            <li>iMac Pro (2017 or later)</li>
            <li>Mac Pro (2013 or later)</li>
          </ul>
        </li>
        <li><strong>Processor:</strong> Intel-based Macs are required.</li>
        <li><strong>RAM:</strong> Minimum of 2 GB (4 GB recommended).</li>
        <li><strong>Free Disk Space:</strong> At least 12.5 GB of available storage, or up to 18.5 GB when upgrading from an older macOS version.</li>
        <li><strong>Display:</strong> 1280 x 800 resolution or higher.</li>
      </ul>

      <img 
        src='/images/operatingsystem/majowaxpic.jpg' 
        alt="Windows 11 Logo" 
        style={{
          width: '600px',
          height: '400px',
          display: 'block', // Ensures block-level behavior for centering
          marginLeft: 'auto', 
          marginRight: 'auto' ,
        }} 
      />



      <h2 style={{ paddingLeft: '20px' , color: '#090460' }}>Conclusion</h2>
      <p style={{ paddingLeft: '5px' }}>
      macOS Mojave is designed to increase productivity and improve the user experience through many new features and improvements. Whether you're a consumer or a professional, Mojave has tools and enhancements to help your workflow. A variety Making it a valuable upgrade for Mac users.
      </p>


      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>

      <Button
      variant="contained"
      color="primary"
      size="large"
      startIcon={<DownloadIcon />}
      onClick={handleDownload}
      style={{
        backgroundColor: '#0078D4',
        color: '#fff',
        padding: '10px 20px',
        fontSize: '16px',
        borderRadius: '8px',
        textTransform: 'none',
        marginBottom: '20px',
      }}
    >
    Free Download Mac OS Mojave VMWare Image
    </Button>

</div>


<p  style={{ paddingLeft: '5px' }}>
              If the application is not installed on your Windows system, click the button below to proceed with the installation.
            </p>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                startIcon={<DownloadIcon />}
               
                style={{
                  backgroundColor: 'green',
                  color: '#fff',
                  padding: '10px 20px',
                  marginLeft: '10px',
                  fontSize: '16px',
                  borderRadius: '8px',
                  textTransform: 'none',
                  marginBottom: '20px',
                }}
              >
                How to download and install 
              </Button>

              </div>





      </div>
      </div>
      <div className="empty-area"></div>
    </div>
  <Footer />
  </div>
  );
}

export default Mojave ;
