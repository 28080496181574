import React from 'react';
import { Button } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import TopNavbar from '../HeaderAndFooter/TopHeader';
import LogoComponent from '../HeaderAndFooter/PageLogo';
import SocialMediaSidebar from '../MenuBar/SocialMediaSidebar';
import MenuBar from '../MenuBar/MenuBar';
import Footer from '../HeaderAndFooter/Footer';
import ReactPlayer from "react-player";


const VLCMedia= () => {



    const handleDownload = () => {
      // Your download logic here
      window.location.href = 'SoftwareHere/homesoft/_Easypcsoft_vlc-3.0.21-win32.zip';

      // window.location.href = 'https://www.videolan.org/vlc/index.html';
    };

    // const vlcplayervideos= () => {
    //   // Your download logic here
    //   window.location.href = 'https://youtu.be/QTZzttUFfno';

    //   // window.location.href = 'https://www.videolan.org/vlc/index.html';
    // };




    


  return (
    <div class="background-image">
   <TopNavbar/>
   <LogoComponent />
   <SocialMediaSidebar />


    <div  className="main-layout" >
    <MenuBar />
    </div>

    <div className="container" style={{ marginTop: '50px' }}>
      <div className="text-area">
    <div className='text-mainArea'> 

    <h2 style={{ textAlign: 'center', color: '#e37106', marginTop: '10px' }}>Overview of VLC Media Player for Windows</h2>
      <p style={{ paddingLeft: '5px' }}>
      VLC Media Player is free and open-source, with all sorts of audio and video formats supported; no additional codecs are needed. The VideoLAN project created VLC, which has earned a reputation as one of the most versatile multimedia players available, rich in features, easy to use, and very popular worldwide.
      </p>
      
      <h2 style={{ paddingLeft: '20px' , color: '#e37106' }}>Key Features</h2>
      <ul>
        <li><strong>Wide Format Support:</strong>  It plays almost all audio and video formats, such as MP4, AVI, MKV, MP3, and more.</li>
        <li><strong>Customizable Interface:</strong> It has a simple interface that can be customized with skins and themes according to the user's preferences.</li>
        <li><strong>Streaming Support:</strong> Users can stream media over the internet and local networks, and it supports various protocols.</li>
        <li><strong>Playback Controls:</strong> Features advanced playback controls, including speed adjustment, frame-by-frame playback, and a variety of playback modes.</li>
        <li><strong>Media Library:</strong> It organizes and categorizes all your media files for easy access and management.</li>
        <li><strong>Video Filters and Effects:</strong> It offers several video and audio filters and effects to enhance playback quality.</li>
        <li><strong>Subtitle Support:</strong> Supports multiple subtitle formats and allows users to customize font styles, colors, and positioning.</li>
        <li><strong>Cross-Platform Compatibility:</strong> vailable for Windows, macOS, Linux, Android, and iOS, ensuring a consistent experience across devices.</li>
        <li><strong>Recording and Conversion:</strong> Allows users to record their screens and convert media files to different formats.</li>
        <li><strong>Free and Open Source:</strong> Free to use, without ads, making it accessible for all.</li>
      </ul>

      <h2 style={{ paddingLeft: '20px' , color: '#e37106' }}>System Requirements</h2>
      <ul>
        <li><strong>Operating System:</strong> Windows 10, Windows 8/8.1, Windows 7, or later</li>
        <li><strong>Processor:</strong> Intel Pentium 4 or later</li>
        <li><strong>RAM:</strong> Minimum of 1 GB (2 GB recommended)</li>
        <li><strong>Free Disk Space:</strong> At least 100 MB for installation</li>
        <li><strong>Display:</strong> 800 x 600 resolution or higher</li>
        <li><strong>Internet Connection:</strong> Required for streaming content and updates</li>
      </ul>


      <img 
        src='/images/MostP/vlcplayer.png' 
        alt="window11 logo" 
        style={{
          width: '600px',
          height: '400px',
          display: 'block', // Ensures block-level behavior for centering
          marginLeft: 'auto', 
          marginRight: 'auto' 
        }}
        
      />



      <h2 style={{ paddingLeft: '20px' , paddingTop: '10px' , color: '#e37106' }}>Conclusion</h2>
      <p style={{ paddingLeft: '5px' }}>
      VLC Media Player is a powerful and flexible media player that meets a variety of multimedia needs. Extensive format support Customizable features And its intuitive interface makes it a great choice for both casual users and media professionals. Whether you want to play, stream, or convert media files, VLC has the tools to suit your needs.
      </p>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
      <Button
      variant="contained"
      color="primary"
      size="large"
      startIcon={<DownloadIcon />}
      onClick={handleDownload}
      style={{
        backgroundColor: '#0078D4',
        color: '#fff',
        padding: '10px 20px',
        fontSize: '16px',
        borderRadius: '8px',
        textTransform: 'none',
        marginBottom: '10px',
      }}
    >
    VLC Media Player for Windows
    </Button>

</div>

<p  style={{ paddingLeft: '5px' }}>
              If the application is not installed on your Windows system, click the button below to proceed with the installation.
            </p>
            {/* <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                startIcon={<DownloadIcon />}
               
                onClick={vlcplayervideos}
                
                style={{
                  backgroundColor: 'green',
                  color: '#fff',
                  padding: '10px 20px',
                  marginLeft: '10px',
                  fontSize: '16px',
                  borderRadius: '8px',
                  textTransform: 'none',
                  marginBottom: '20px',
                }}
              >
                How to download and install 
              </Button>
              </div> */}


              <div >
                    <h2 style={{ paddingTop: '20px' , paddingLeft: '20px' ,  color: '#4f4bff' }}>  How to download and install Check the below video </h2>
                    <ReactPlayer
                    style={{ paddingTop: '20px'  }}
                      url="https://youtu.be/QTZzttUFfno"
                      controls={true} // Show controls for the player
                      width="100%"    // Set the width of the player
                      height="500px"  // Set the height of the player
                    />
                  </div>


      </div>
      </div>
      <div className="empty-area"></div>

  {/* google add sence adds here */}

    </div>
  <Footer />
  </div>
  );
}

export default VLCMedia;
