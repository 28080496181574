import React, { useState } from "react";
import '../OperatingSystem/css/operatingsysyemCat.css';
import { useNavigate } from "react-router-dom";


const categories = [


  {
    id:1,
    image: 'images/AITools/chatgptlogo.jpg',
    title: 'ChatGPT ',
    time: ' chatgpt.If you went to use the chatgpt tool, click here.',
    details: 'Powered by learning, ChatGPT is an advanced AI language model developed by OpenAI designed to help users generate human-like text based on suggestions. They can talk, answer questions, write content, give lectures, and perform a variety of other tasks. Powered by learning. in-depth It understands the context and creates a consistent, context-appropriate response. It provides a multi-purpose tool for both general and professional use.',
  },
  {
    id: 2,
    image: 'images/AITools/MetaAILogo.jpg',
    title: 'Meta AI',
    time: '.If you went to use the Meta AI, click here.',
    details: 'Meta AI is a division of Meta (formerly Facebook) focused on developing advanced artificial intelligence technologies. Its goal is to develop cutting-edge AI models and systems that improve human-computer interaction. Improved automation and driving innovation in areas such as natural language processing computer vision and machine learning Ethical and AI solutions are dedicated to creating.'
  },
  {
    id: 3,
    image: 'images/AITools/Perplexityalilogo.jpg',
    title: 'Perplexity',
    time: 'If you went to use the Perplexity AI , click here.',
    details: 'Perplexity  is a measure used to evaluate the performance of language models in natural language processing (NLP). It measures how well the model predicts text samples. with less Perplexity  indicating better prediction accuracy. Basically Confusion on the other hand reflects the degree of uncertainty or The models surprisex when predicting the next word It is often used to evaluate a models performance in tasks such as language modeling. Creating a message And machine translation.'
  }
  ,
  {
    id: 4,
    image: 'images/AITools/copilot-logo.jpg',
    title: 'Copilot ',
    time: 'If you went to use the Microsoft Copilot AI , click here.',
    details: 'Copilot is an AI-powered code completion tool developed by GitHub in collaboration with OpenAI. It helps developers write by suggesting code snippets, functions, and solutions based on context and natural language. Copilot coding improves productivity. Work by accelerating work, reducing errors, and providing constructive suggestions. It designed to integrate seamlessly with the popular code editor Visual Studio Code. It provides real-time support for a wide range of programming languages ​​and frameworks'
  }
  

  ,
  {
    id: 5,
    image: 'images/AITools/GemaniAi-logo.jpg',
    title: 'Gemini AI ',
    time: 'If you went to use the Gemini AI , click here.',
    details: 'Gemini is an advanced AI model suite developed by Google DeepMind designed to expand the scope of artificial intelligence research and applications. By combining state-of-the-art machine learning techniques to improve natural language processing, understanding, and generation, Gemini aims to improve in many areas. including search Creative work and solving complex problems by providing accurate, efficient AI capabilities. and take into account the context more This represents a significant milestone in Google AI development, integrating innovations in general AI models and specialized applications.'
  },



  {
    id: 6,
    image: 'images/AITools/Midjourney -logo.jpg',
    title: 'Midjourney ',
    time: 'If you went to use the Gemini AI , click here.',
    details: 'MidJourney is an advanced AI tool designed to create stunning images and artwork based on text prompts. Using cutting-edge machine learning techniques, users can easily create detailed and imaginative scenes. Explaining their vision, MidJourney is widely praised for their ability to produce high quality, unique art with creative freedom. This makes it a valuable resource for artists, designers, and creators.'
  },
  {
    id: 7,
    image: 'images/AITools/Adobe Firefly-logo.jpg',
    title: 'Adobe Firefly ',
    time: 'If you went to use the Gemini AI , click here.',
    details: 'Adobe Firefly is a suite of generative AI tools developed by Adobe. This software is designed to allow creative professionals who need to create images, graphics, and designs more efficiently. Firefly utilizes AI to generate high-quality visual content from textual prompts or other user input based on Adobe deep expertise in creative software. The tool concentrates on making image creation, logo design, illustrations, and even text effects possible with a minimal loss of control over creativity for the user. Firefly combines well with other Adobe products, such as Photoshop and Illustrator, to help the user improve workflow and explore more innovative designs. Its features emphasize ethical use of AI, including Adobe aim to use only safe, copyright-compliant datasets when training the model.'
  },
  {
    id: 8,
    image: 'images/AITools/Grammarly-logo.jpg',
    title: 'Grammarly',
    time: 'If you went to use the Gemini AI , click here.',
    details: 'Grammarly is an AI writing assistant which uses the computerized intelligence technology to assist a user in correcting the writing for grammatical errors, spelling errors, punctuation, and even styles of tone. The formats it can correct range from emails, essays, reports, to social media posts. It can review the text based on its clarity, conciseness, and readability while making suggestions about how to strengthen sentences and words used. Grammarly has also incorporated plagiarism checker and vocabulary enhancing features. Available as both a browser extension and a separate desktop application or integrated into third-party applications such as Microsoft Word, Grammarly ensures that writing always looks polished, professional, and error-free. It helps students, office professionals, as well as virtually anyone else whose writing skills need improvements.'
  }
  ,
  {
    id: 9,
    image: 'images/AITools/ChatSonic-logo.jpg',
    title: 'ChatSonic ',
    time: 'If you went to use the Gemini AI , click here.',
    details: 'ChatSonic is an AI-powered conversational tool designed by Writesonic to aid users with content generation and interactive communication. It uses large-scale natural language processing models to generate responses like those written by humans, helping with the work of writing articles, answering questions, brainstorming, and generating marketing content. ChatSonic is a leading AI chatbox that generates a more context-related and engaging input compared to an ordinary chat. It can do such things as offering voice-to-text capabilities, a personalized response mechanism, and updates with current information and data that make it adaptable for writers and marketers to increase customer support communication and content material.'
  }
  

  ,
  {
    id: 10,
    image: 'images/AITools/Jasper-logo.jpg',
    title: 'Jasper AI, Inc. ',
    time: 'If you went to use the Gemini AI , click here.',
    details: 'Jasper AI, Inc., also known as Jarvis, is a content generation AI platform for business, marketing, and content writing. Jasper has the capability to generate top-notch written content rapidly and efficiently through advanced natural language processing models, including generating social media posts, blog posts, advertisements, and emails. It is designed to save time on repetitive writing tasks, improve productivity, and assist in crafting persuasive, SEO-optimized copy. Jasper AI offers a range of templates and customizable workflows to suit various writing needs, making it a popular tool for digital marketers, entrepreneurs, and agencies. It also integrates with other tools to streamline content creation processes, helping users stay focused on strategy and creativity.'
  }
];



// Component to display individual category information
const CategoryCard = ({ category, onClick }) => {


  return (
    <div className="category-card" onClick={onClick}>
      <img src={category.image} alt={category.title} className="category-image" />
      <div className="category-info">
        <h3>{category.title}</h3>
        <p className="category-time">Return to  {category.time}</p>
        <p>{category.details}</p>
      </div>
    </div>
  );
};





// Main Component to display the list of categories
const ResponsiveList = () => {
  const navigate = useNavigate();  // React Router's navigation hook

  const handleCardClick = (categoryId) => {
    if (categoryId === 1) {
      window.location.href = "https://chatgpt.com/";
      // Navigate to the Windows 7 page
    } else if (categoryId === 2) {

      window.location.href = "https://www.meta.ai/";
    }
    else if (categoryId === 3) {
      window.location.href = "https://www.perplexity.ai/";
    }
    else if (categoryId === 4) {
      window.location.href = "https://copilot.microsoft.com/chats/yt3hDjqseb12fjKndZiyt";
    }
    else if (categoryId === 5) {
      window.location.href = "https://gemini.google.com/";
    }
    else if (categoryId === 6) {
      window.location.href = "https://www.imagine.art/?utm_source=google&utm_medium=cpc&utm_campaign=G_I_Web_T2_WW_New&utm_term=midjourney%20ai&utm_campaign=&utm_source=adwords&utm_medium=ppc&hsa_acc=3029240990&hsa_cam=22095383460&hsa_grp=174807442964&hsa_ad=728312931535&hsa_src=g&hsa_tgt=kwd-1675638903539&hsa_kw=midjourney%20ai&hsa_mt=b&hsa_net=adwords&hsa_ver=3&gad_source=1&gclid=EAIaIQobChMItoCAgJv8igMVyJpoCR06_jWaEAAYASAAEgKDP_D_BwE";
    }
    else if (categoryId === 7) {
      window.location.href = "https://www.meta.ai/";
    }
    
    else if (categoryId === 8) {
      window.location.href = "https://www.meta.ai/";
    }
    
    
    else if (categoryId === 9) {
      window.location.href = "https://www.meta.ai/";
    }
    
    else if (categoryId === 10) {
      window.location.href = "https://www.meta.ai/";
    }
    
    
  };



  return (
    <div className="Main-category">
    <div className="category-list">
      {categories.map((category) => (
        <CategoryCard key={category.id} category={category} onClick={() => handleCardClick(category.id)} />
      ))}


    </div>
   
    </div>
  );
};

export default ResponsiveList;



