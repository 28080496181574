import React from 'react';
import TopNavbar from '../../HeaderAndFooter/TopHeader';
import LogoComponent from '../../HeaderAndFooter/PageLogo';
import SocialMediaSidebar from '../../MenuBar/SocialMediaSidebar';
import MenuBar from '../../MenuBar/MenuBar';
import Footer from '../../HeaderAndFooter/Footer';
import './css/windows10sp1.css';
import { Button } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';



const ManjaroLinux= () => {



    const handleDownload = () => {
      // Your download logic here
      window.location.href = 'https://manjaro.org/download/';


      // Example link
    };



  return (
    <div class="background-image">
   <TopNavbar />
   <LogoComponent />
   <SocialMediaSidebar />


    <div  className="main-layout" >
    <MenuBar />
    </div>

    <div className="container" style={{ marginTop: '50px' }}>
      <div className="text-area">
    <div className='text-mainArea'> 

    <h2 style={{ paddingLeft: '20px' , color: '#2bc805', marginTop: '10px'  }}>Overview of Manjaro Linux Features & Requirements</h2>
      <p style={{ paddingLeft: '5px' }}>
      Manjaro Linux is an easy-to-use and versatile Arch Linux operating system. It aims to provide access to the power and flexibility of Arch Linux to a broad audience through a more accessible installation process and pre-configured environment.
      </p>
      
      <h2  style={{ paddingLeft: '20px' , color: '#2bc805' }}>Key Features:</h2>
      <ul>
        <li><strong style={{ color: '#2bc805' }}>User-Friendly Experience:</strong> Manjaro provides an accessible installation process with a graphical installer, making it easier for users to set up and manage their system compared to Arch Linux's more manual installation approach.</li>
        <li><strong style={{ color: '#2bc805' }}>Rolling Release Model:</strong> Like Arch Linux, Manjaro follows a rolling release model, which means users receive continuous updates without needing to upgrade to a new version periodically. This ensures that the system always has the latest features and security updates.</li>
        <li><strong style={{ color: '#2bc805' }}>Pre-Configured Environments:</strong> Manjaro offers several pre-configured desktop environments, including Xfce, KDE Plasma, and GNOME. These environments come with a curated set of applications and settings, providing a ready-to-use experience out of the box.</li>
        <li><strong style={{ color: '#2bc805' }}>Hardware Detection and Drivers:</strong> Manjaro includes an advanced hardware detection tool and a wide range of drivers, ensuring good compatibility with a variety of hardware. This feature simplifies the process of configuring and optimizing system components.</li>
        <li><strong style={{ color: '#2bc805' }}>AUR Support:</strong> Manjaro provides access to the Arch User Repository (AUR), allowing users to install a vast array of software packages that are not available in the official repositories. This expands the software options significantly.</li>
        <li><strong style={{ color: '#2bc805' }}>Manjaro Settings Manager:</strong> A graphical tool for managing system settings, including kernel versions, user accounts, and system updates, streamlining maintenance tasks and system configuration.</li>
        <li><strong style={{ color: '#2bc805' }}>Community and Documentation:</strong> Manjaro has an active community and extensive documentation, offering support and resources to help users troubleshoot issues and make the most of their system.</li>
      </ul>
      
      <h2 style={{ paddingLeft: '20px' , color: '#2bc805' }}>System Requirements:</h2>
      <ul>
        <li><strong style={{ color: '#2bc805' }}>Processor:</strong> 1 GHz or faster.</li>
        <li><strong style={{ color: '#2bc805' }}>RAM:</strong> 2 GB or more (4 GB recommended for a better experience).</li>
        <li><strong style={{ color: '#2bc805' }}>Storage:</strong> 30 GB of available disk space (more recommended for additional applications and data).</li>
        <li><strong style={{ color: '#2bc805' }}>Graphics Card:</strong> A graphics card capable of 1024x768 resolution or higher.</li>
        <li><strong style={{ color: '#2bc805' }}>Display:</strong> Minimum 1024x768 resolution.</li>
      </ul>
      
      <h2 style={{ paddingLeft: '20px' , color: '#2bc805' }}>Performance and Reception:</h2>
      <p style={{ paddingLeft: '5px' }}>
      Manjaro Linux is praised for its balance between ease of use and powerful features. Provides a smooth and responsive user experience. It combines the strengths of Arch Linux with a more user-friendly approach. The rolling release model gives users access to the latest software without the need for major upgrades.
      </p>
      
      <img 
        src='/images/operatingsystem/manjoropicmain.jpg' 
        alt="Windows 11 Logo" 
        style={{
          width: '600px',
          height: '400px',
          display: 'block', // Ensures block-level behavior for centering
          marginLeft: 'auto', 
          marginRight: 'auto' ,
        }} 
      />



      <h2 style={{  paddingTop: '20px' , paddingLeft: '20px' , color: '#2bc805' }}>Conclusion:</h2>
      <p  style={{ paddingLeft: '5px' }}>
      Manjaro Linux offers an attractive alternative for users who want the power of Arch Linux with increased convenience and accessibility. User friendly installation Pre-configured environment and an active community This makes it a great choice for both new and experienced Linux users looking for a modern and flexible operating system.
      </p>


      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>


      <Button
      variant="contained"
      color="primary"
      size="large"
      startIcon={<DownloadIcon />}
      onClick={handleDownload}
      style={{
        backgroundColor: '#0078D4',
        color: '#fff',
        padding: '10px 20px',
        fontSize: '16px',
        borderRadius: '8px',
        textTransform: 'none',
        marginBottom: '20px',
      }}
    >
      Download ManjaroLinex
    </Button>

</div>





<p  style={{ paddingLeft: '5px' }}>
              If the application is not installed on your Windows system, click the button below to proceed with the installation.
            </p>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                startIcon={<DownloadIcon />}
               
                style={{
                  backgroundColor: 'green',
                  color: '#fff',
                  padding: '10px 20px',
                  marginLeft: '10px',
                  fontSize: '16px',
                  borderRadius: '8px',
                  textTransform: 'none',
                  marginBottom: '20px',
                }}
              >
                How to download and install 
              </Button>

              </div>





      </div>
      </div>
      <div className="empty-area"></div>
    </div>
  <Footer />
  </div>
  );
}

export default ManjaroLinux ;
