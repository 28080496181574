import React from 'react';

import { Button } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import TopNavbar from '../../HeaderAndFooter/TopHeader';
import LogoComponent from '../../HeaderAndFooter/PageLogo';
import SocialMediaSidebar from '../../MenuBar/SocialMediaSidebar';
import MenuBar from '../../MenuBar/MenuBar';
import Footer from '../../HeaderAndFooter/Footer';



const JetBrainsGoLand= () => {



    const handleDownload = () => {
      // Your download logic here
      window.location.href = 'https://getintopc.com/softwares/programming/jetbrains-goland-2024-free-download/';


      // Example link
    };


    const handleDownloadbuy = () => {
        // Your download logic here
        window.location.href = 'https://www.jetbrains.com/goland/';
  
  
        // Example link
      };



  return (
    <div class="background-image">
   <TopNavbar />
   <LogoComponent/>
   <SocialMediaSidebar />


    <div  className="main-layout" >
    <MenuBar />
    </div>

    <div className="container" style={{ marginTop: '50px' }}>
      <div className="text-area">
    <div className='text-mainArea'> 

    <h2  style={{ paddingLeft: '30px' , color: '#7806f9', marginTop: '20px'  }} >Overview of JetBrains GoLand & free download</h2>
      <p style={{ paddingLeft: '5px' }}>
        JetBrains GoLand is a powerful integrated development environment (IDE) specifically designed for Go (Golang) development. 
        It combines intelligent code assistance with advanced tools for building, testing, and debugging Go applications, making it suitable for both beginners and experienced developers. 
        With its comprehensive set of features, GoLand helps streamline the development process and enhances productivity.
      </p>

      <h2 style={{ paddingLeft: '20px' , color: '#7806f9' }}>Key Features</h2>
      <ul>
        <li>
          <strong style={{  color: '#7806f9' }}  >Smart Code Completion:</strong>
          <ul>
            <li>Context-aware code suggestions for Go syntax and standard library functions.</li>
            <li>Completion for variables, types, methods, and more.</li>
          </ul>
        </li>
        <li>
          <strong style={{  color: '#7806f9' }}>Integrated Debugger:</strong>
          <ul>
            <li>Powerful debugging capabilities with breakpoints, watches, and call stack inspection.</li>
            <li>Supports local and remote debugging of applications.</li>
          </ul>
        </li>
        <li>
          <strong style={{  color: '#7806f9' }}>Testing Support:</strong>
          <ul>
            <li>Built-in support for Go's testing framework, enabling easy test creation and execution.</li>
            <li>Code coverage analysis and test result visualization.</li>
          </ul>
        </li>
        <li>
          <strong style={{  color: '#7806f9' }}>Version Control Integration:</strong>
          <ul>
            <li>Seamless integration with Git, Mercurial, Subversion, and other version control systems.</li>
            <li>Visual representation of commit history, branches, and diffs.</li>
          </ul>
        </li>
        <li>
          <strong style={{  color: '#7806f9' }}>Refactoring Tools:</strong>
          <ul>
            <li>Comprehensive refactoring capabilities, including renaming, extracting methods, and more.</li>
            <li>Safe refactorings that ensure code integrity.</li>
          </ul>
        </li>
        <li>
          <strong style={{  color: '#7806f9' }}>Code Quality Analysis:</strong>
          <ul>
            <li>On-the-fly code inspections to detect potential issues and suggest improvements.</li>
            <li>Integration with linters like golint and go vet.</li>
          </ul>
        </li>
        <li>
          <strong style={{  color: '#7806f9' }}>Go Modules Support:</strong>
          <ul>
            <li>Integrated support for managing Go modules and dependencies.</li>
            <li>Tools for handling module-related tasks directly within the IDE.</li>
          </ul>
        </li>
        <li>
          <strong style={{  color: '#7806f9' }}>Database Tools:</strong>
          <ul>
            <li>Built-in tools for connecting to databases, executing queries, and managing schemas.</li>
            <li>Support for various databases, including MySQL, PostgreSQL, and MongoDB.</li>
          </ul>
        </li>
        <li>
          <strong style={{  color: '#7806f9' }}>Customizable UI:</strong>
          <ul>
            <li>Highly customizable interface with themes, layouts, and keymaps.</li>
            <li>Support for plugins to extend functionality and integrate additional tools.</li>
          </ul>
        </li>
        <li>
          <strong style={{  color: '#7806f9' }}>Terminal Integration:</strong>
          <ul>
            <li>Built-in terminal for executing shell commands without leaving the IDE.</li>
            <li>Supports multiple terminal sessions and configurations.</li>
          </ul>
        </li>
      </ul>

      <h2 style={{ paddingLeft: '20px' , color: '#7806f9' }}>System Requirements</h2>
      <ul>
        <li>
          <strong style={{  color: '#7806f9' }}>Operating System:</strong>
          <ul>
            <li>Windows 10 or later (64-bit)</li>
            <li>macOS 10.13 or later</li>
            <li>Linux (Ubuntu, Fedora, and other distributions supported)</li>
          </ul>
        </li>
        <li>
          <strong style={{  color: '#7806f9' }}>Hardware:</strong>
          <ul>
            <li>Minimum 4 GB RAM (8 GB or more recommended)</li>
            <li>Minimum 2.5 GB of disk space for installation (more required for projects and caches)</li>
            <li>Recommended: SSD for optimal performance</li>
          </ul>
        </li>
        <li>
          <strong style={{  color: '#7806f9' }}>Software:</strong>
          <ul>
            <li>Go (latest version recommended)</li>
            <li>Docker support for containerized development (if applicable)</li>
          </ul>
        </li>
      </ul>


      <img 
        src='/images/Development/JetBrains GoLandpic.png' 
        alt="Windows 11 Logo" 
        style={{
          width: '600px',
          height: '400px',
          display: 'block', // Ensures block-level behavior for centering
          marginLeft: 'auto', 
          marginRight: 'auto' ,
        }} 
      />


      <h2 style={{ paddingLeft: '20px' , color: '#7806f9' }}>Conclusion</h2>
      <p style={{ paddingLeft: '5px' }}>
        JetBrains GoLand is an excellent choice for Go developers seeking a feature-rich IDE. 
        Its intelligent code assistance, integrated debugging, and testing support make it ideal for developing robust Go applications efficiently. 
        Whether you're building web servers, command-line tools, or cloud applications, GoLand provides the tools necessary to enhance your development workflow.
      </p>


      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}> 
      <Button
      variant="contained"
      color="primary"
      size="large"
      startIcon={<DownloadIcon />}
      onClick={handleDownload}
      style={{
        backgroundColor: '#08c725',
        color: '#fff',
        padding: '10px 20px',
        fontSize: '16px',
        borderRadius: '8px',
        textTransform: 'none',
        marginBottom: '20px',
      }}
    >
  Free DownLoad JetBrains GoLand 2024
    </Button>

    </div>

    <p  style={{ paddingLeft: '5px' }}>
              If the application is not installed on your Windows system, click the button below to proceed with the installation.
            </p>

 <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}> 
    <Button
      variant="contained"
      color="primary"
      size="large"
      startIcon={<DownloadIcon />}
      onClick={handleDownloadbuy }
      style={{
        backgroundColor: '#0078D4',
        color: '#fff',
        padding: '10px 20px',
        marginLeft: '5px',
        fontSize: '16px',
        borderRadius: '8px',
        textTransform: 'none',
        marginBottom: '20px',
      }}
    >
  Offical Buy JetBrains GoLand 2024
    </Button>

</div>


      </div>
      </div>
      <div className="empty-area"></div>
    </div>
  <Footer />
  </div>
  );
}

export default JetBrainsGoLand;
