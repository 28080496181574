import React from 'react';

import { Button } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import TopNavbar from '../../HeaderAndFooter/TopHeader';
import LogoComponent from '../../HeaderAndFooter/PageLogo';
import SocialMediaSidebar from '../../MenuBar/SocialMediaSidebar';
import MenuBar from '../../MenuBar/MenuBar';
import Footer from '../../HeaderAndFooter/Footer';



const GooglePlayGames= () => {



    const handleDownload = () => {
      // Your download logic here
      window.location.href = 'https://filehippo.com/download_google-play-games/';


      // Example link
    };


    const handleDownloadbuy = () => {
        // Your download logic here
        window.location.href = 'https://www.dragonrise.com/yolomouse/';
  
  
        // Example link
      };



  return (
    <div class="background-image">
   <TopNavbar />
   <LogoComponent/>
   <SocialMediaSidebar />


    <div  className="main-layout" >
    <MenuBar />
    </div>

    <div className="container" style={{ marginTop: '50px' }}>
      <div className="text-area">
    <div className='text-mainArea'> 

    <h2 style={{ paddingLeft: '30px' , color: '#0434f7', marginTop: '20px'  }}>Overview of Google Play Games for Windows</h2>
      <p style={{ paddingLeft: '5px' }}>
        Google Play Games for Windows is an application that allows users to play Android games on their Windows PCs. This platform bridges the gap between mobile and desktop gaming, enabling users to enjoy their favorite titles with improved performance and controls. The service offers a variety of popular games, optimized for a larger screen experience.
      </p>
      
      <h2 style={{ paddingLeft: '20px' , color: '#0fbf5f' }}>Key Features</h2>
      <ul>
        <li><strong style={{  color: '#f78b02' }} >Cross-Platform Play:</strong> Sync your game progress across mobile and PC devices, ensuring a seamless transition between platforms.</li>
        <li><strong style={{  color: '#f78b02' }}>Extensive Game Library:</strong> Access a wide selection of popular Android games, all optimized for Windows performance.</li>
        <li><strong style={{  color: '#f78b02' }}>Cloud Save Functionality:</strong> Your game progress is automatically saved in the cloud, allowing you to pick up right where you left off.</li>
        <li><strong style={{  color: '#f78b02' }}>Enhanced Controls:</strong> Use keyboard and mouse for improved precision and control compared to touchscreens.</li>
        <li><strong style={{  color: '#f78b02' }}>Social Connectivity:</strong> Connect with friends, participate in multiplayer games, and track achievements through Google Play Games.</li>
        <li><strong style={{  color: '#f78b02' }}>User-Friendly Interface:</strong> An intuitive layout makes navigating your game library and accessing features simple.</li>
        <li><strong style={{  color: '#f78b02' }}>Performance Optimization:</strong> Games are tailored for Windows hardware, ensuring smoother graphics and faster load times.</li>
      </ul>
      
      <h2 style={{ paddingLeft: '20px' , color: '#eaf905' }}>System Requirements</h2>
      <p style={{ paddingLeft: '5px' }}>
        While requirements may vary by game, general system specifications for Google Play Games on Windows are as follows:
      </p>
      <ul>
        <li><strong style={{  color: '#f78b02' }}>Operating System:</strong> Windows 10 or later (64-bit)</li>
        <li><strong style={{  color: '#f78b02' }}>Processor:</strong> Intel Core i3 or equivalent</li>
        <li><strong style={{  color: '#f78b02' }}>RAM:</strong> At least 8 GB</li>
        <li><strong style={{  color: '#f78b02' }}>Storage:</strong> Minimum of 10 GB of free disk space</li>
        <li><strong style={{  color: '#f78b02' }}>Graphics:</strong> DirectX 11 compatible GPU</li>
        <li><strong style={{  color: '#f78b02' }}>Internet Connection:</strong> A broadband internet connection for downloads and online features</li>
      </ul>



      <img 
        src='/images/Games/Google Play Gamespic1.jpg' 
        alt="Windows 11 Logo" 
        style={{
          width: '400px',
          height: '200px',
          display: 'block', // Ensures block-level behavior for centering
          marginLeft: 'auto', 
          marginRight: 'auto' ,
        }} 
      />




      <h2 style={{ paddingLeft: '20px' , color: '#2a9e09' }}> Conclusion</h2>
      <p style={{ paddingLeft: '5px' }}>
        Google Play Games for Windows presents a robust platform for gamers who wish to enjoy Android titles on their PCs. With its cross-platform capabilities, extensive game library, and user-friendly features, it enhances the gaming experience by providing improved controls and performance. As it continues to evolve, this platform holds promise for both casual and dedicated gamers, offering a convenient way to enjoy mobile games on a larger scale.
      </p>


      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}> 

      <Button
      variant="contained"
      color="primary"
      size="large"
      startIcon={<DownloadIcon />}
      onClick={handleDownload}
      style={{
        backgroundColor: '#08c725',
        color: '#fff',
        padding: '10px 20px',
        fontSize: '16px',
        borderRadius: '8px',
        textTransform: 'none',
        marginBottom: '20px',
      }}
    >
  Free DownLoad Google Play Games for Windows
    </Button>

 </div>
 <p  style={{ paddingLeft: '5px' }}>
              If the application is not installed on your Windows system, click the button below to proceed with the installation.
            </p>


<div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}> 

    <Button
      variant="contained"
      color="primary"
      size="large"
      startIcon={<DownloadIcon />}
      onClick={handleDownloadbuy }
      style={{
        backgroundColor: '#0078D4',
        color: '#fff',
        padding: '10px 20px',
        marginLeft: '5px',
        fontSize: '16px',
        borderRadius: '8px',
        textTransform: 'none',
        marginBottom: '20px',
      }}
    >
  Offical Buy Games Teaser AEP
    </Button>

</div>




      </div>
      </div>
      <div className="empty-area"></div>
    </div>
  <Footer />
  </div>
  );
}

export default GooglePlayGames;
