



// ItemPage.js
import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

const ItemPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  // Dynamic navigation based on the id
  useEffect(() => {
    switch (id) {
      case '1':
        navigate('/operatingSystem');
        break;
      case '2':
        navigate('/GraphicDesign');
        break;
      case '3':
        navigate('/development');
        break;
      case '4':
        navigate('/education');
        break;
      case '5':
        navigate('/Mostpopular');
        break;
      case '6':
        navigate('/games');
        break;
      case '7':
        navigate('/multimedia');
        break;
      default:
        navigate('/');
        break;
    }
  }, [id, navigate]);

  return (
    <div className="text-center mt-5">
      <h1>Loading...</h1>
    </div>
  );
};

export default ItemPage;
