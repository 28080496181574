import React from 'react';
import TopNavbar from '../../HeaderAndFooter/TopHeader';
import LogoComponent from '../../HeaderAndFooter/PageLogo';
import SocialMediaSidebar from '../../MenuBar/SocialMediaSidebar';
import MenuBar from '../../MenuBar/MenuBar';
import Footer from '../../HeaderAndFooter/Footer';
import './css/windows10sp1.css';
import { Button } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';



const Windows7SP1= () => {



    const handleDownload = () => {
      // Your download logic here
      window.location.href = 'https://www.microsoft.com/en-us/software-download/windows7';

      // Example link
    };





  return (
    <div class="background-image">
   <TopNavbar />
   <LogoComponent />
   <SocialMediaSidebar />


    <div  className="main-layout" >
    <MenuBar />
    </div>

    <div className="container" style={{ marginTop: '50px' }}>
      <div className="text-area">
    <div className='text-mainArea'> 

    <h2 style={{ paddingTop: '20px' ,paddingLeft: '20px' , color: '#139fbe' }}> Windows 7 SP1 Features and Requirements</h2>
      <p style={{ paddingLeft: '5px' }}>
        Windows 7 Service Pack 1 (SP1), released in February 2011, was a significant update to Windows 7 that addressed various issues and added important improvements to the operating system. Here’s a detailed overview of its features, requirements, and more:
      </p>
      
      <h2 style={{ paddingLeft: '20px' , color: '#139fbe' }}>Key Features:</h2>
      <ul>
        <li><strong>Security Enhancements:</strong> SP1 included critical security updates to address vulnerabilities and improve system protection against malware and cyber threats.</li>
        <li><strong>Performance Improvements:</strong> The update optimized system performance and stability, resolving many of the issues reported by users and improving the overall responsiveness of the operating system.</li>
        <li><strong>Compatibility Updates:</strong> SP1 included updates to enhance compatibility with new hardware and software, ensuring that Windows 7 continued to perform well with emerging technologies.</li>
        <li><strong>System Reliability:</strong> Improvements were made to system reliability, addressing bugs and stability issues that had been reported in the original release.</li>
        <li><strong>Updated Features:</strong> Although SP1 did not introduce major new features, it included updates to existing functionalities, such as enhanced support for device drivers and system tools.</li>
        <li><strong>Remote Desktop Improvements:</strong> Enhanced Remote Desktop features were included, providing better performance and reliability for remote access.</li>
        <li><strong>Windows 7 and Windows Server 2008 R2:</strong> SP1 was a unified update for both Windows 7 and Windows Server 2008 R2, ensuring that both platforms benefited from the same set of improvements.</li>
      </ul>
      
      <h2 style={{ paddingLeft: '20px' , color: '#139fbe' }}>System Requirements:</h2>
      <ul>
        <li><strong>Processor:</strong> 1 GHz or faster with 32-bit (x86) or 64-bit (x64) architecture.</li>
        <li><strong>RAM:</strong> 1 GB for 32-bit or 2 GB for 64-bit.</li>
        <li><strong>Storage:</strong> 16 GB of available disk space for 32-bit or 20 GB for 64-bit.</li>
        <li><strong>Graphics Card:</strong> DirectX 9 graphics device with WDDM 1.0 or higher driver.</li>
        <li><strong>Display:</strong> Minimum 800x600 resolution.</li>
        <li><strong>Internet Connection:</strong> Required for downloading updates and certain features.</li>
      </ul>
      
      <h2 style={{ paddingLeft: '20px' , color: '#139fbe' }}>Performance and Reception:</h2>
      <p style={{ paddingLeft: '5px' }}>
        Windows 7 SP1 was well-received by users and reviewers alike, as it addressed many of the initial concerns about system performance and compatibility. It was seen as a necessary update to ensure that Windows 7 continued to deliver a stable and secure experience.
      </p>
      
      <h2 style={{ paddingLeft: '20px' , color: '#139fbe' }}>Conclusion:</h2>
      <p style={{ paddingLeft: '5px' }}>
        Windows 7 SP1 was an important update that refined and improved Windows 7, enhancing security, performance, and compatibility. It provided users with a more reliable and robust operating system, continuing to support Windows 7 as a popular choice for both personal and professional computing.
      </p>

      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>

      <Button
      variant="contained"
      color="primary"
      size="large"
      startIcon={<DownloadIcon />}
      onClick={handleDownload}
      style={{
        backgroundColor: '#0078D4',
        color: '#fff',
        padding: '10px 20px',
        fontSize: '16px',
        borderRadius: '8px',
        textTransform: 'none',
        marginBottom: '20px',
      }}
    >
      Download Windows 7 SP1
    </Button>

</div>

<p  style={{ paddingLeft: '5px' }}>
              If the application is not installed on your Windows system, click the button below to proceed with the installation.
            </p>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                startIcon={<DownloadIcon />}
               
                style={{
                  backgroundColor: 'green',
                  color: '#fff',
                  padding: '10px 20px',
                  marginLeft: '10px',
                  fontSize: '16px',
                  borderRadius: '8px',
                  textTransform: 'none',
                  marginBottom: '20px',
                  marginBottom: '20px',
                }}
              >
                How to download and install 
              </Button>

              </div>







      </div>
      </div>
      <div className="empty-area"></div>
    </div>
  <Footer />
  </div>
  );
}

export default Windows7SP1 ;
