import React from 'react';
import TopNavbar from '../../HeaderAndFooter/TopHeader';
import LogoComponent from '../../HeaderAndFooter/PageLogo';
import SocialMediaSidebar from '../../MenuBar/SocialMediaSidebar';
import MenuBar from '../../MenuBar/MenuBar';
import Footer from '../../HeaderAndFooter/Footer';
import '../css/NukeStudioOverview.css';
import { Button } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';



const AppleiTunes2024= () => {



    const handleDownload = () => {
      // Your download logic here
      window.location.href = 'https://www.apple.com/itunes/'; // Example link
    };





  return (
    <div class="background-image">
   <TopNavbar />
   <LogoComponent />
   <SocialMediaSidebar />


    <div  className="main-layout" >
    <MenuBar />
    </div>

    <div className="container" style={{ marginTop: '50px' }}>
      <div className="text-area">
    <div className='text-mainArea'> 
    
      <h1 style={{ paddingLeft: '30px' , color: '#0fbf5f', marginTop: '20px'  }}>Overview of Apple iTunes 2024</h1>
      <p style={{ paddingLeft: '5px' }}>
      Apple iTunes 2024 is a media player, media library, online store. and a comprehensive cloud-based service that lets users organize and enjoy music, movies, TV shows, and podcasts, enabling seamless integration across devices with iTunes. which is the cornerstone of Apple's ecosystem, it happens.
      </p>

      <h2 style={{ paddingLeft: '20px' , color: '#f49c0b' }}>Key Features</h2>
      <ul className="features-list">
        <li>
          <strong style={{  color: '#0c6dc9' }}>Media Library Management:</strong> Organize and manage music, movies, TV shows, and podcasts in a centralized library.
        </li>
        <li>
          <strong style={{  color: '#0c6dc9' }}>Apple Music Integration:</strong> Stream millions of songs and create custom playlists seamlessly.
        </li>
        <li>
          <strong style={{  color: '#0c6dc9' }}>iTunes Store:</strong> Access a vast catalog of media for purchase and download.
        </li>
        <li>
          <strong style={{  color: '#0c6dc9' }}>iCloud Integration:</strong> Sync media across all Apple devices with iCloud.
        </li>
        <li>
          <strong style={{  color: '#0c6dc9' }}>Podcast Support:</strong> Browse, subscribe to, and listen to a wide range of podcasts.
        </li>
        <li>
          <strong style={{  color: '#0c6dc9' }}>Playlist Creation and Sharing:</strong> Create and share custom playlists with friends and family.
        </li>
        <li>
          <strong style={{  color: '#0c6dc9' }}>Music and Video Playback:</strong> Enjoy high-quality playback with customizable settings.
        </li>
        <li>
          <strong style={{  color: '#0c6dc9' }}>Home Sharing:</strong> Stream media from one Apple device to another using Home Sharing.
        </li>
        <li>
          <strong style={{  color: '#0c6dc9' }}>Import and Export Features:</strong> Import music from CDs and export playlists to share.
        </li>
        <li>
          <strong style={{  color: '#0c6dc9' }}>User-Friendly Interface:</strong> An intuitive interface for easy navigation and access to content.
        </li>
      </ul>

      <h2 style={{ paddingLeft: '20px' , color: '#f49c0b' }}>Use Cases</h2>
      <ul className="use-cases-list">
        <li><strong style={{  color: '#0c6dc9' }}>Music Enthusiasts:</strong> Manage extensive music collections and discover new songs.</li>
        <li><strong style={{  color: '#0c6dc9' }}>Movie and TV Show Buffs:</strong> Purchase and watch a vast selection of movies and TV shows.</li>
        <li><strong style={{  color: '#0c6dc9' }}>Podcast Listeners:</strong> Subscribe to and consume a wide range of podcasts.</li>
        <li><strong style={{  color: '#0c6dc9' }}>Families:</strong> Share media collections and enjoy content together.</li>
      </ul>


      <img 
        src='/images/MultiMedia/Apple iTunes 2024pic.jpg' 
        alt="Windows 11 Logo" 
        style={{
          width: '600px',
          height: '400px',
          display: 'block', // Ensures block-level behavior for centering
          marginLeft: 'auto', 
          marginRight: 'auto' ,
        }} 
      />








      <h2 style={{ paddingLeft: '20px' , color: '#f49c0b' }}>Conclusion</h2>
      <p style={{ paddingLeft: '5px' }}>
      Apple iTunes 2024 is an essential tool for managing and enjoying media. With its comprehensive features and seamless integration with the Apple ecosystem, iTunes is the go-to application for millions of users. Whether for personal enjoyment or sharing with family and friends, iTunes provides a rich media experience.
      </p>



      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}> 


      <Button
      variant="contained"
      color="primary"
      size="large"
      startIcon={<DownloadIcon />}
      onClick={handleDownload}
      style={{
        backgroundColor: '#0078D4',
        color: '#fff',
        padding: '10px 20px',
        fontSize: '16px',
        borderRadius: '8px',
        textTransform: 'none',
        marginBottom: '20px',
      }}
    
    >
      Free Download Apple iTunes 2024 
    </Button>


</div>

<p style={{ paddingLeft: '5px' }}>
              If the application is not installed on your Windows system, click the button below to proceed with the installation.
            </p>


<div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}> 

    <Button
      variant="contained"
      color="primary"
      size="large"
      startIcon={<DownloadIcon />}
     
      style={{
        backgroundColor: '#47f40b',
        color: '#fff',
        padding: '10px 20px',
        marginLeft: '5px',
        fontSize: '16px',
        borderRadius: '8px',
        textTransform: 'none',
        marginBottom: '20px',
      }}
    >
  Offical Buy Games Teaser AEP
    </Button>

</div>




      </div>
      </div>
      <div className="empty-area"></div>
    </div>
  <Footer />
  </div>
  );
}

export default AppleiTunes2024;
