import React from 'react';
import TopNavbar from './TopHeader';
import LogoComponent from './PageLogo';
import SocialMediaSidebar from '../MenuBar/SocialMediaSidebar';
import MenuBar from '../MenuBar/MenuBar';
import Footer from './Footer';
import '../css/aboutUs.css';





const AboutUs= () => {






  return (
    <div class="background-image">
   <TopNavbar />
   <LogoComponent/>
   <SocialMediaSidebar />


    <div  className="main-layout" style={{ marginTop: '10px' }}>
    <MenuBar />
    </div>

    <div className="container">
      <div className="text-area">
      <div className="about-us-container">
      <h1 className="about-us-title">About Us</h1>
      <p className="about-us-description">
        At <span className="highlight">Easy PC Software</span>, we believe in making technology accessible to everyone. Our platform offers a wide range of software downloads, including operating systems, graphic design tools, development tools, and games. We prioritize user experience, ensuring that each software can be easily downloaded and installed offline. Whether you’re a professional looking for the latest design software or a gamer eager to try out new titles, our user-friendly site simplifies the process, helping you get the tools you need without any hassle. Join us and discover how easy it is to enhance your PC experience!
      </p>
    </div>
      </div>
      <div className="empty-area"></div>
    </div>
  <Footer />
  </div>
  );
}

export default AboutUs;
