import React, { useState } from "react";
import '../OperatingSystem/css/operatingsysyemCat.css';
import { useNavigate } from "react-router-dom";

// Sample data (You can replace this with dynamic data later)
const categories = [


  {
    id:1,
    image: 'images/Games/Dragonrise.png',
    title: 'Free Download Dragonrise Games YoloMouse ',
    time: '2024-09-12',
    details: 'Dragonrise Games YoloMouse is a customizable mouse cursor optimizer. It is designed to improve vision and accuracy in gaming and other applications. With a wide selection of cursor layouts and easy customization options, Users can now customize their cursor experience for better gaming experience and performance.'
  },
  {
    id: 2,
    image: 'images/Games/Video Games.png',
    title: ' Free Download Video Games Titles Classic Games Intro Games Teaser AEP  ',
    time: '2024-09-10',
    details: 'Video game name Classic Game Intro Teaser AEP" is a dynamic After Effects project template designed to create engaging intros for classic video games. Features customizable text and images. Make it easy for users to display Great for teasers and promotional materials.'
  },
  {
    id: 3,
    image: 'images/Games/GooglePlay.png',
    title: 'Free Download Google Play Games for Windows',
    time: '2024-09-04',
    details: 'Google Play Games for Windows is a gaming platform that allows users to access and play their favorite mobile games on Windows PCs with features like cloud saving. Cross-platform play functionality and an improved interface It will enhance the gaming experience by offering a larger screen and improved performance for mobile games.'
  }
  ,
  {
    id: 4,
    image: 'images/Games/Sonic.png',
    title: 'Free Download Sonic Games for Windows',
    time: '2024-09-04',
    details: 'Sonic Games for Windows refers to a collection of classic and modern Sonic the Hedgehog games available on the Windows platform. Players can enjoy fast-paced gameplay. Lively graphics And captivating levels of Sonic and his friends, as well as frequent remastered versions and new adventures optimized for PC.'
  }
  

  ,
  {
    id: 5,
    image: 'images/Games/Kids Learning.png',
    title: 'Free Download Kids Learning Word Games for Windows',
    time: '2024-09-04',
    details: 'Kids Learning Word Games for Windows is an engaging software package designed to increase vocabulary and spelling skills in a fun and interactive way. There are a variety of games and activities appropriate for all ages. By encouraging children learn through play Makes language learning fun and effective.'
  },



  {
    id: 6,
    image: 'images/Games/Racing.png',
    title: 'Free Download Street Racing Games Pack for Windows',
    time: '2024-09-06',
    details: 'Street Racing Games Pack for Windows is a collection of high-octane racing games that immerse players in the adrenaline-fueled world of street racing. Comes with customizable cars A variety of race tracks and exciting multiplayer modes This pack provides a rich gaming experience from the illegal streets. To compete at the professional level It is attractive to competition enthusiasts.'
  },
  {
    id: 7,
    image: 'images/Games/BlockPuzzle.png',
    title: ' Free Download Block Puzzle Games for Windows',
    time: '2024-09-05',
    details: 'Block Puzzle Games for Windows is a collection of addictive puzzle games that challenge players to arrange blocks of various sizes into a grid, with simple mechanics and addictive gameplay. These games promote strategic thinking and problem-solving skills. This makes it suitable for players of all ages. Ideal for quick play or long gaming sessions. They provide a relaxing yet stimulating experience.'
  },
  {
    id: 8,
    image: 'images/Games/Draw & Color.png',
    title: 'Drawing Games: Draw & Color For Kids for Windows',
    time: '2024-09-04',
    details: 'Drawing Game: Draw & Color For Kids for Windows is interactive software designed to foster creativity and artistic skills in young children. There are a variety of fun drawing tools, coloring pages, and animations to help children explore their imaginations as well. with developing fine motor skills in a safe and engaging environment.'
  }
  ,
  {
    id: 9,
    image: 'images/Games/gun.png',
    title: 'Modern Sniper Gun Shooter 3D: FPS Shooting Games for Windows',
    time: '2024-09-04',
    details: 'Modern Sniper Gun Shooter 3D: FPS Shooting Game for Windows is an action-packed first-person shooter that puts players in the role of a skilled sniper. Presents realistic graphics Realistic environment and a variety of challenging missions The game allows players to test it. Accuracy and strategy with many weapons. Complete with upgrades and an engaging experience for fans of FPS games.'
  }
  

  ,
  {
    id: 10,
    image: 'images/Games/flash.png',
    title: 'Free Download Flash Games for Windows',
    time: '2024-09-04',
    details: 'Flash games for Windows basically mean a collection of interactive games. They typically use browsers built using Adobe Flash technology. This wide selection includes different types of games, such as puzzles, action, and arcade games. that provides a fun and engaging gaming experience. Although Flash support has been discontinued, But many classic games are now available through emulators or new releases. It allows players to enjoy nostalgic gaming on their Windows PCs.'
  }
];





// Component to display individual category information
const CategoryCard = ({ category, onClick }) => {


  return (
    <div className="category-card" onClick={onClick}>
      <img src={category.image} alt={category.title} className="category-image" />
      <div className="category-info">
        <h3>{category.title}</h3>
        <p className="category-time">Added on: {category.time}</p>
        <p>{category.details}</p>
      </div>
    </div>
  );
};





// Main Component to display the list of categories
const GamesCategoryList = () => {
  const navigate = useNavigate();  // React Router's navigation hook

  const handleCardClick = (categoryId) => {
    if (categoryId === 1) {
      navigate("/DragonriseGamesYoloMouse"); // Navigate to the Windows 7 page
    } else if (categoryId === 2) {
      navigate("/GamesTeaserAEP"); // Navigate to the Windows 10 page
    }else if (categoryId === 3) {
      navigate("/GooglePlayGames"); // Navigate to the Windows 10 page
    }else if (categoryId === 4) {
      navigate("/SonicGames"); // Navigate to the Windows 10 page
    }else if (categoryId === 5) {
      navigate("/KidsLearningWord"); // Navigate to the Windows 10 page
    }else if (categoryId === 6) {
      navigate("/StreetRacingGames"); // Navigate to the Windows 10 page
    }else if (categoryId === 7) {
      navigate("/BlockPuzzleGames"); // Navigate to the Windows 10 page
    }else if (categoryId === 8) {
      navigate("/DrawColorForKids"); // Navigate to the Windows 10 page
    }
    else if (categoryId === 9) {
      navigate("/SniperGunShooter"); // Navigate to the Windows 10 page
    }else if (categoryId === 10) {
      navigate("/FlashGames"); // Navigate to the Windows 10 page
    }
    
    
    
    
    
    
  };

  return (
    <div className="Main-category">
    <div className="category-list">
      {categories.map((category) => (
        <CategoryCard key={category.id} category={category} onClick={() => handleCardClick(category.id)} />
      ))}


    
    </div>
   
    </div>
  );
};

export default GamesCategoryList;




