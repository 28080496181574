import React from 'react';

import '../css/mainpage.css';

import TopNavbar from '../HeaderAndFooter/TopHeader';
import LogoComponent from '../HeaderAndFooter/PageLogo';
import MenuBar from '../MenuBar/MenuBar';
import SocialMediaSidebar from '../MenuBar/SocialMediaSidebar';
import Footer from '../HeaderAndFooter/Footer';
import GraphicDesignCategoryList from '../Components/GraphicDesign/GraphicDesignCategories';
import ResponsiveList from '../Components/ListCat/ResponsiveList';

const ListPage= () => {
  return (
    <div class="background-image">
    <TopNavbar/>
    <LogoComponent/>
    <SocialMediaSidebar />
    <div  className="main-layout" style={{ marginTop: '10px' }}>
      <MenuBar />
    </div>

    <div  className="main-layout" style={{ marginTop: '80px' }}>
      <ResponsiveList/>
     
    </div>
    <Footer />
  </div>
  );
}

export default ListPage;