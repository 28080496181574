


import React from "react";
import '../assest/SocialMediaSidebar.css'; // Import your custom CSS
import facebookIcon from '../images/facebook.png'; 
import twittericon from '../images/twitter.png';
import instagramicon from '../images/instagram.png';
import linkedin from  '../images/linkedin.png';
import github from '../images/github.png';

const SocialMediaSidebar = () => {
  return (
    <div className="sidebar">
       <a href="https://www.facebook.com/profile.php?id=100067791949452&mibextid=kFxxJD" target="_blank" rel="noopener noreferrer" className="icon facebook">
       <img src={facebookIcon} alt="Facebook" />
      </a>
      <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer" className="icon twitter">
        <img src={twittericon}  alt="Twitter" />
      </a>
      <a href="https://www.instagram.com/@g_m_khan_khattak" target="_blank" rel="noopener noreferrer" className="icon instagram">
        <img src={instagramicon}  alt="Instagram" />
      </a>
      <a href="https://www.linkedin.com/in/g-khattak-960a872b1?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app" target="_blank" rel="noopener noreferrer" className="icon linkedin">
        <img src={linkedin}  alt="LinkedIn" />
      </a>
      <a href="https://github.com/Gulmarkhan" target="_blank" rel="noopener noreferrer" className="icon github">
        <img src={github}  alt="GitHub" />
      </a>
    </div>
  );
};

export default SocialMediaSidebar;
