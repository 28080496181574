import React from 'react';
import { Button } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import TopNavbar from '../HeaderAndFooter/TopHeader';
import LogoComponent from '../HeaderAndFooter/PageLogo';
import SocialMediaSidebar from '../MenuBar/SocialMediaSidebar';
import MenuBar from '../MenuBar/MenuBar';
import Footer from '../HeaderAndFooter/Footer';
import ReactPlayer from "react-player";


const Rufus= () => {



    const handleDownload = () => {
      // Your download logic here
      window.location.href = 'https://rufus.ie/';

      // window.location.href = 'https://rufus.ie/';
    };
    // const handleclickvideos = () => {
    //   // Your download logic here
    //   window.location.href = 'https://youtu.be/LyN9O4yrpD4';

    //   // window.location.href = 'https://rufus.ie/';
    // };



    

  return (
    <div class="background-image">
   <TopNavbar/>
   <LogoComponent />
   <SocialMediaSidebar />


    <div  className="main-layout" >
    <MenuBar />
    </div>

    <div className="container" style={{ marginTop: '50px' }}>
      <div className="text-area">
    <div className='text-mainArea'> 

    <h1 style={{ textAlign: 'center', color: '#8c4d3f', marginTop: '10px' }}>Overview of Rufus for Windows</h1>
      <p style={{ paddingLeft: '5px' }}>
      Rufus is a free, open source utility that helps format and create bootable USB flash drives. It is widely used to install operating systems. Run the diagnostic tool and perform system recovery With its simple interface and powerful capabilities, Rufus is an essential tool for users who want to prepare their USB drives for a variety of purposes.
      </p>

      <h2 style={{ paddingLeft: '20px' , color: '#8c4d3f' }}>Key Features</h2>
      <ul>
        <li><strong>Bootable USB Creation:</strong> Easily create bootable USB drives from ISO images of various operating systems, including Windows, Linux, and more.</li>
        <li><strong>Multiple Partition Schemes:</strong> Supports various partition schemes (MBR and GPT) and file systems (FAT32, NTFS, exFAT) to cater to different installation needs.</li>
        <li><strong>Fast Performance:</strong> Offers high-speed data transfer and formatting, making the creation of bootable USB drives quick and efficient.</li>
        <li><strong>ISO Image Support:</strong> Can use ISO images directly, providing options to customize the partition scheme and file system.</li>
        <li><strong>Persistence Support:</strong> Allows users to create persistent storage on USB drives for Linux distributions, enabling the saving of files and settings.</li>
        <li><strong>Integrated Download Feature:</strong> Provides an option to download the latest ISO images directly within the application for various operating systems.</li>
        <li><strong>User-Friendly Interface:</strong> Features a simple and intuitive interface, making it accessible for both beginners and advanced users.</li>
        <li><strong>Advanced Options:</strong> Offers advanced formatting options for power users, such as the ability to check for bad blocks.</li>
        <li><strong>Multi-Language Support:</strong> Available in multiple languages, catering to a global audience.</li>
      </ul>

      <h2 style={{ paddingLeft: '20px' , color: '#8c4d3f' }}>System Requirements</h2>
      <ul>
        <li><strong>Operating System:</strong> Windows 7, Windows 8, Windows 10, or later.</li>
        <li><strong>Processor:</strong> Intel Pentium 4 or later.</li>
        <li><strong>RAM:</strong> Minimum of 1 GB (2 GB recommended).</li>
        <li><strong>Free Disk Space:</strong> At least 2 MB for installation.</li>
        <li><strong>USB Drive:</strong> Requires a USB flash drive with a minimum capacity of 4 GB for bootable creation.</li>
      </ul>


      <img 
      src='/images/MostP/rufuseasypcsoft.png' 
        alt="window11 logo" 
        style={{
          width: '600px',
          height: '400px',
          display: 'block', // Ensures block-level behavior for centering
          marginLeft: 'auto', 
          marginRight: 'auto' 
        }}
        
      />



      <h2 style={{ paddingTop: '20px' , paddingLeft: '20px' , color: '#8c4d3f' }}>Conclusion</h2>
      <p  style={{ paddingLeft: '5px' }}>
      Rufus is an invaluable tool for users who want to create bootable USB drives for operating system installation or recovery work. Its speed, performance, and user-friendly design make it ideal for both beginners and experienced users. Experienced Whether you need to reinstall your operating system or run a diagnostic tool, Rufus has the features you need for a smooth experience.
      </p>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
      <Button
      variant="contained"
      color="primary"
      size="large"
      startIcon={<DownloadIcon />}
      onClick={handleDownload}
      style={{
        backgroundColor: '#0078D4',
        color: '#fff',
        padding: '10px 20px',
        fontSize: '16px',
        borderRadius: '8px',
        textTransform: 'none',
        marginBottom: '20px',
      }}
    >
      Rufus for Windows
    </Button>
</div>


<p  style={{ paddingLeft: '5px' }}>
              If the application is not installed on your Windows system, click the button below to proceed with the installation.
            </p>
            {/* <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                startIcon={<DownloadIcon />}
                
                onClick={handleclickvideos}    
                style={{
                  backgroundColor: 'green',
                  color: '#fff',
                  padding: '10px 20px',
                  marginLeft: '10px',
                  fontSize: '16px',
                  borderRadius: '8px',
                  textTransform: 'none',
                  marginBottom: '20px',
                }}
              >
                How to download and install 
              </Button>

              </div> */}


  <div >
      <h2 style={{ paddingTop: '20px' , paddingLeft: '20px' ,  color: '#4f4bff' }}>  How to download and install Check the below video </h2>
      <ReactPlayer
      style={{ paddingTop: '20px'  }}
        url="https://youtu.be/LyN9O4yrpD4"
        controls={true} // Show controls for the player
        width="100%"    // Set the width of the player
        height="500px"  // Set the height of the player
      />
    </div>








      </div>
      </div>
      <div className="empty-area"></div>
    </div>
  <Footer />
  </div>
  );
}

export default Rufus ;
