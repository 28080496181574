import React from 'react';

import { Button } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import TopNavbar from '../../HeaderAndFooter/TopHeader';
import LogoComponent from '../../HeaderAndFooter/PageLogo';
import SocialMediaSidebar from '../../MenuBar/SocialMediaSidebar';
import MenuBar from '../../MenuBar/MenuBar';
import Footer from '../../HeaderAndFooter/Footer';



const DrawColorForKids= () => {



    const handleDownload = () => {
      // Your download logic here
      window.location.href = 'https://filehippo.com/download_drawing-games-draw-color-for-kids/';


      // Example link
    };




  return (
    <div class="background-image">
   <TopNavbar />
   <LogoComponent/>
   <SocialMediaSidebar />


    <div  className="main-layout" >
    <MenuBar />
    </div>

    <div className="container" style={{ marginTop: '50px' }}>
      <div className="text-area">
    <div className='text-mainArea'> 

    <h2 style={{ paddingLeft: '30px' , color: '#0fbf5f', marginTop: '20px'  }}>Overview of Draw & Color For Kids Games</h2>


      <p style={{ paddingLeft: '5px' }}>
        Draw & Color for Kids games are designed to spark creativity and imagination in young children. These interactive applications provide a fun and educational platform for kids to explore their artistic talents through drawing and coloring. With various tools and features, these games encourage self-expression while helping to develop fine motor skills and hand-eye coordination.
      </p>
      
      <h2 style={{ paddingLeft: '20px' , color: '#e4b90c' }}>Key Features</h2>
      <ul>
     
        <li><strong style={{  color: '#5dad09' }}  >User-Friendly Interface:</strong> Intuitive controls that make it easy for young children to navigate and use the app without frustration.</li>
        <li><strong style={{  color: '#5dad09' }}>Variety of Tools:</strong> A wide range of drawing tools, including brushes, crayons, and shapes, allowing children to experiment with different artistic styles.</li>
        <li><strong style={{  color: '#5dad09' }}>Color Palettes:</strong> Extensive color options that enable kids to select their favorite colors and create vibrant artworks.</li>
        <li><strong style={{  color: '#5dad09' }}>Pre-Designed Templates:</strong> A selection of fun templates and images to color, helping children get started and inspiring their creativity.</li>
        <li><strong style={{  color: '#5dad09' }}>Save and Share Options:</strong> Features that allow kids to save their artwork and share it with family and friends, fostering a sense of accomplishment.</li>
        <li><strong style={{  color: '#5dad09' }}>Educational Elements:</strong> Games that incorporate learning aspects, such as color recognition, shapes, and patterns, making it both fun and educational.</li>
        <li><strong style={{  color: '#5dad09' }}>No In-App Purchases:</strong> Many apps offer a completely free experience without hidden costs, ensuring that kids can enjoy the games without any financial concerns.</li>
      </ul>
      
      <h2 style={{ paddingLeft: '20px' , color: '#0fbf5f' }}>System Requirements</h2>
      <p style={{ paddingLeft: '5px' }}>
        While specific requirements may vary by app, general system requirements for Draw & Color for Kids games on Windows typically include:
      </p>
      <ul>
        <li><strong style={{  color: '#5dad09' }}>Operating System:</strong> Windows 10 or later (64-bit)</li>
        <li><strong style={{  color: '#5dad09' }}>Processor:</strong> Intel Core i3 or equivalent</li>
        <li><strong style={{  color: '#5dad09' }}>RAM:</strong> At least 4 GB</li>
        <li><strong style={{  color: '#5dad09' }}>Storage:</strong> Minimum of 1 GB free disk space</li>
        <li><strong style={{  color: '#5dad09' }}>Graphics:</strong> Integrated graphics or a basic dedicated GPU</li>
        <li><strong style={{  color: '#5dad09' }}>Internet Connection:</strong> Required for online features and downloads (if applicable)</li>
      </ul>


      <img 
        src='/images/Games/Draw & Color For Kids Gamespic.jpg' 
        alt="Windows 11 Logo" 
        style={{
          width: '400px',
          height: '200px',
          display: 'block', // Ensures block-level behavior for centering
          marginLeft: 'auto', 
          marginRight: 'auto' ,
        }} 
      />



      <h2 style={{ paddingLeft: '20px' , color: '#f14b07' }}>Conclusion</h2>
      <p style={{ paddingLeft: '5px' }}>
        Draw & Color for Kids games provide a wonderful platform for young children to unleash their creativity and enjoy the process of artistic expression. With user-friendly interfaces and a variety of tools and features, these games are ideal for fostering creativity while also aiding in the development of essential skills. Whether through coloring pre-designed images or creating original artwork, kids can have endless fun while learning. These games are an excellent addition to any child's playtime, combining fun and education seamlessly.
      </p>



      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}> 
      <Button
      variant="contained"
      color="primary"
      size="large"
      startIcon={<DownloadIcon />}
      onClick={handleDownload}
      style={{
        backgroundColor: '#08c725',
        color: '#fff',
        padding: '10px 20px',
        fontSize: '16px',
        borderRadius: '8px',
        textTransform: 'none',
      }}
    >
  Free DownLoad Draw Color For Kids for Windows
    </Button>

 </div>

 <p  style={{ paddingLeft: '5px' }}>
              If the application is not installed on your Windows system, click the button below to proceed with the installation.
            </p>


<div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}> 

    <Button
      variant="contained"
      color="primary"
      size="large"
      startIcon={<DownloadIcon />}
   
      style={{
        backgroundColor: '#0078D4',
        color: '#fff',
        padding: '10px 20px',
        marginLeft: '5px',
        fontSize: '16px',
        borderRadius: '8px',
        textTransform: 'none',
        marginBottom: '20px',
      }}
    >
  Offical Buy Games Teaser AEP
    </Button>

</div>




      </div>
      </div>
      <div className="empty-area"></div>
    </div>
  <Footer />
  </div>
  );
}

export default DrawColorForKids;
