import React from 'react';

import { Button } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import TopNavbar from '../../HeaderAndFooter/TopHeader';
import LogoComponent from '../../HeaderAndFooter/PageLogo';
import SocialMediaSidebar from '../../MenuBar/SocialMediaSidebar';
import MenuBar from '../../MenuBar/MenuBar';
import Footer from '../../HeaderAndFooter/Footer';



const DragonriseGames= () => {



    const handleDownload = () => {
      // Your download logic here
      window.location.href = 'https://getintopc.com/softwares/utilities/dragonrise-games-yolomouse-2023-free-download/';


      // Example link
    };


    const handleDownloadbuy = () => {
        // Your download logic here
        window.location.href = 'https://www.dragonrise.com/yolomouse/';
  
  
        // Example link
      };



  return (
    <div class="background-image">
   <TopNavbar />
   <LogoComponent/>
   <SocialMediaSidebar />


    <div  className="main-layout" >
    <MenuBar />
    </div>

    <div className="container" style={{ marginTop: '50px' }}>
      <div className="text-area">
    <div className='text-mainArea'> 

    <h2 style={{ paddingLeft: '30px' , color: '#ff00b9', marginTop: '20px'  }}>Overview of Dragonrise Games YoloMouse free download</h2>
      <p style={{ paddingLeft: '5px' }}>

       Dragonrise Games YoloMouse is a multi-purpose utility designed to enhance the mouse experience in video games. Especially for those who don't natively support mouse cursor customization... 
        It allows users to change the appearance of the mouse cursor and provides additional functionality to improve game launch. 
        YoloMouse is especially useful for players who want a more personalized gaming experience. Or do you need to adjust the cursor for better visibility.
      </p>

      <h2 style={{ paddingLeft: '20px' , color: '#ff00b9' }}>Key Features</h2>
      <ul>
        <li>
         <strong style={{  color: '#ff00b9' }}>Custom Cursor Support:</strong>
          <ul>
            <li>Allows users to change the mouse cursor to various custom designs.</li>
            <li>Supports animated cursors for enhanced visual appeal.</li>
          </ul>
        </li>
        <li>
          <strong  style={{  color: '#ff00b9' }}>Cursor Visibility Options:</strong>
          <ul>
            <li>Provides options to adjust cursor size and visibility, making it easier to see in various game environments.</li>
            <li>Customizable cursor color to improve contrast against backgrounds.</li>
          </ul>
        </li>
        <li>
          <strong style={{  color: '#ff00b9' }}>Game-Specific Profiles:</strong>
          <ul>
            <li>Enables users to create and switch between profiles tailored for different games.</li>
            <li>Automatically applies specific settings when launching supported games.</li>
          </ul>
        </li>
        <li>
          <strong style={{  color: '#ff00b9' }}>Multi-Monitor Support:</strong>
          <ul>
            <li>Works seamlessly with multiple monitor setups, allowing for consistent cursor behavior across screens.</li>
          </ul>
        </li>
        <li>
          <strong style={{  color: '#ff00b9' }}>Hotkey Functionality:</strong>
          <ul>
            <li>Assign hotkeys to quickly switch between different cursors or profiles during gameplay.</li>
            <li>Streamlines the user experience by reducing the need for in-game menu navigation.</li>
          </ul>
        </li>
        <li>
          <strong style={{  color: '#ff00b9' }}>User-Friendly Interface:</strong>
          <ul>
            <li>Simple and intuitive interface for easy customization and management of cursor settings.</li>
            <li>Preview feature to see how cursors will look before applying changes.</li>
          </ul>
        </li>
        <li>
          <strong style={{  color: '#ff00b9' }}>Compatibility:</strong>
          <ul>
            <li>Compatible with a wide range of games, including those that lack native cursor support.</li>
            <li>Regular updates to expand compatibility with new titles and features.</li>
          </ul>
        </li>
      </ul>

      <ul>
        <li>
          <strong style={{  color: '#ff00b9' }}>Operating System:</strong>
          <ul>
            <li>Windows 7 or later (64-bit)</li>
          </ul>
        </li>
        <li>
          <strong style={{  color: '#ff00b9' }}>Hardware:</strong>
          <ul>
            <li>Minimum 2 GB RAM (4 GB or more recommended)</li>
            <li>At least 100 MB of free disk space for installation</li>
          </ul>
        </li>
        <li>
          <strong style={{  color: '#ff00b9' }}>Software:</strong>
          <ul>
            <li>.NET Framework 4.5 or later</li>
            <li>DirectX 9 or later for optimal performance in games</li>
          </ul>
        </li>
      </ul>

      <h2 style={{ paddingLeft: '20px' , color: '#ff00b9' }}>System Requirements</h2>
      
      <ul>
        <li><strong>OS:</strong> Windows 10/11 (64-bit)</li>
        <li><strong>Processor:</strong> Intel Core i5 or higher</li>
        <li><strong>Memory:</strong> 8 GB RAM or more</li>
        <li><strong>Graphics:</strong> NVIDIA or AMD GPU with support for DirectX 11</li>
        <li><strong>Storage:</strong> 50 MB available space (SSD recommended)</li>
      </ul>

      <img 
        src='/images/Development/Dragonrise Games YoloMousepic.jpg' 
        alt="Windows 11 Logo" 
        style={{
          width: '600px',
          height: '400px',
          display: 'block', // Ensures block-level behavior for centering
          marginLeft: 'auto', 
          marginRight: 'auto' ,
        }} 
      />


      <h2 style={{ paddingLeft: '20px' , color: '#ff00b9' }}>Conclusion</h2>
      <p style={{ paddingLeft: '5px' }}>
        Dragonrise Games YoloMouse is an excellent tool for gamers looking to customize their mouse experience and improve gameplay comfort. 
        With its range of features, including custom cursors, game-specific profiles, and enhanced visibility options, 
        YoloMouse makes it easier for players to navigate their virtual worlds effectively. 
        Whether you're a casual gamer or a competitive player, YoloMouse offers the tools to enhance your gaming experience.
      </p>


      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}> 
      <Button
      variant="contained"
      color="primary"
      size="large"
      startIcon={<DownloadIcon />}
      onClick={handleDownload}
      style={{
        backgroundColor: '#08c725',
        color: '#fff',
        padding: '10px 20px',
        fontSize: '16px',
        borderRadius: '8px',
        textTransform: 'none',
        marginBottom: '20px',
      }}
    >
  Free DownLoad Dragonrise Games YoloMouse
    </Button>

</div>

<p  style={{ paddingLeft: '5px' }}>
              If the application is not installed on your Windows system, click the button below to proceed with the installation.
            </p>
<div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}> 

    <Button
      variant="contained"
      color="primary"
      size="large"
      startIcon={<DownloadIcon />}
      onClick={handleDownloadbuy }
      style={{
        backgroundColor: '#0078D4',
        color: '#fff',
        padding: '10px 20px',
        marginLeft: '5px',
        fontSize: '16px',
        borderRadius: '8px',
        textTransform: 'none',
        marginBottom: '20px',
      }}
    >
  Offical Buy Dragonrise Games YoloMouse
    </Button>
</div>



      </div>
      </div>
      <div className="empty-area"></div>
    </div>
  <Footer />
  </div>
  );
}

export default DragonriseGames;
