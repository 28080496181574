import React from 'react';
import TopNavbar from '../../HeaderAndFooter/TopHeader';
import LogoComponent from '../../HeaderAndFooter/PageLogo';
import SocialMediaSidebar from '../../MenuBar/SocialMediaSidebar';
import MenuBar from '../../MenuBar/MenuBar';
import Footer from '../../HeaderAndFooter/Footer';
import './css/windows10sp1.css';
import { Button } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';


const Windows10sp1= () => {



    const handleDownload = () => {
      // Your download logic here
      window.location.href = 'https://getintopc.com/softwares/operating-systems/windows-10-april-2023-free-download-1438426/';


      // Example link
    };





  return (
    <div class="background-image">
   <TopNavbar />
   <LogoComponent />
   <SocialMediaSidebar />


    <div  className="main-layout" >
    <MenuBar />
    </div>

    <div className="container" style={{ marginTop: '50px' }}>
      <div className="text-area">
    <div className='text-mainArea'> 

     <h1 style={{ textAlign: 'center', color: '#088ff2', marginTop: '10px' }}>Overview of Windows 10 Free Download </h1>
      <p style={{ paddingLeft: '5px' }}>
        Windows 10 is one of Microsoft's most popular and widely-used operating systems, offering a blend of productivity tools, security features, and flexibility to cater to both personal and business needs. Released in 2015, Windows 10 introduced key enhancements in user interface, multitasking, and performance. Here's an overview of its key features and system requirements:
      </p>

      <h2 style={{ paddingLeft: '20px' , color: '#088ff2' }}>Key Features</h2>
      <ul>
        <li>
          <strong style={{ paddingLeft: '20px' , color: '#088ff2' }}>Start Menu and Taskbar:</strong> The Start menu, combining the best elements of Windows 7 and Windows 8, provides quick access to apps, settings, and live tiles. The taskbar is highly customizable, allowing users to pin frequently used apps and monitor system status.
        </li>
        <li>
          <strong style={{ paddingLeft: '20px' , color: '#088ff2' }}>Cortana:</strong> Windows 10 includes the Cortana virtual assistant, offering voice-activated search and task management features like reminders and calendar integration.
        </li>
        <li>
          <strong style={{ paddingLeft: '20px' , color: '#088ff2' }}>   Virtual Desktops & Task View:</strong> Users can create multiple virtual desktops for organizing open applications and improving productivity. The Task View button provides a snapshot of all open windows and desktops.
        </li>
        <li>
          <strong style={{ paddingLeft: '20px' , color: '#088ff2' }}>Microsoft Edge:</strong> Windows 10 introduced the Edge browser, designed for speed and integration with modern web standards, while offering features like reading mode and annotations.
        </li>
        <li>
          <strong style={{ paddingLeft: '20px' , color: '#088ff2' }}>Continuum:</strong> For hybrid devices like tablets and 2-in-1s, Continuum automatically adjusts the user interface for touch or keyboard input, providing a seamless experience when switching between modes.
        </li>
        <li>
          <strong style={{ paddingLeft: '20px' , color: '#088ff2' }}>Gaming Enhancements:</strong> Windows 10 supports DirectX 12, improving performance for gaming and graphics-heavy applications. The Xbox App allows gamers to stream games from their Xbox consoles to a Windows PC.
        </li>
        <li>
          <strong style={{ paddingLeft: '20px' , color: '#088ff2' }}>Security:</strong> Windows 10 comes with advanced security features, including Windows Defender for real-time virus protection, Windows Hello for biometric authentication, and BitLocker for encryption.
        </li>
        <li>
          <strong style={{ paddingLeft: '20px' , color: '#088ff2' }}>Windows Store:</strong> Unified app store for downloading and updating both desktop and mobile applications.
        </li>
        <li>
          <strong style={{ paddingLeft: '20px' , color: '#088ff2' }}>Updates & Support:</strong> Windows 10 follows a service model, providing continuous updates for security, features, and functionality without requiring users to purchase new versions.
        </li>
      </ul>

      <img 
        src='/images/operatingsystem/freewindwos10.jpeg' 
        alt="Windows 11 Logo" 
        style={{
          width: '600px',
          height: '400px',
          display: 'block', // Ensures block-level behavior for centering
          marginLeft: 'auto', 
          marginRight: 'auto' 
        }} 
      />

      <h2 style={{ paddingLeft: '20px' , color: '#088ff2' }}>System Requirements</h2>
      <ul>
        <li><strong > Processor:</strong> 1 GHz or faster compatible processor.</li>
        <li><strong>RAM:</strong> 1 GB for 32-bit or 2 GB for 64-bit.</li>
        <li><strong>Storage:</strong> 16 GB for 32-bit or 20 GB for 64-bit.</li>
        <li><strong>Graphics Card:</strong> DirectX 9 or later with WDDM 1.0 driver.</li>
        <li><strong>Display:</strong> 800x600 resolution.</li>
        <li><strong>Internet Connection:</strong> Required for downloading updates and certain features.</li>
      </ul>

      <h2 style={{ paddingLeft: '20px' , color: '#088ff2' }}>Performance and Efficiency</h2>
      <p style={{ paddingLeft: '5px' }}>
        Windows 10 is optimized to run efficiently on both new and older hardware. It provides fast boot times and better performance, particularly on devices with SSD storage. Power management improvements extend battery life on laptops and tablets.
      </p>

      <h2 style={{ paddingLeft: '20px' , color: '#088ff2' }}>Conclusion</h2>
      <p style={{ paddingLeft: '5px' }}>
        Windows 10 is a versatile operating system with a broad range of features that cater to various user needs, from gaming and productivity to security and multitasking. It is widely compatible with both legacy applications and modern apps, ensuring a smooth transition for users coming from older versions of Windows.
      </p>
      

      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>

      <Button
      variant="contained"
      color="primary"
      size="large"
      startIcon={<DownloadIcon />}
      onClick={handleDownload}
      style={{
        backgroundColor: '#0078D4',
        color: '#fff',
        padding: '10px 20px',
        fontSize: '16px',
        borderRadius: '8px',
        textTransform: 'none',
    
        marginBottom:'20px',
      }}
    >
      Free Download Windows 10
    </Button>
</div>



    <p  style={{ paddingLeft: '5px' }}>
              If the application is not installed on your Windows system, click the button below to proceed with the installation.
            </p>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                startIcon={<DownloadIcon />}
               
                style={{
                  backgroundColor: 'green',
                  color: '#fff',
                  padding: '10px 20px',
                  marginLeft: '10px',
                  fontSize: '16px',
                  borderRadius: '8px',
                  textTransform: 'none',
                  marginBottom: '20px',
                }}
              >
                How to download and install 
              </Button>

              </div>




      </div>
      </div>
      <div className="empty-area"></div>
    </div>
  <Footer />
  </div>

  );
}

export default Windows10sp1 ;
