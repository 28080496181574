import React from 'react';

import { Button } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import TopNavbar from '../../HeaderAndFooter/TopHeader';
import LogoComponent from '../../HeaderAndFooter/PageLogo';
import SocialMediaSidebar from '../../MenuBar/SocialMediaSidebar';
import MenuBar from '../../MenuBar/MenuBar';
import Footer from '../../HeaderAndFooter/Footer';



const StreetRacingGames= () => {



    const handleDownload = () => {
      // Your download logic here
      window.location.href = 'https://filehippo.com/download_street-racing-games-pack/';


      // Example link
    };




  return (
    <div class="background-image">
   <TopNavbar />
   <LogoComponent/>
   <SocialMediaSidebar />


    <div  className="main-layout" >
    <MenuBar />
    </div>

    <div className="container" style={{ marginTop: '50px' }}>
      <div className="text-area">
    <div className='text-mainArea'> 

    <h1 style={{ paddingLeft: '30px' , color: '#0fbf5f', marginTop: '20px'  }}>Overview of Street Racing Games</h1>
      <p style={{ paddingLeft: '5px' }}>
        Street racing games are a popular genre in the gaming world that simulate illegal street racing and urban car culture. These games often feature high-speed racing in city environments, offering players the thrill of competition and the excitement of driving exotic cars. With realistic graphics and engaging gameplay, street racing games have captivated racing enthusiasts and casual gamers alike.
      </p>
      
      <h2 style={{ paddingLeft: '20px' , color: '#0a07fa' }}>Key Features</h2>
      <ul>
        <li><strong style={{  color: '#ebf609' }} >Realistic Graphics:</strong> High-quality visuals that depict detailed car models and dynamic urban environments, enhancing the immersive experience.</li>
        <li><strong style={{  color: '#ebf609' }}>Variety of Vehicles:</strong> A wide selection of cars, from sports cars to muscle cars, allowing players to customize and upgrade their vehicles to improve performance.</li>
        <li><strong style={{  color: '#ebf609' }}>Dynamic Gameplay:</strong> Fast-paced racing with various modes, including time trials, circuit races, and elimination challenges, keeping the gameplay fresh and exciting.</li>
        <li><strong style={{  color: '#ebf609' }}>Customizable Options:</strong> Extensive customization options for vehicles, including visual modifications (paint jobs, decals) and performance upgrades (engine, tires, suspension).</li>
        <li><strong style={{  color: '#ebf609' }}>Multiplayer Modes:</strong> Online and local multiplayer options that enable players to compete against friends or other players worldwide, adding a competitive edge.</li>
        <li><strong style={{  color: '#ebf609' }}>Engaging Soundtrack:</strong> A curated soundtrack featuring popular music tracks that enhance the adrenaline-pumping atmosphere of the races.</li>
        <li><strong style={{  color: '#ebf609' }}>Open-World Environments:</strong> Many street racing games feature open-world settings, allowing players to explore the city and discover hidden challenges and collectibles.</li>
      </ul>
      
      <h2 style={{ paddingLeft: '20px' , color: '#0a07fa' }}>System Requirements</h2>
      <p style={{ paddingLeft: '5px' }}>
        While specific requirements may vary based on the title, general system requirements for street racing games on Windows often include:
      </p>
      <ul>
        <li><strong style={{  color: '#ebf609' }}>Operating System:</strong> Windows 10 or later (64-bit)</li>
        <li><strong style={{  color: '#ebf609' }}>Processor:</strong> Intel Core i5 or equivalent</li>
        <li><strong style={{  color: '#ebf609' }}>RAM:</strong> At least 8 GB</li>
        <li><strong style={{  color: '#ebf609' }}>Storage:</strong> Minimum of 20 GB free disk space</li>
        <li><strong style={{  color: '#ebf609' }}>Graphics:</strong> NVIDIA GeForce GTX 760 or equivalent</li>
        <li><strong style={{  color: '#ebf609' }}>DirectX:</strong> Version 11 or higher</li>
        <li><strong style={{  color: '#ebf609' }}>Internet Connection:</strong> Required for multiplayer features and online content</li>
      </ul>

      <h2 style={{ paddingLeft: '20px' , color: '#0a07fa' }}>Conclusion</h2>
      <p style={{ paddingLeft: '5px' }}>
        Street racing games provide an exhilarating experience for players who enjoy high-speed competition and car culture. With stunning graphics, a wide array of vehicles, and engaging gameplay modes, these games have something to offer for both racing aficionados and casual gamers. The combination of customization options and multiplayer features enhances the overall experience, making street racing games a thrilling addition to any gaming library. Whether you're racing against friends or exploring an open-world city, the adrenaline rush of street racing is sure to captivate players.
      </p>

      
      <Button
      variant="contained"
      color="primary"
      size="large"
      startIcon={<DownloadIcon />}
      onClick={handleDownload}
      style={{
        backgroundColor: '#08c725',
        color: '#fff',
        padding: '10px 20px',
        fontSize: '16px',
        borderRadius: '8px',
        textTransform: 'none',
      }}
    >
  Free DownLoad Street Racing Games for Windows
    </Button>

 




      </div>
      </div>
      <div className="empty-area"></div>
    </div>
  <Footer />
  </div>
  );
}

export default StreetRacingGames;
