import React from 'react';

import { Button } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import TopNavbar from '../../HeaderAndFooter/TopHeader';
import LogoComponent from '../../HeaderAndFooter/PageLogo';
import SocialMediaSidebar from '../../MenuBar/SocialMediaSidebar';
import MenuBar from '../../MenuBar/MenuBar';
import Footer from '../../HeaderAndFooter/Footer';



const SniperGunShooter= () => {



    const handleDownload = () => {
      // Your download logic here
      window.location.href = 'https://filehippo.com/download_modern-sniper-gun-shooter-3d-fps-shooting-games/';


      // Example link
    };




  return (
    <div class="background-image">
   <TopNavbar />
   <LogoComponent/>
   <SocialMediaSidebar />


    <div  className="main-layout" >
    <MenuBar />
    </div>

    <div className="container" style={{ marginTop: '50px' }}>
      <div className="text-area">
    <div className='text-mainArea'> 

    <h2 style={{ paddingLeft: '30px' , color: '#0a75a4', marginTop: '20px'  }}>Overview of Sniper Gun Shooter  FPS Shooting Games</h2>
      <p style={{ paddingLeft: '5px' }}>
      Sniper Gun Shooter 3D is an action-packed first-person shooter (FPS) that takes players into the exciting world of sniper shooting. With realistic graphics and engaging gameplay. The game puts the player in the role of a skilled marksman. Complete various missions and challenges that test their accuracy and reflexes. From urban environments to remote landscapes Players must use strategy and precision to achieve their goals.
      </p>
      
      <h2 style={{ paddingLeft: '20px' , color: '#0fbf5f' }} >Key Features</h2>
      <ul>
        <li><strong style={{  color: '#f4ad0b' }} >Realistic Graphics and Sound:</strong> Stunning 3D graphics and immersive sound effects create a lifelike shooting experience that draws players into the game.</li>
        <li><strong style={{  color: '#f4ad0b' }} >Variety of Weapons:</strong> A wide range of sniper rifles and other firearms, each with unique attributes, allows players to choose the best weapon for their missions.</li>
        <li><strong style={{  color: '#f4ad0b' }} >Multiple Game Modes:</strong> Different modes such as campaign missions, survival challenges, and timed events offer diverse gameplay experiences.</li>
        <li><strong style={{  color: '#f4ad0b' }} >Challenging Missions:</strong> Players must complete various missions with increasing difficulty, requiring skillful sniping and strategic planning.</li>
        <li><strong style={{  color: '#f4ad0b' }} >Intuitive Controls:</strong> Easy-to-use controls make it accessible for players of all skill levels, from beginners to experienced gamers.</li>
        <li><strong style={{  color: '#f4ad0b' }} >Customization Options:</strong> Players can upgrade and customize their weapons with scopes, silencers, and other attachments to enhance performance.</li>
        <li><strong style={{  color: '#f4ad0b' }} >Leaderboards and Achievements:</strong> Compete with friends and players worldwide through leaderboards, and unlock achievements for completing specific challenges.</li>
      </ul>
      
      <h2 style={{ paddingLeft: '20px' , color: '#0fbf5f' }}>System Requirements</h2>
      <p>
        While specific requirements may vary based on the platform, general system requirements for Sniper Gun Shooter 3D on Windows typically include:
      </p>
      <ul>
        <li><strong style={{  color: '#f4ad0b' }}   >Operating System:</strong> Windows 10 or later (64-bit)</li>
        <li><strong style={{  color: '#f4ad0b' }} >Processor:</strong> Intel Core i5 or equivalent</li>
        <li><strong style={{  color: '#f4ad0b' }} >RAM:</strong> At least 8 GB</li>
        <li><strong style={{  color: '#f4ad0b' }} >Storage:</strong> Minimum of 2 GB free disk space</li>
        <li><strong style={{  color: '#f4ad0b' }} >Graphics:</strong> NVIDIA GeForce GTX 660 or equivalent</li>
        <li><strong style={{  color: '#f4ad0b' }} >DirectX:</strong> Version 11 or higher</li>
        <li><strong style={{  color: '#f4ad0b' }} >Internet Connection:</strong> Required for online features and updates</li>
      </ul>


      <img 
        src='/images/Games/Sniper Gun Shooter 3Dpic1.jpg' 
        alt="Windows 11 Logo" 
        style={{
          width: '600px',
          height: '400px',
          display: 'block', // Ensures block-level behavior for centering
          marginLeft: 'auto', 
          marginRight: 'auto' ,
        }} 
      />




      <h2 style={{ paddingLeft: '20px' , color: '#0fbf5f' }}>Conclusion</h2>
      <p>
      Sniper Gun Shooter 3D: FPS Shooting Game provides an exciting experience for players who love action-packed gameplay and accurate shooting. with realistic graphics A wide variety of weapons and various missions The game provides hours of entertainment for both casual and hardcore gamers. The combination of tactical gameplay and exciting action makes this a standout game in the FPS genre, appealing to those seeking a realistic sniper experience. Whether you are taking down targets in the campaign or competing against others, Sniper Gun Shooter 3D offers intense and engaging gameplay. To the players they come back for more.
      </p>

      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}> 

      <Button
      variant="contained"
      color="primary"
      size="large"
      startIcon={<DownloadIcon />}
      onClick={handleDownload}
      style={{
        backgroundColor: '#08c725',
        color: '#fff',
        padding: '10px 20px',
        fontSize: '16px',
        borderRadius: '8px',
        textTransform: 'none',
        marginBottom: '20px',
      }}
    >
  Free DownLoad Sniper Gun Shooter for Windows
    </Button>

 </div>

 <p  style={{ paddingLeft: '5px' }}>
              If the application is not installed on your Windows system, click the button below to proceed with the installation.
            </p>


<div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}> 

    <Button
      variant="contained"
      color="primary"
      size="large"
      startIcon={<DownloadIcon />}
     
      style={{
        backgroundColor: '#0078D4',
        color: '#fff',
        padding: '10px 20px',
        marginLeft: '5px',
        fontSize: '16px',
        borderRadius: '8px',
        textTransform: 'none',
        marginBottom: '20px',
      }}
    >
  Offical Buy Games Teaser AEP
    </Button>

</div>




      </div>
      </div>
      <div className="empty-area"></div>
    </div>
  <Footer />
  </div>
  );
}

export default SniperGunShooter;
