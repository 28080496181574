import React from 'react';
import { Button } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import TopNavbar from '../../HeaderAndFooter/TopHeader';
import LogoComponent from '../../HeaderAndFooter/PageLogo';
import SocialMediaSidebar from '../../MenuBar/SocialMediaSidebar';
import MenuBar from '../../MenuBar/MenuBar';
import Footer from '../../HeaderAndFooter/Footer';



const AdobeIllustrator1= () => {



    const handleDownload = () => {
      // Your download logic here
      window.location.href = 'https://getintopc.com/softwares/3d-visualization/adobe-illustrator-2024-free-download/';


      // Example link
    };





  return (
    <div class="background-image">
   <TopNavbar />
   <LogoComponent/>
   <SocialMediaSidebar />


    <div  className="main-layout" >
    <MenuBar />
    </div>

    <div className="container" style={{ marginTop: '50px' }}>
      <div className="text-area">
    <div className='text-mainArea'> 

    <h2 style={{ paddingLeft: '30px' , color: '#c87c06', marginTop: '10px'  }}>Overview of Adobe Illustrator 2024</h2>
            <p style={{ paddingLeft: '5px' }}>
            Adobe Illustrator 2024 is a powerful vector graphics editor widely used by graphic designers, artists, and illustrators. 
                With improved features and performance improvements Users can then create stunning images, logos, and images… 
                with precision and creativity.
            </p>

            <h2 style={{ paddingLeft: '20px' , color: '#c87c06' }}>Key Features</h2>
            <ul>
                <li><strong style={{  color: '#c87c06' }}>AI-Powered Tools:</strong> Utilizes artificial intelligence for improved object selection, color suggestions, and design enhancements, speeding up the creative process.</li>
                <li><strong style={{  color: '#c87c06' }}>Enhanced Collaboration:</strong> Improved sharing and collaboration features allow multiple users to work on a project simultaneously, making it easier to gather feedback and make revisions.</li>
                <li><strong style={{  color: '#c87c06' }}>New User Interface:</strong> A refreshed UI provides a more intuitive layout, improving accessibility to tools and features.</li>
                <li><strong style={{  color: '#c87c06' }}>Vector Effects:</strong> New effects and styles for vectors allow for more creative freedom, enabling designers to add textures, shadows, and gradients with ease.</li>
                <li><strong style={{  color: '#c87c06' }}>Pattern Creation:</strong> Simplified tools for creating complex patterns and seamless designs, enhancing workflow efficiency.</li>
                <li><strong style={{  color: '#c87c06' }}>Expanded Asset Libraries:</strong> Access to an extensive library of templates, stock images, and design assets directly within the application.</li>
                <li><strong style={{  color: '#c87c06' }}>Improved Performance:</strong> Optimized for speed and efficiency, Illustrator 2024 ensures smoother operation even with large files.</li>
                <li><strong style={{  color: '#c87c06' }}>Cross-Platform Compatibility:</strong> Available on both Windows and macOS, with consistent features and functionality across platforms.</li>
                <li><strong style={{  color: '#c87c06' }}>Integration with Adobe Creative Cloud:</strong> Seamless integration with other Adobe applications allows for an efficient workflow and easy asset sharing.</li>
            </ul>

            <h2 style={{ paddingLeft: '20px' , color: '#c87c06' }}>System Requirements</h2>
            <ul>
                <li><strong>Operating System:</strong> 
                    <ul>
                        <li>Windows 10 (64-bit) or later</li>
                        <li>macOS 11.0 or later</li>
                    </ul>
                </li>
                <li><strong>Processor:</strong> Multicore Intel processor with 64-bit support</li>
                <li><strong>RAM:</strong> Minimum of 8 GB (16 GB recommended)</li>
                <li><strong>Graphics Card:</strong> GPU with DirectX 12 support (Windows) or Metal support (macOS)</li>
                <li><strong>Free Disk Space:</strong> At least 2 GB for installation (additional space required for user-generated content)</li>
                <li><strong>Display:</strong> 1280 x 800 resolution (1920 x 1080 or higher recommended)</li>
            </ul>

      <img 
        src='/images/GraphicDesign/abobeillustrator2024pic1.jpg' 
        alt="Windows 11 Logo" 
        style={{
          width: '400px',
          height: '200px',
          display: 'block', // Ensures block-level behavior for centering
          marginLeft: 'auto', 
          marginRight: 'auto' ,
        }} 
      />



            <h2 style={{ paddingLeft: '20px' , color: '#c87c06' }}>Conclusion</h2>
            <p style={{ paddingLeft: '5px' }}>
            Adobe Illustrator 2024 is designed for professionals and beginners. It provides a comprehensive set of tools for designing vector graphics. 
                With powerful features Improved collaboration options and improved performance It continues to be the leading choice for creatives looking to produce… 
                High quality artwork and design Whether you're creating a logo, illustration, or complex illustration, Illustrator 2024 has the tools you need. 
                To make your vision come true.
            </p>



            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>       
      <Button
      variant="contained"
      color="primary"
      size="large"
      startIcon={<DownloadIcon />}
      onClick={handleDownload}
      style={{
        backgroundColor: '#0078D4',
        color: '#fff',
        padding: '10px 20px',
        fontSize: '16px',
        borderRadius: '8px',
        textTransform: 'none',
        marginBottom: '20px',
      }}
    >
   DownLoad Adobe Illustrator 2024
    </Button>


</div>


<p  style={{ paddingLeft: '5px' }}>
              If the application is not installed on your Windows system, click the button below to proceed with the installation.
            </p>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                startIcon={<DownloadIcon />}
               
                style={{
                  backgroundColor: 'green',
                  color: '#fff',
                  padding: '10px 20px',
                  marginLeft: '10px',
                  fontSize: '16px',
                  borderRadius: '8px',
                  textTransform: 'none',
                  marginBottom: '20px',
                }}
              >
                How to download and install 
              </Button>

              </div>





      </div>
      </div>
      <div className="empty-area"></div>
    </div>
  <Footer />
  </div>
  );
}

export default AdobeIllustrator1 ;
