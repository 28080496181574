import React from 'react';

import { Button } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import TopNavbar from '../../HeaderAndFooter/TopHeader';
import LogoComponent from '../../HeaderAndFooter/PageLogo';
import SocialMediaSidebar from '../../MenuBar/SocialMediaSidebar';
import MenuBar from '../../MenuBar/MenuBar';
import Footer from '../../HeaderAndFooter/Footer';



const AepGames= () => {



    const handleDownload = () => {
      // Your download logic here
      window.location.href = 'https://getintopc.com/softwares/video-editing/videohive-video-games-titles-classic-games-intro-games-teaser-aep-free-download/';


      // Example link
    };


    const handleDownloadbuy = () => {
        // Your download logic here
        window.location.href = 'https://www.example.com/classic-games-intro-teaser';
  
  
        // Example link
      };



  return (
    <div class="background-image">
   <TopNavbar />
   <LogoComponent/>
   <SocialMediaSidebar />


    <div  className="main-layout" >
    <MenuBar />
    </div>

    <div className="container" style={{ marginTop: '50px' }}>
      <div className="text-area">
    <div className='text-mainArea'> 

    <h2 style={{ paddingLeft: '30px' , color: '#0fbf5f', marginTop: '20px'  }}>Overview of Classic Games Intro Games Teaser AEP</h2>
      <p style={{ paddingLeft: '5px' }}>
      Classic Games Intro Game Teaser AEP is a collection of nostalgic video game titles designed to keep players engaged with engaging introductions and teasers. 
      This anthology aims to conjure up memories of classic gaming experiences while introducing modern gameplay mechanics. ​​Makes it appealing to both long-time fans and new players.
      </p>

      <h2 style={{ paddingLeft: '20px' , color: '#0fbf5f' }}>Key Features</h2>
      <ul>
        <li>
         <strong style={{  color: '#0fbf5f' }}  >Nostalgic Gameplay:</strong>
          <ul>
            <li>Revives beloved classic titles with enhanced graphics and sound.</li>
            <li>Incorporates traditional gameplay mechanics that fans cherish.</li>
          </ul>
        </li>
        <li>
          <strong style={{  color: '#0fbf5f' }}>Dynamic Introductions:</strong>
          <ul>
            <li>Engaging intro sequences that set the stage for each game.</li>
            <li>High-quality animations and storytelling to draw players in.</li>
          </ul>
        </li>
        <li>
          <strong style={{  color: '#0fbf5f' }}>Diverse Game Genres:</strong>
          <ul>
            <li>Includes a variety of genres such as platformers, puzzles, and adventure games.</li>
            <li>Appeals to a wide range of gaming preferences.</li>
          </ul>
        </li>
        <li>
          <strong style={{  color: '#0fbf5f' }}>Modern Enhancements:</strong>
          <ul>
            <li>Updated controls and mechanics for smoother gameplay.</li>
            <li>Optional tutorials to help new players acclimate.</li>
          </ul>
        </li>
        <li>
          <strong style={{  color: '#0fbf5f' }}>Multiplayer Options:</strong>
          <ul>
            <li>Local and online multiplayer modes to enhance social gaming experiences.</li>
            <li>Competitive and cooperative gameplay features.</li>
          </ul>
        </li>
        <li>
          <strong style={{  color: '#0fbf5f' }}>Achievements and Rewards:</strong>
          <ul>
            <li>In-game achievements that reward players for completing challenges.</li>
            <li>Unlockable content and bonuses to encourage exploration.</li>
          </ul>
        </li>
        <li>
          <strong style={{  color: '#0fbf5f' }}>Customizable Settings:</strong>
          <ul>
            <li>Options to adjust graphics, controls, and audio settings for a personalized experience.</li>
            <li>Accessibility features to accommodate different player needs.</li>
          </ul>
        </li>
      </ul>

      <h2 style={{ paddingLeft: '20px' , color: '#0fbf5f' }}>System Requirements</h2>
      <ul>
        <li>
          <strong style={{  color: '#0fbf5f' }}>Operating System:</strong>
          <ul>
            <li>Windows 10 or later, macOS (latest versions)</li>
          </ul>
        </li>
        <li>
          <strong style={{  color: '#0fbf5f' }}>Hardware:</strong>
          <ul>
            <li>Minimum 4 GB RAM (8 GB recommended)</li>
            <li>Minimum 2 GB of available disk space</li>
            <li>Graphics card with DirectX 11 or later support</li>
          </ul>
        </li>
        <li>
          <strong style={{  color: '#0fbf5f' }}>Software:</strong>
          <ul>
            <li>.NET Framework 4.5 or later (for Windows)</li>
            <li>Latest version of compatible web browser for online features</li>
          </ul>
        </li>
      </ul>


      <img 
        src='/images/Games/Classic Games Intro Games Teaser AEPpic1.jpg' 
        alt="Windows 11 Logo" 
        style={{
          width: '600px',
          height: '400px',
          display: 'block', // Ensures block-level behavior for centering
          marginLeft: 'auto', 
          marginRight: 'auto' ,
        }} 
      />






      <h2 style={{ paddingLeft: '20px' , color: '#0fbf5f' }}>Conclusion</h2>
      <p style={{ paddingLeft: '5px' }}>
      Teaser game introduction to the classic game AEP offers a mix of nostalgia and modern gameplay. This makes it a great choice for gamers who want to relive the classic experience. 
        With its attractive features and various names. This collection provides hours of entertainment for gamers of all ages.
      </p>



      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}> 
      <Button
      variant="contained"
      color="primary"
      size="large"
      startIcon={<DownloadIcon />}
      onClick={handleDownload}
      style={{
        backgroundColor: '#08c725',
        color: '#fff',
        padding: '10px 20px',
        fontSize: '16px',
        borderRadius: '8px',
        textTransform: 'none',
        marginBottom: '20px',
      }}
    >
  Free DownLoad Games Teaser AEP
    </Button>



</div>

<p  style={{ paddingLeft: '5px' }}>
              If the application is not installed on your Windows system, click the button below to proceed with the installation.
            </p>


<div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}> 

    <Button
      variant="contained"
      color="primary"
      size="large"
      startIcon={<DownloadIcon />}
     
      style={{
        backgroundColor: '#47f40b',
        color: '#fff',
        padding: '10px 20px',
        marginLeft: '5px',
        fontSize: '16px',
        borderRadius: '8px',
        textTransform: 'none',
        marginBottom: '20px',
      }}
    >
  Offical Buy Games Teaser AEP
    </Button>

</div>

 


      </div>
      </div>
      <div className="empty-area"></div>
    </div>
  <Footer />
  </div>
  );
}

export default AepGames;
