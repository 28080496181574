import React from 'react';
import TopNavbar from '../../HeaderAndFooter/TopHeader';
import LogoComponent from '../../HeaderAndFooter/PageLogo';
import SocialMediaSidebar from '../../MenuBar/SocialMediaSidebar';
import MenuBar from '../../MenuBar/MenuBar';
import Footer from '../../HeaderAndFooter/Footer';
import './css/windows10sp1.css';
import { Button } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';



const Windows8= () => {



    const handleDownload = () => {
      // Your download logic here
      window.location.href = 'https://www.microsoft.com/en-us/software-download/windows8';

      // Example link
    };





  return (
    <div class="background-image">
   <TopNavbar />
   <LogoComponent />
   <SocialMediaSidebar />


    <div  className="main-layout">
    <MenuBar />
    </div>

    <div className="container" style={{ marginTop: '50px' }} >
      <div className="text-area">
    <div className='text-mainArea'> 

    <h2 style={{ textAlign: 'center', color: '#095187', marginTop: '10px' }}> Windows 8 Features and Requirements</h2>
      <p style={{ paddingLeft: '5px' }}>
      Released in 2012, Windows 8 marked a significant departure from previous Microsoft operating systems that emphasized touch-centric design and integration between tablets and PCs. It offers a modern user interface. and new features aimed at enhancing the native desktop and mobile experience.
      </p>
      
      <h2 style={{ paddingLeft: '20px' , color: '#095187' }}>Key Features:</h2>
      <ul>
        <li><strong>Start Screen with Live Tiles:</strong> The traditional Start menu was replaced by the Start screen, featuring large, customizable live tiles that update in real-time to show notifications, weather, emails, and more.</li>
        <li><strong>Metro UI (Modern UI):</strong> Designed for touch devices, Windows 8’s Metro UI introduced a full-screen interface optimized for tablets and touchscreens, allowing users to interact with apps using gestures and swipes.</li>
        <li><strong>Charms Bar:</strong> A side menu that could be accessed by swiping or hovering on the screen’s edge, offering quick access to search, sharing, settings, and other system features.</li>
        <li><strong>Microsoft Store:</strong> Windows 8 introduced the Microsoft Store for downloading and updating Metro-style apps, marking the OS’s entry into the app marketplace space.</li>
        <li><strong>Multitasking with Snap Feature:</strong> The Snap feature allowed users to multitask by running two apps side-by-side in a split-screen view.</li>
        <li><strong>Cloud Integration:</strong> Deep integration with Microsoft’s cloud services (OneDrive), allowing users to sync settings, files, and apps across devices.</li>
        <li><strong>Improved Boot Times:</strong> Windows 8 offered faster boot and shutdown times due to better power management and optimizations.</li>
        <li><strong>Touch-Optimized Internet Explorer 10:</strong> Internet Explorer 10 was optimized for touch interaction, providing a more fluid web-browsing experience on touch devices.</li>
        <li><strong>Enhanced Security:</strong> Windows 8 introduced features like Secure Boot and built-in Windows Defender to protect against malware and unauthorized access.</li>
      </ul>

      <img 
        src='/images/operatingsystem/windows8a.jpeg' 
        alt="Windows 11 Logo" 
        style={{
          width: '600px',
          height: '400px',
          display: 'block', // Ensures block-level behavior for centering
          marginLeft: 'auto', 
          marginRight: 'auto' 
        }} />

      
      
      <h2 style={{ paddingLeft: '20px' ,paddingTop: '20px' , color: '#095187' }}>System Requirements:</h2>
      <ul>
        <li><strong>Processor:</strong> 1 GHz or faster with support for PAE, NX, and SSE2.</li>
        <li><strong>RAM:</strong> 1 GB for 32-bit or 2 GB for 64-bit.</li>
        <li><strong>Storage:</strong> 16 GB for 32-bit or 20 GB for 64-bit.</li>
        <li><strong>Graphics Card:</strong> Microsoft DirectX 9 with WDDM driver.</li>
        <li><strong>Display:</strong> Minimum 1024x768 resolution (for running Metro-style apps).</li>
        <li><strong>Touch Support:</strong> A touchscreen is required to take full advantage of the touch features, though it’s not mandatory for desktop use.</li>
      </ul>
      
      <h2 style={{ paddingLeft: '20px' , color: '#095187' }}>Performance and Reception:</h2>
      <p style={{ paddingLeft: '5px' }}>
      Windows 8 is designed to work seamlessly between traditional desktops and tablets. It has faster boot times and better power efficiency. But has received criticism. Especially among users of non-touch devices. For dramatically abandoning the familiar desktop interface. This resulted in the subsequent release of Windows 8.1, which brought back some original elements such as the Start button.
      </p>
      
      <h2 style={{ paddingLeft: '20px' , color: '#095187' }}>Conclusion:</h2>
      <p style={{ paddingLeft: '5px' }}>
      Windows 8 aims to bridge the gap between desktop and mobile devices. It has a modern interface that is better suited to touch screens. Although new features have been introduced, the complexity of the Start menu and reliance on touch functionality has kept many traditional desktop users away.
      </p>

      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
      <Button
      variant="contained"
      color="primary"
      size="large"
      startIcon={<DownloadIcon />}
      onClick={handleDownload}
      style={{
        backgroundColor: '#0078D4',
        color: '#fff',
        padding: '10px 20px',
        fontSize: '16px',
        borderRadius: '8px',
        textTransform: 'none',
      }}
    >
      Download Windows 8
    </Button>


</div>


<p  style={{ paddingLeft: '5px' }}>
              If the application is not installed on your Windows system, click the button below to proceed with the installation.
            </p>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                startIcon={<DownloadIcon />}
               
                style={{
                  backgroundColor: 'green',
                  color: '#fff',
                  padding: '10px 20px',
                  marginLeft: '10px',
                  fontSize: '16px',
                  borderRadius: '8px',
                  textTransform: 'none',
                  marginBottom: '20px',
                }}
              >
                How to download and install 
              </Button>

              </div>




      </div>
      </div>
      <div className="empty-area"></div>
    </div>
  <Footer />
  </div>
  );
}

export default Windows8 ;
