
import React, { useState } from 'react';
import '../assest/MenuBar.css';
import { FiMenu, FiX } from 'react-icons/fi';
import { FaHome, FaDesktop, FaPaintBrush, FaCode, FaBook, FaStar, FaGamepad, FaMusic, FaList } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const MenuBar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="menu-bar">
      <div className="menu-icons" onClick={toggleMenu}>
        {isOpen ? <FiX /> : <FiMenu />}
      </div>

      <ul className={`menu-list ${isOpen ? 'open' : ''}`}>
     
        <li className="menu-item">
          <FaHome className="menu-icon" />
          <Link to="/">Home</Link>
         
        </li>
       
        <li className="menu-item">
          <FaDesktop className="menu-icon" />
          <Link to="/operatingSystem">Operating Systems</Link>
        </li>
        <li className="menu-item">
          <FaPaintBrush className="menu-icon" />
          <Link  to="/graphicDesignpage">Graphic Design</Link>
        </li>
        <li className="menu-item">
          <FaCode className="menu-icon" />
          <Link to="/development">Development</Link>
        </li>
        <li className="menu-item">
          <FaBook className="menu-icon" />
          <Link  to="/education">Education</Link>
        </li>
        <li className="menu-item">
          <FaStar className="menu-icon" />
          <Link  to="/Mostpopular">Most Popular</Link>
        </li>
        <li className="menu-item">
          <FaGamepad className="menu-icon" />
          <Link  to="/games">Games</Link>
        </li>
        <li className="menu-item">
          <FaMusic className="menu-icon" />
          <Link  to="/multimedia">MultiMedia</Link>
        </li>
        <li className="menu-item">
          <FaList className="menu-icon" />
          <Link  to="/categories">AI tools</Link>
        </li>
      </ul>
    </nav>
  );
};

export default MenuBar;
