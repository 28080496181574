import React from 'react';

import { Button } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import TopNavbar from '../../HeaderAndFooter/TopHeader';
import LogoComponent from '../../HeaderAndFooter/PageLogo';
import SocialMediaSidebar from '../../MenuBar/SocialMediaSidebar';
import MenuBar from '../../MenuBar/MenuBar';
import Footer from '../../HeaderAndFooter/Footer';



const PHPRunner24= () => {



    const handleDownload = () => {
      // Your download logic here
      window.location.href = 'https://getintopc.com/softwares/development/phprunner-enterprise-2024-free-download/';


      // Example link
    };

    const handleDownloadbuy = () => {
        // Your download logic here
        window.location.href = 'https://www.phprunner.com';
  
  
        // Example link
      };



  return (
    <div class="background-image">
   <TopNavbar />
   <LogoComponent/>
   <SocialMediaSidebar />


    <div  className="main-layout" >
    <MenuBar />
    </div>

    <div className="container" style={{ marginTop: '50px' }}>
      <div className="text-area">
    <div className='text-mainArea'> 

    <h2 style={{ paddingLeft: '30px' , color: '#ff00b6', marginTop: '10px'  }}>PHPRunner Overview and Free Download</h2>
      <p style={{ paddingLeft: '5px' }}>
      PHPRunner is a powerful tool designed to build web applications quickly and efficiently. It allows users to create web applications with minimal coding. This makes it ideal for developers and those with less technical expertise.
      </p>
      
      <h2 style={{ paddingLeft: '20px' , color: '#ff00b6' }}>Key Features:</h2>
      <ul>
        <li>
          <strong style={{ color: '#ff00b6' }}>User-Friendly Interface:</strong>
          <ul>
            <li>Intuitive drag-and-drop environment for designing applications.</li>
            <li>WYSIWYG (What You See Is What You Get) editor for easy layout customization.</li>
          </ul>
        </li>
        <li>
          <strong style={{ color: '#ff00b6' }}>Database Support:</strong>
          <ul>
            <li>Connects to various databases including MySQL, PostgreSQL, Oracle, and Microsoft SQL Server.</li>
            <li>Supports multiple database connections within a single application.</li>
          </ul>6c620
        </li>
        <li>
          <strong style={{ color: '#ff00b6' }}>Built-in Security:</strong>
          <ul>
            <li>User authentication and authorization features.</li>
            <li>Role-based access control to manage user permissions.</li>
          </ul>
        </li>
        <li>
          <strong style={{ color: '#ff00b6' }}>Responsive Design:</strong>
          <ul>
            <li>Automatically generates mobile-friendly applications.</li>
            <li>Supports various themes and templates for a modern look.</li>
          </ul>
        </li>
        <li>
          <strong style={{ color: '#ff00b6' }}>Advanced Reporting:</strong>
          <ul>
            <li>Tools for creating detailed reports and charts.</li>
            <li>Export options to formats like PDF, Excel, and Word.</li>
          </ul>
        </li>
        <li>
          <strong style={{ color: '#ff00b6' }}>Custom Code Support:</strong>
          <ul>
            <li>Ability to add custom PHP, JavaScript, and HTML for enhanced functionality.</li>
            <li>Hooks for integrating with third-party services.</li>
          </ul>
        </li>
        <li>
          <strong style={{ color: '#ff00b6' }}>Multi-Language Support:</strong>
          <ul>
            <li>Options for creating applications in multiple languages.</li>
            <li>Easy translation management.</li>
          </ul>
        </li>
        <li>
          <strong style={{ color: '#ff00b6' }}>Version Control:</strong>
          <ul>
            <li>Keeps track of changes and allows for rollbacks if needed.</li>
            <li>Supports collaboration among multiple developers.</li>
          </ul>
        </li>
        <li>
          <strong style={{ color: '#ff00b6' }}>API Integration:</strong>
          <ul>
            <li>Built-in options for REST API and other integrations.</li>
            <li>Supports web services for data exchange with other applications.</li>
          </ul>
        </li>
        <li>
          <strong style={{ color: '#ff00b6' }}>Deployment Options:</strong>
          <ul>
            <li>Easy deployment to web servers or cloud platforms.</li>
            <li>One-click publish options to popular hosting services.</li>
          </ul>
        </li>
      </ul>

      <h2 style={{ paddingLeft: '20px' , color: '#ff00b6' }}>System Requirements:</h2>
      <ul>
        <li><strong>Operating System:</strong> Windows (7 or later), Server environments compatible with PHP (IIS, Apache)</li>
        <li><strong>Web Server:</strong> Apache or IIS (with PHP support)</li>
        <li><strong>Database:</strong> MySQL 5.1 or later, Microsoft SQL Server 2008 or later, PostgreSQL 9.0 or later, Oracle 10g or later.</li>
        <li><strong>PHP Version:</strong> PHP 7.2 or later</li>
        <li><strong>RAM:</strong> Minimum 2 GB recommended (4 GB or more for larger applications)</li>
        <li><strong>Disk Space:</strong> At least 200 MB for installation; more depending on application size.</li>
        <li><strong>Browser:</strong> Modern web browser (Chrome, Firefox, Edge) for application testing and use.</li>
      </ul>



      <img 
        src='/images/Development/PHPRunnerpic1.png' 
        alt="Windows 11 Logo" 
        style={{
          width: '600px',
          height: '400px',
          display: 'block', // Ensures block-level behavior for centering
          marginLeft: 'auto', 
          marginRight: 'auto' ,
        }} 
      />

      <h2 style={{ paddingLeft: '20px' , color: '#ff00b6' }}>Conclusion:</h2>
      <p style={{ paddingLeft: '5px' }}>
      PHPRunner is ideal for developers who want to streamline their application development process with features and a straightforward interface. Flexible system requirements make it easy for a variety of application scenarios. Be it for small projects or large enterprise applications.
      </p>


      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}> 
      <Button
      variant="contained"
      color="primary"
      size="large"
      startIcon={<DownloadIcon />}
      onClick={handleDownload}
      style={{
        backgroundColor: '#08c725',
        color: '#fff',
        padding: '10px 20px',
        fontSize: '16px',
        borderRadius: '8px',
        textTransform: 'none',
        marginBottom: '20px',
      }}
    >
  Free DownLoad PHPRunner 2024
    </Button>
</div>
<p  style={{ paddingLeft: '5px' }}>
              If the application is not installed on your Windows system, click the button below to proceed with the installation.
            </p>
<div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}> 

    <Button
      variant="contained"
      color="primary"
      size="large"
      startIcon={<DownloadIcon />}
      onClick={handleDownloadbuy }
      style={{
        backgroundColor: '#0078D4',
        color: '#fff',
        padding: '10px 20px',
        marginLeft: '5px',
        fontSize: '16px',
        borderRadius: '8px',
        textTransform: 'none',
        marginBottom: '20px',
      }}
    >
  Offical Buy PHPRunner 2024
    </Button>

</div>

      </div>
      </div>
      <div className="empty-area"></div>
    </div>
  <Footer />
  </div>
  );
}

export default PHPRunner24 ;
