import React from 'react';

import { Button } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import TopNavbar from '../../HeaderAndFooter/TopHeader';
import LogoComponent from '../../HeaderAndFooter/PageLogo';
import SocialMediaSidebar from '../../MenuBar/SocialMediaSidebar';
import MenuBar from '../../MenuBar/MenuBar';
import Footer from '../../HeaderAndFooter/Footer';



const KidsLearningWord= () => {



    const handleDownload = () => {
      // Your download logic here
      window.location.href = 'https://filehippo.com/download_kids-learning-word-games/';


      // Example link
    };




  return (
    <div class="background-image">
   <TopNavbar />
   <LogoComponent/>
   <SocialMediaSidebar />


    <div  className="main-layout" >
    <MenuBar />
    </div>

    <div className="container" style={{ marginTop: '50px' }}>
      <div className="text-area">
    <div className='text-mainArea'> 

    <h2 style={{ paddingLeft: '30px' , color: '#b60d36', marginTop: '20px'  }}>Overview of Kids Learning Word for Windows</h2>
      <p style={{ paddingLeft: '5px' }}>
        Kids Learning Word for Windows is an educational software designed to help young learners improve their vocabulary, spelling, and reading skills. With interactive activities and engaging content, it provides a fun and effective way for children to enhance their language abilities while enjoying a user-friendly interface.
      </p>
      
      <h2 style={{ paddingLeft: '20px' , color: '#0db688' }}>Key Features</h2>
      <ul>
        <li><strong style={{  color: '#1fe10b' }} >Interactive Learning Activities:</strong> A variety of games and exercises designed to make learning fun and engaging, including puzzles, quizzes, and matching games.</li>
        <li><strong style={{  color: '#1fe10b' }}>Customizable Levels:</strong> Adjust difficulty levels to cater to different age groups and learning paces, ensuring that each child can learn at their own speed.</li>
        <li><strong style={{  color: '#1fe10b' }}>Visual and Audio Support:</strong> Colorful graphics and audio instructions help to maintain children's interest and aid in comprehension.</li>
        <li><strong style={{  color: '#1fe10b' }}>Progress Tracking:</strong> Monitor progress through reports that highlight improvements and areas needing more focus, enabling parents and educators to guide learning effectively.</li>
        <li><strong style={{  color: '#1fe10b' }}>User-Friendly Interface:</strong> Simple navigation designed for children, making it easy for them to use the software independently.</li>
        <li><strong style={{  color: '#1fe10b' }}>Multilingual Support:</strong> Some versions offer multiple language options, allowing children to learn words in different languages.</li>
        <li><strong style={{  color: '#1fe10b' }}>Fun Characters and Themes:</strong> Engaging characters and colorful themes create an enjoyable learning environment, making it appealing to young users.</li>
      </ul>
      
      <h2 style={{ paddingLeft: '20px' , color: '#0db688' }}>System Requirements</h2>
      <p style={{ paddingLeft: '5px' }}>
        While requirements can vary slightly depending on the version, general system specifications for Kids Learning Word on Windows are as follows:
      </p>
      <ul>
        <li><strong style={{  color: '#1fe10b' }}>Operating System:</strong> Windows 10 or later (64-bit)</li>
        <li><strong style={{  color: '#1fe10b' }}>Processor:</strong> Intel Core i3 or equivalent</li>
        <li><strong style={{  color: '#1fe10b' }}>RAM:</strong> At least 4 GB</li>
        <li><strong style={{  color: '#1fe10b' }}>Storage:</strong> Minimum of 2 GB free disk space</li>
        <li><strong style={{  color: '#1fe10b' }}>Graphics:</strong> DirectX 9 compatible graphics card</li>
        <li><strong style={{  color: '#1fe10b' }}>Internet Connection:</strong> Required for updates and online features</li>
      </ul>


      <img 
        src='/images/Games/Kids Learning Word for Windowspic1.jpg' 
        alt="Windows 11 Logo" 
        style={{
          width: '600px',
          height: '400px',
          display: 'block', // Ensures block-level behavior for centering
          marginLeft: 'auto', 
          marginRight: 'auto' ,
        }} 
      />







      <h2 style={{ paddingLeft: '20px' , color: '#0db688' }}>Conclusion</h2>
      <p style={{ paddingLeft: '5px' }}>
        Kids Learning Word for Windows offers a dynamic and enjoyable platform for children to develop essential language skills. With its interactive features, customizable levels, and engaging content, it provides a comprehensive learning experience that adapts to individual needs. This software is an excellent resource for parents and educators seeking to enhance children's literacy in a fun and effective way, making it a valuable addition to any educational toolkit.
      </p>



      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}> 
      <Button
      variant="contained"
      color="primary"
      size="large"
      startIcon={<DownloadIcon />}
      onClick={handleDownload}
      style={{
        backgroundColor: '#08c725',
        color: '#fff',
        padding: '10px 20px',
        fontSize: '16px',
        borderRadius: '8px',
        textTransform: 'none',
      }}
    >
  Free DownLoad Kids Learning Word for Windows
    </Button>
</div>
 
<p  style={{ paddingLeft: '5px' }}>
              If the application is not installed on your Windows system, click the button below to proceed with the installation.
            </p>


<div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}> 

    <Button
      variant="contained"
      color="primary"
      size="large"
      startIcon={<DownloadIcon />}
      
      style={{
        backgroundColor: '#0078D4',
        color: '#fff',
        padding: '10px 20px',
        marginLeft: '5px',
        fontSize: '16px',
        borderRadius: '8px',
        textTransform: 'none',
        marginBottom: '20px',
      }}
    >
  Offical Buy Games Teaser AEP
    </Button>

</div>



      </div>
      </div>
      <div className="empty-area"></div>
    </div>
  <Footer />
  </div>
  );
}

export default KidsLearningWord;
