import React from 'react';
import '../assest/LogoComponent.css'; // Create a CSS file to style the component

const LogoComponent = () => {
  return (
    <div className="logo-container">
     <h1 className="logo-text">
  <span className="logo-e">e</span> <span  className='asy' >ASY   </span> <span className="logo-pc">PC </span>  <span  className='asy' >SOFT </span>
</h1>
      <div className="line"></div>
      <p className="tagline">  The Software Mart  </p>
      <div className="line"></div>
    </div>
  );
};

export default LogoComponent;
