import React from 'react';

import '../css/mainpage.css';
import OperatingSysyemCategoryList from '../Components/OperatingSystem/OperatingSystemCategories';
import TopNavbar from '../HeaderAndFooter/TopHeader';
import LogoComponent from '../HeaderAndFooter/PageLogo';
import MenuBar from '../MenuBar/MenuBar';
import SocialMediaSidebar from '../MenuBar/SocialMediaSidebar';
import Footer from '../HeaderAndFooter/Footer';

const OperatingSystemMainPage= () => {
  return (
    <div class="background-image">
    <TopNavbar/>
    <LogoComponent/>
    <SocialMediaSidebar />
    <div  className="main-layout" style={{ marginTop: '10px' }}>
      <MenuBar />
    </div>

    <div  className="main-layout" style={{ marginTop: '80px' }}>
      <OperatingSysyemCategoryList />
      
    </div>
    <Footer />
  </div>
  );
}

export default OperatingSystemMainPage;