import React, { useState } from "react";
import '../OperatingSystem/css/operatingsysyemCat.css';
import { useNavigate } from "react-router-dom";



// Sample data (You can replace this with dynamic data later)
const categories = [


  {
    id:1,
    image: 'images/GraphicDesign/illustrator24.jpg',
    title: 'Free Download Adobe Illustrator 2024',
    time: '2024-09-11',
    details: 'Adobe Illustrator 2024 offers enhanced AI-powered features such as advanced vector editing. precision tools Faster performance to create complex illustrations and designs. The new update includes seamless collaboration in the cloud. Expanded 3D capabilities and advanced printing tools to make this happen - into professional graphic design solutions.',
  },
  {
    id: 2,
    image: 'images/GraphicDesign/ps22.jpg',
    title: 'Free Download Adobe Photoshop Elements 2024 ',
    time: '2024-09-10',
    details: 'Adobe Photoshop Elements 2022 is a user-friendly photo editing software designed for hobbyists, offering automated tools powered by Adobe Sensei AI to enhance, organize, and create photo projects with ease.It includes features like Guided Edits for step-by-step assistance, one-click transformations, and new artistic effects, making photo editing accessible without needing advanced skills.'
  },
  {
    id: 3,
    image: 'images/GraphicDesign/Photoshop CC 2017.png',
    title: 'Free Download Adobe Photoshop CC 2017 v18 DMG For Mac OS',
    time: '2024-09-04',
    details: 'Adobe Photoshop CC 2017 is a powerful image editing software used for photo retouching, graphic design, and digital art creation. It offers advanced tools for image manipulation, layer-based editing, and supports various file formats for professional-grade results.'
  }
  ,
  {
    id: 4,
    image: 'images/GraphicDesign/CorelDRAW Graphics 2024.png',
    title: 'Free Download CorelDRAW Graphics 2024 ',
    time: '2024-09-04',
    details: 'CorelDRAW Graphics 2024 is a comprehensive vector illustration and graphic design software known for its precision and versatility. It offers advanced tools for layout, typography, and photo editing, catering to professionals in branding, marketing, and creative design.'
  }
  

  ,
  {
    id: 5,
    image: 'images/GraphicDesign/CorelDRAW Graphics 2022.png',
    title: 'Free Download  CorelDRAW Graphics 2022 ',
    time: '2024-09-04',
    details: 'CorelDRAW Graphics 2022 is a robust graphic design software that provides tools for vector illustration, layout, and photo editing. It offers enhanced features for collaboration, creative effects, and efficient workflows, making it ideal for professional designers and illustrators.'
  },



  {
    id: 6,
    image: 'images/GraphicDesign/After Effects2024.png',
    title: 'Free Download Adobe After Effects  ',
    time: '2024-09-06',
    details: 'Adobe After Effects is a powerful motion graphics and visual effects software used for creating animations, compositing, and cinematic effects. It’s widely utilized in video production, offering advanced tools for post-production, motion design, and 3D rendering.'
  },
  {
    id: 7,
    image: 'images/GraphicDesign/clasic.png',
    title: 'Free Download Mossaik Classic Pro 2024 ',
    time: '2024-09-05',
    details: 'Mossaik Classic Pro 2024 is a powerful photo editing tool designed for both casual users and professionals. It offers a clutter-free interface powered by the Pixstream graphics engine, allowing precise control over each pixel. Key features include real-time rendering, support for RAW images, non-destructive edits, and over 50 global adjustments. The software also supports multiple color models (RGB, CMYK, LAB) and advanced tools like AI denoising, HDR rendering, and dynamic histograms.'
  },
  {
    id: 8,
    image: 'images/GraphicDesign/olympiacreateionlogo.png',
    title: 'Free Download Olympia Logo Creation 2024 ',
    time: '2024-09-04',
    details: 'Olympia Logo Creation 2024 is a versatile software tool designed for crafting professional logos with ease. It offers advanced vector tools, customizable templates, and features that allow users to create scalable logos for various formats, from websites to billboards. Ideal for businesses and branding projects, it ensures clarity and sharpness across all sizes.'
  }
  ,
  {
    id: 9,
    image: 'images/GraphicDesign/topizstudio.png',
    title: 'Free Download  Topaz Studio + Texture Effects 2022 ',
    time: '2024-09-04',
    details: 'Topaz Studio + Texture Effects is a versatile image editing software that specializes in adding custom textures and enhancing images with unique visual effects. It offers intuitive tools for layering, masking, and blending, allowing photographers and designers to create artistic transformations with ease.'
  }
  

  ,
  {
    id: 10,
    image: 'images/GraphicDesign/popcards.png',
    title: 'Free Download Pop-Up Card Designer 2024 ',
    time: '2024-09-04',
    details: 'Pop-Up Card Designer 2024 is a creative software that allows users to design and craft 3D pop-up cards from a single sheet of paper. It simplifies the process with real-time 3D visualization, generating flat patterns that can be printed, cut, and folded to create stunning, personalized pop-up cards.'
  }
];





// Component to display individual category information
const CategoryCard = ({ category, onClick }) => {


  return (
    <div className="category-card" onClick={onClick}>
      <img src={category.image} alt={category.title} className="category-image" />
      <div className="category-info">
        <h3>{category.title}</h3>
        <p className="category-time">Added on: {category.time}</p>
        <p>{category.details}</p>
      </div>
    </div>
  );
};





// Main Component to display the list of categories
const GraphicDesignCategoryList = () => {
  const navigate = useNavigate();  // React Router's navigation hook

  const handleCardClick = (categoryId) => {
    if (categoryId === 1) {
      navigate("/AdobeIllustrator1"); // Navigate to the Windows 7 page
    } else if (categoryId === 2) {
      navigate("/AdobePhotoshop24"); // Navigate to the Windows 10 page
    }
    else if (categoryId === 3) {
      navigate("/AdobePhotoshopCC2017"); // Navigate to the Windows 10 page
    }
    else if (categoryId === 4) {
      navigate("/CorelDRAWGraphics24"); // Navigate to the Windows 10 page
    }
    else if (categoryId === 5) {
      navigate("/CorelDRAWGraphics2022"); // Navigate to the Windows 10 page
    }
    else if (categoryId === 6) {
      navigate("/AdobeAfterEffect"); // Navigate to the Windows 10 page
    }
    else if (categoryId === 7) {
      navigate("/MossaikClassicPro"); // Navigate to the Windows 10 page
    }
    
    else if (categoryId === 8) {
      navigate("/OlympiaLogoCreation2024"); // Navigate to the Windows 10 page
    }
    
    
    else if (categoryId === 9) {
      navigate("/TopazStudio"); // Navigate to the Windows 10 page
    }
    
    else if (categoryId === 10) {
      navigate("/PopcardDesigner24"); // Navigate to the Windows 10 page
    }
    
    
  };

  return (
    <div className="Main-category">
    <div className="category-list">
      {categories.map((category) => (
        <CategoryCard key={category.id} category={category} onClick={() => handleCardClick(category.id)} />
      ))}


     {/* {selectedCategoryId === 1 && <Windows7UltimateSP1 />} 
     {selectedCategoryId === 2 && <Windows10sp1 />}  */}
    </div>
   
    </div>
  );
};

export default GraphicDesignCategoryList;




