import React from 'react';
import { Button } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import TopNavbar from '../../HeaderAndFooter/TopHeader';
import LogoComponent from '../../HeaderAndFooter/PageLogo';
import SocialMediaSidebar from '../../MenuBar/SocialMediaSidebar';
import MenuBar from '../../MenuBar/MenuBar';
import Footer from '../../HeaderAndFooter/Footer';



const CorelDRAWGraphics2022= () => {



    const handleDownload = () => {
      // Your download logic here
      window.location.href = 'https://getintopc.com/softwares/graphic-design/coreldraw-graphics-suite-2022-free-download/';


      // Example link
    };





  return (
    <div class="background-image">
   <TopNavbar />
   <LogoComponent/>
   <SocialMediaSidebar />


    <div  className="main-layout" >
    <MenuBar />
    </div>

    <div className="container" style={{ marginTop: '50px' }}>
      <div className="text-area">
    <div className='text-mainArea'> 

    <h2 style={{ paddingLeft: '30px' , color: '#ae19f3', marginTop: '10px'  }}>CorelDRAW Graphics 2022 Overview & Free Download</h2>

    <p style={{ paddingLeft: '5px' }}>
    CorelDRAW Graphics Suite 2022 offers a comprehensive set of professional design tools for vector illustration, layout, photo editing, typography, and collaboration  It includes productivity-boosting enhancements, real-time non-destructive photo editing tools, and creative templates to inspire your designs.With improved performance and a user-friendly interface, CorelDRAW Graphics Suite 2022 is designed to help you create stunning graphics efficiently.
      </p>


      <h2 style={{ paddingLeft: '20px' , color: '#ae19f3' }}>Key Features</h2>
      <ul>
        <li>
          <strong>Improved User Interface:</strong> A modernized and customizable interface for a more intuitive design experience.
        </li>
        <li>
          <strong>AI-Powered Tools:</strong> Enhanced tools like AI-based image upsampling and background removal for faster editing.
        </li>
        <li>
          <strong>Vector Illustration Enhancements:</strong> Upgraded pen and shape tools for more precise vector illustrations, including new shape modes.
        </li>
        <li>
          <strong>Multi-Page Support:</strong> Easier management of multi-page projects for creating brochures, magazines, and more.
        </li>
        <li>
          <strong>Advanced Typography Options:</strong> New text formatting features and improved control for typographic designs.
        </li>
        <li>
          <strong>Collaboration Tools:</strong> Enhanced cloud collaboration capabilities for real-time sharing and editing among team members.
        </li>
        <li>
          <strong>Customizable Workspaces:</strong> Users can create personalized workspaces to fit their unique workflows and preferences.
        </li>
        <li>
          <strong>Enhanced File Compatibility:</strong> Improved support for a wide variety of file formats, including better handling of Adobe files.
        </li>
        <li>
          <strong>New Patterns and Textures:</strong> Access to a rich library of patterns and textures for creative design options.
        </li>
        <li>
          <strong>Performance Improvements:</strong> Optimized performance for handling larger files and complex designs efficiently.
        </li>
      </ul>

      <h2 style={{ paddingLeft: '20px' , color: '#ae19f3' }}>System Requirements</h2>
      <h3 style={{ paddingLeft: '20px' , color: '#ae19f3' }}>Minimum Requirements:</h3>
      <ul>
        <li>Operating System: Windows 10 (64-bit) or later; macOS 10.15 (Catalina) or later.</li>
        <li>Processor: Intel or AMD multi-core processor with 64-bit support.</li>
        <li>RAM: 4 GB (8 GB recommended).</li>
        <li>Graphics Card: DirectX 11 compatible graphics card with 1 GB VRAM (2 GB recommended).</li>
        <li>Display: 1280 x 720 resolution (1920 x 1080 or greater recommended).</li>
        <li>Hard Disk Space: 4 GB of available hard-disk space for installation.</li>
      </ul>

      <h3 style={{ paddingLeft: '20px' , color: '#ae19f3' }}>Recommended Requirements:</h3>
      <ul>
        <li>Operating System: Windows 11 or latest macOS version.</li>
        <li>Processor: Intel or AMD multi-core processor (2 GHz or faster).</li>
        <li>RAM: 8 GB or more.</li>
        <li>Graphics Card: DirectX 12 compatible graphics card with 2 GB VRAM or greater.</li>
        <li>Display: 1920 x 1080 resolution or greater.</li>
        <li>Hard Disk Space: SSD recommended for improved performance.</li>
      </ul>


      <img 
        src='/images/GraphicDesign/CorelDRAW Graphicspc1.jpg' 
        alt="Windows 11 Logo" 
        style={{
          width: '600px',
          height: '400px',
          display: 'block', // Ensures block-level behavior for centering
          marginLeft: 'auto', 
          marginRight: 'auto' ,
        }} 
      />



      <h2 style={{ paddingLeft: '20px' , color: '#ae19f3' }}>Conclusion</h2>
      <p style={{ paddingLeft: '5px' }}>
        CorelDRAW Graphics 2022 offers a robust set of features designed to enhance the workflow of graphic designers. 
        With AI-powered tools, advanced typography, and improved collaboration capabilities, it caters to both professionals and enthusiasts alike. 
        Make sure your system meets the required specifications for optimal performance.
      </p>



      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>   
      <Button
      variant="contained"
      color="primary"
      size="large"
      startIcon={<DownloadIcon />}
      onClick={handleDownload}
      style={{
        backgroundColor: '#0078D4',
        color: '#fff',
        padding: '10px 20px',
        fontSize: '16px',
        borderRadius: '8px',
        textTransform: 'none',
        marginBottom: '20px',
      }}
    >
  Free DownLoad CorelDRAW Graphics 2022
    </Button>

</div>


<p  style={{ paddingLeft: '5px' }}>
              If the application is not installed on your Windows system, click the button below to proceed with the installation.
            </p>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                startIcon={<DownloadIcon />}
               
                style={{
                  backgroundColor: 'green',
                  color: '#fff',
                  padding: '10px 20px',
                  marginLeft: '10px',
                  fontSize: '16px',
                  borderRadius: '8px',
                  textTransform: 'none',
                  marginBottom: '20px',
                }}
              >
                How to download and install 
              </Button>

              </div>







      </div>
      </div>
      <div className="empty-area"></div>
    </div>
  <Footer />
  </div>
  );
}

export default CorelDRAWGraphics2022;
