



import React, { useState } from 'react';
import '../css/footerMenu.css';
import { FiMenu, FiX } from 'react-icons/fi';  // Using react-icons for hamburger and close icons
import { Link } from 'react-router-dom';
const FooterMenuBar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="menu-barr">
      <div className="menu-iconn" onClick={toggleMenu}>
        {isOpen ? <FiX /> : <FiMenu />}
      </div>
      <ul className={`menu-listt ${isOpen ? 'open' : ''}`}>
        <li className="menu-itemm"><Link to="/">Home</Link></li>
        <li className="menu-itemm"><Link to="/about">About us</Link></li>
        <li className="menu-itemm"><Link to="/services">Our Mission</Link></li>
        <li className="menu-itemm"><Link to="/development">Development</Link></li>
        <li className="menu-itemm"><Link to="/education">Education</Link></li>
        <li className="menu-itemm"><Link to="/Mostpopular">Most Popular</Link></li>
        <li className="menu-itemm"><Link to="/contact">Contact Us</Link></li>
      </ul>
    </nav>
  );
};

export default FooterMenuBar;
