import React from 'react';

import { Button } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import TopNavbar from '../../HeaderAndFooter/TopHeader';
import LogoComponent from '../../HeaderAndFooter/PageLogo';
import SocialMediaSidebar from '../../MenuBar/SocialMediaSidebar';
import MenuBar from '../../MenuBar/MenuBar';
import Footer from '../../HeaderAndFooter/Footer';



const MossaikClassicPro= () => {



    const handleDownload = () => {
      // Your download logic here
      window.location.href = 'https://getintopc.com/softwares/graphic-design/mossaik-classic-pro-2024-free-download/';


      // Example link
    };


  return (
    <div class="background-image">
   <TopNavbar />
   <LogoComponent/>
   <SocialMediaSidebar />


    <div  className="main-layout" >
    <MenuBar />
    </div>

    <div className="container" style={{ marginTop: '50px' }}>
      <div className="text-area">
    <div className='text-mainArea'> 

    <h2  style={{ paddingLeft: '30px' , color: '#eeae04', marginTop: '10px'  }}>Mossaik Classic Pro Overview & Free Download</h2>


<p style={{ paddingLeft: '5px' }}>
Mossaik Classic Pro is advanced photo editing software designed for creative professionals. It offers a variety of features. Including more than 50 global adjustments, 32 customizable presets, and powerful RAW image processing yen The ideal tool for photographers and digital artists, it happens.

</p>
<h2 style={{ paddingLeft: '20px' , color: '#eeae04' }}>Key Features</h2>
<ul>
  <li>
    <strong>Advanced Mosaic Creation:</strong> Allows users to create stunning mosaics from images, offering various customization options.
  </li>
  <li>
    <strong>High-Quality Rendering:</strong> Generates high-resolution mosaics suitable for print and digital displays, ensuring detailed output.
  </li>
  <li>
    <strong>Customizable Tile Options:</strong> Users can choose from different tile shapes, sizes, and colors, enabling unique artistic expressions.
  </li>
  <li>
    <strong>Image Manipulation Tools:</strong> Integrated tools for editing source images before creating mosaics, including cropping, filtering, and adjusting colors.
  </li>
  <li>
    <strong>Batch Processing:</strong> Supports batch processing for creating multiple mosaics simultaneously, saving time for large projects.
  </li>
  <li>
    <strong>User-Friendly Interface:</strong> Intuitive interface that makes it easy for both beginners and experienced users to navigate and utilize the software effectively.
  </li>
  <li>
    <strong>Export Options:</strong> Various export formats available, allowing users to save their creations in formats suitable for web, print, or further editing.
  </li>
  <li>
    <strong>Collaboration Features:</strong> Tools for sharing and collaborating on projects with team members, enhancing teamwork and creativity.
  </li>
</ul>

<h2 style={{ paddingLeft: '20px' , color: '#eeae04' }}>System Requirements</h2>
<h3 style={{ paddingLeft: '20px' , color: '#eeae04' }}>Minimum Requirements:</h3>
<ul>
  <li>Operating System: Windows 10 or later; macOS 10.14 (Mojave) or later.</li>
  <li>Processor: Multi-core processor (Intel or AMD).</li>
  <li>RAM: 4 GB (8 GB recommended).</li>
  <li>Graphics Card: GPU with OpenGL support.</li>
  <li>Display: 1280 x 800 resolution or higher.</li>
  <li>Hard Disk Space: 2 GB of available hard-disk space for installation.</li>
</ul>

<h3 style={{ paddingLeft: '20px' , color: '#eeae04' }}>Recommended Requirements:</h3>
<ul>
  <li>Operating System: Windows 11 or latest macOS version.</li>
  <li>Processor: Multi-core processor (3.0 GHz or faster).</li>
  <li>RAM: 8 GB or more.</li>
  <li>Graphics Card: Dedicated graphics card with 2 GB of VRAM or more.</li>
  <li>Display: 1920 x 1080 resolution or greater.</li>
  <li>Hard Disk Space: SSD recommended for improved performance.</li>
</ul>

<img 
        src='/images/GraphicDesign/Mossaik Classic Propic1.png' 
        alt="Windows 11 Logo" 
        style={{
          width: '600px',
          height: '400px',
          display: 'block', // Ensures block-level behavior for centering
          marginLeft: 'auto', 
          marginRight: 'auto' ,
        }} 
      />

<h2 style={{ paddingLeft: '20px' , color: '#eeae04' }}>Conclusion</h2>
<p style={{ paddingLeft: '5px' }}>
Mossaik Classic Pro is a powerful tool for creating mosaics. It offers a wide range of features designed for creative professionals and hobbyists alike. 
  Ideal for art projects with advanced customization options and high quality results. 
  Make sure your system meets the necessary requirements for the best experience.
</p>


<div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}> 

      <Button
      variant="contained"
      color="primary"
      size="large"
      startIcon={<DownloadIcon />}
      onClick={handleDownload}
      style={{
        backgroundColor: '#0078D4',
        color: '#fff',
        padding: '10px 20px',
        fontSize: '16px',
        borderRadius: '8px',
        textTransform: 'none',
        marginBottom: '20px',
      }}
    >
  Free DownLoad Mossaik Classic Pro 2024
    </Button>

</div>


<p  style={{ paddingLeft: '5px' }}>
              If the application is not installed on your Windows system, click the button below to proceed with the installation.
            </p>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                startIcon={<DownloadIcon />}
               
                style={{
                  backgroundColor: 'green',
                  color: '#fff',
                  padding: '10px 20px',
                  marginLeft: '10px',
                  fontSize: '16px',
                  borderRadius: '8px',
                  textTransform: 'none',
                  marginBottom: '20px',
                }}
              >
                How to download and install 
              </Button>

              </div>







      </div>
      </div>
      <div className="empty-area"></div>
    </div>
  <Footer />
  </div>
  );
}

export default MossaikClassicPro ;
