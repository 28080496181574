import React from 'react';
import { Button } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import TopNavbar from '../HeaderAndFooter/TopHeader';
import LogoComponent from '../HeaderAndFooter/PageLogo';
import SocialMediaSidebar from '../MenuBar/SocialMediaSidebar';
import MenuBar from '../MenuBar/MenuBar';
import Footer from '../HeaderAndFooter/Footer';
import ReactPlayer from "react-player";



const GoogleChrome1= () => {



    const handleDownload = () => {
      // Your download logic here
      // window.location.href = 'https://www.google.com/chrome/';

      window.location.href = 'SoftwareHere/homesoft/_Easypcsoft_ChromeSetup.zip';
    };

    // const handleyoutubegooglechorm = () => {
     

    //   window.location.href = 'https://youtu.be/hrjJ-Ape0WE';
    // };





    




  return (
    <div class="background-image">
   <TopNavbar/>
   <LogoComponent />
   <SocialMediaSidebar />


    <div  className="main-layout" >
    <MenuBar />
    </div>

    <div className="container" style={{ marginTop: '50px' }}>
      <div className="text-area">
    <div className='text-mainArea'> 

    <h2 style={{ textAlign: 'center', color: '#f05a04', marginTop: '20px' }}>Overview of Google Chrome</h2>
      <p style={{ paddingLeft: '5px' }}>
      Google Chrome is a fast, secure, and user-friendly web browser developed by Google. It has been created to provide a seamless browsing experience with various features that focus on enhancement of productivity as well as security.
      </p>
      
      <h2 style={{ paddingLeft: '20px', color: '#f05a04' }} >Key Features</h2>
      <ul>
        <li><strong>Speed:</strong>Chrome has great startup time, and it opens pages in just a matter of seconds. Browsing will thus be quite fluid.</li>
        <li><strong>User-Friendly Interface:</strong> Navigation is not a hassle; it makes sure that you concentrate on content, not on finding your way.</li>
        <li><strong>Omnibox:</strong> You can search right from the address bar. As you start typing in the URL bar, it shows suggestions and results.</li>
        <li><strong>Extensions and Apps:</strong> Chrome Web Store has plenty of extensions and applications to extend functionality from ad blockers to productivity tools.</li>
        <li><strong>Sync Across Devices:</strong> Sign in with your Google account to sync bookmarks, history, and settings across all your devices.</li>
        <li><strong>Security Features:</strong> Chrome has inbuilt protection against phishing and malware. It also provides automatic updates that ensure the latest security patches.</li>
        <li><strong>Incognito Mode:</strong> Browse privately, without saving your browsing history or cookies.</li>
        <li><strong>Tab Management:</strong> Tab grouping, pinning, and the restoration of closed tabs improve organization and efficiency.</li>
        <li><strong>Built-in PDF Viewer:</strong> View and print PDFs from the browser window without any added software.</li>
        <li><strong>Developer Tools:</strong> A rich collection of developer tools for debugging, optimizing websites as well as other related resources consisting of console and network monitor and performance analysis.</li>
      </ul>

      <h2 style={{ paddingLeft: '20px', color: '#f05a04' }}>System Requirements</h2>
      <ul>
        <li><strong>Operating System:</strong> Windows 10, Windows 8/8.1, Windows 7, Linux, or Chrome OS</li>
        <li><strong>Processor:</strong> Intel Pentium 4 or later</li>
        <li><strong>RAM:</strong> Minimum of 2 GB (4 GB recommended)</li>
        <li><strong>Free Disk Space:</strong> At least 350 MB for installation</li>
        <li><strong>Display:</strong> 1024 x 768 resolution or higher</li>
        <li><strong>Internet Connection:</strong> Required for installation and updates</li>
      </ul>


      <img 
        src='/images/MostP/FreedownloadChrome.jpg' 
        alt="window11 logo" 
        style={{
          width: '600px',
          height: '400px',
          display: 'block', // Ensures block-level behavior for centering
          marginLeft: 'auto', 
          marginRight: 'auto' 
        }}
        
      />



      <h2 style={{ paddingLeft: '20px',  paddingTop: '20px', color: '#f05a04' }}>Conclusion</h2>
      <p  style={{ paddingLeft: '5px' }}>
      Google Chrome is a powerful browser that combines speed, simplicity, and security. It is good for casual users as well as professionals because of its extensive features and customization options. Whether you want a fast browsing experience or need advanced developer tools, Chrome has something to offer for everyone.
      </p>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
      <Button
      variant="contained"
      color="primary"
      size="large"
      startIcon={<DownloadIcon />}
      onClick={handleDownload}
      style={{
        backgroundColor: '#0078D4',
        color: '#fff',
        padding: '10px 20px',
        fontSize: '16px',
        borderRadius: '8px',
        textTransform: 'none',
        marginBottom: '10px',
      }}
    >
      GoogleChrome for Windows
    </Button>


</div>

<p style={{ paddingLeft: '5px' }}>
              If the application is not installed on your Windows system, click the button below to proceed with the installation.
            </p>
            {/* <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                startIcon={<DownloadIcon />}
               
                onClick={handleyoutubegooglechorm}
                
                style={{
                  backgroundColor: 'green',
                  color: '#fff',
                  padding: '10px 20px',
                  marginLeft: '10px',
                  fontSize: '16px',
                  borderRadius: '8px',
                  textTransform: 'none',
                  marginBottom: '20px',
                }}
              >
                How to download and install 
              </Button>
              </div> */}


              <div >
      <h2 style={{ paddingTop: '20px' , paddingLeft: '20px' ,  color: '#4f4bff' }}>  How to download and install Check the below video </h2>
      <ReactPlayer
      style={{ paddingTop: '20px'  }}
        url="https://youtu.be/hrjJ-Ape0WE"
        controls={true} // Show controls for the player
        width="100%"    // Set the width of the player
        height="500px"  // Set the height of the player
      />
    </div>







      </div>
      </div>
      <div className="empty-area"></div>

      {/* google add sence adds here */}


      
    </div>
  <Footer />
  </div>
  );
}

export default GoogleChrome1 ;
