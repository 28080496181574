import React from 'react';

import { Button } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import TopNavbar from '../../HeaderAndFooter/TopHeader';
import LogoComponent from '../../HeaderAndFooter/PageLogo';
import SocialMediaSidebar from '../../MenuBar/SocialMediaSidebar';
import MenuBar from '../../MenuBar/MenuBar';
import Footer from '../../HeaderAndFooter/Footer';



const AdobePhotoshopCC2017= () => {



    const handleDownload = () => {
      // Your download logic here
      window.location.href = 'https://getintopc.com/softwares/graphic-design/download-adobe-photoshop-cc-2017-v18-dmg-for-mac-os-1565397/';


      // Example link
    };





  return (
    <div class="background-image">
   <TopNavbar />
   <LogoComponent/>
   <SocialMediaSidebar />


    <div  className="main-layout" >
    <MenuBar />
    </div>

    <div className="container" style={{ marginTop: '50px' }}>
      <div className="text-area">
    <div className='text-mainArea'> 

    <h2 style={{ paddingLeft: '30px' , color: '#054b6a', marginTop: '10px'  }}>Adobe Photoshop CC 2017 Overview for macOS</h2>


    <p style={{ paddingLeft: '5px' }}>
    Adobe Photoshop CC 2017 is a powerful graphic design and image editing software. It is known for its wide range of features and capabilities. Contains work space New "Select and Mask" for more accurate selection. Improved "Content Aware" tools. and performance improvements, Photoshop CC 2017 offers a more user-friendly interface. And better integration with other Adobe Creative Cloud applications allows it to help photographers. It has become a popular tool for designers and digital artists.
      </p>
      <h2 style={{ paddingLeft: '20px' , color: '#054b6a' }}>Key Features</h2>
      <ul>
        <li>
          <strong style={{  color: '#054b6a' }}>Improved User Interface:</strong> Streamlined workspace with customizable panels and improved performance.
        </li>
        <li>
          <strong style={{  color: '#054b6a' }}>Enhanced Selection Tools:</strong> Better selection capabilities with tools like the Select Subject feature that uses Adobe Sensei for automatic subject selection.
        </li>
        <li>
          <strong style={{  color: '#054b6a' }}>Artboards:</strong> Allows designers to create multiple layouts for different devices within a single document.
        </li>
        <li>
          <strong style={{  color: '#054b6a' }}>Responsive Design Tools:</strong> Features like the new pattern-making tool to create seamless patterns easily.
        </li>
        <li>
          <strong style={{  color: '#054b6a' }}>Curvature Pen Tool:</strong> Simplified path creation for more intuitive vector drawing.
        </li>
        <li>
          <strong style={{  color: '#054b6a' }}>Updated Brush and Painting Features:</strong> Enhanced brush performance and new brush options for more creative freedom.
        </li>
        <li>
          <strong style={{  color: '#054b6a' }}> Content-Aware Features:</strong> Improved Content-Aware Fill and Move tools for better content-aware editing.
        </li>
        <li>
          <strong style={{  color: '#054b6a' }}>3D Features:</strong> Tools for creating and editing 3D models directly within the application.
        </li>
        <li>
          <strong style={{  color: '#054b6a' }}>Camera Raw Integration:</strong> Enhanced Camera Raw capabilities for better image editing directly from the raw files.
        </li>
        <li>
          <strong style={{  color: '#054b6a' }}>Performance Improvements:</strong> Overall speed enhancements for better responsiveness and stability.
        </li>
      </ul>

      <h2 style={{ paddingLeft: '20px' , color: '#054b6a' }}>System Requirements</h2>
      <h3 style={{ paddingLeft: '20px' , color: '#054b6a' }}>Minimum Requirements:</h3>
      <ul>
        <li>Operating System: macOS 10.10 (Yosemite) or later.</li>
        <li>Processor: Intel multi-core processor with 64-bit support.</li>
        <li>RAM: 2 GB or more (8 GB recommended).</li>
        <li>Graphics Card: GPU with Metal support; 512 MB of VRAM (1 GB recommended).</li>
        <li>Display: 1280 x 800 display (1920 x 1080 or greater recommended).</li>
        <li>Hard Disk Space: 3.1 GB of available hard-disk space for installation; additional space required for downloads.</li>
      </ul>

      <h3 style={{ paddingLeft: '20px' , color: '#054b6a' }}>Recommended Requirements:</h3>
      <ul>
        <li>Operating System: macOS 10.12 (Sierra) or later.</li>
        <li>RAM: 8 GB or more.</li>
        <li>Graphics Card: 1 GB of VRAM or greater, with OpenGL 2.0 support.</li>
        <li>Display: 1920 x 1080 or greater.</li>
        <li>Hard Disk Space: SSD recommended for improved performance.</li>
      </ul>



      <img 
        src='/images/GraphicDesign/adobephoto2017cc.jpg' 
        alt="Windows 11 Logo" 
        style={{
          width: '500px',
          height: '300px',
          display: 'block', // Ensures block-level behavior for centering
          marginLeft: 'auto', 
          marginRight: 'auto' ,
        }} 
      />






      <h2 style={{ paddingLeft: '20px' , color: '#054b6a' }}>Conclusion</h2>
      <p style={{ paddingLeft: '5px' }}>
      Adobe Photoshop CC 2017 v18 includes several improvements and features aimed at improving the user experience and expanding creative possibilities. 
        Ideal for professionals and enthusiasts who want powerful tools for editing images. graphic design and digital art 
        If you are considering using Make sure your Mac meets the required system requirements for optimal performance.
      </p>

      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}> 
      <Button
      variant="contained"
      color="primary"
      size="large"
      startIcon={<DownloadIcon />}
      onClick={handleDownload}
      style={{
        backgroundColor: '#0078D4',
        color: '#fff',
        padding: '10px 20px',
        fontSize: '16px',
        borderRadius: '8px',
        textTransform: 'none',
        marginBottom: '20px',
      
      }}
    >
  Free DownLoad Adobe Photoshop CC 2017
    </Button>

</div>



<p  style={{ paddingLeft: '5px' }}>
              If the application is not installed on your Windows system, click the button below to proceed with the installation.
            </p>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                startIcon={<DownloadIcon />}
               
                style={{
                  backgroundColor: 'green',
                  color: '#fff',
                  padding: '10px 20px',
                  marginLeft: '10px',
                  fontSize: '16px',
                  borderRadius: '8px',
                  textTransform: 'none',
                  marginBottom: '20px',
                }}
              >
                How to download and install 
              </Button>

              </div>




      </div>
      </div>
      <div className="empty-area"></div>
    </div>
  <Footer />
  </div>
  );
}

export default AdobePhotoshopCC2017 ;
