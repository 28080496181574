import React from 'react';

import { Button } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import TopNavbar from '../../HeaderAndFooter/TopHeader';
import LogoComponent from '../../HeaderAndFooter/PageLogo';
import SocialMediaSidebar from '../../MenuBar/SocialMediaSidebar';
import MenuBar from '../../MenuBar/MenuBar';
import Footer from '../../HeaderAndFooter/Footer';



const PopcardDesigner24= () => {



    const handleDownload = () => {
      // Your download logic here
      window.location.href = 'https://getintopc.com/softwares/graphic-design/pop-up-card-designer-2024-free-download/';


      // Example link
    };


  return (
    <div class="background-image">
   <TopNavbar />
   <LogoComponent/>
   <SocialMediaSidebar />


    <div  className="main-layout" >
    <MenuBar />
    </div>

    <div className="container" style={{ marginTop: '50px' }}>
      <div className="text-area">
    <div className='text-mainArea'> 

    <h2  style={{ paddingLeft: '30px' , color: '#fc01cb', marginTop: '10px'  }} >Pop-Up Card Designer Overview</h2>

      <h2 style={{ paddingLeft: '20px' , color: '#fc01cb' }}>Key Features</h2>
      <ul>
        <li>
          <strong style={{ color: '#fc01cb' }}>User-Friendly Interface:</strong> Intuitive design that simplifies the creation of pop-up cards, making it accessible for both beginners and experienced users.
        </li>
        <li>
          <strong style={{ color: '#fc01cb' }}>Template Library:</strong> A variety of pre-designed templates to kickstart your projects, allowing for quick and easy customization.
        </li>
        <li>
          <strong style={{ color: '#fc01cb' }}>Customizable Elements:</strong> Modify shapes, sizes, and designs of pop-up elements to create unique and personalized cards.
        </li>
        <li>
          <strong style={{ color: '#fc01cb' }}>3D Preview:</strong> Real-time 3D visualization of your designs to see how the card will look when opened.
        </li>
        <li>
          <strong style={{ color: '#fc01cb' }}>Export Options:</strong> Multiple formats available for exporting designs, including PDF and SVG, suitable for printing or sharing online.
        </li>
        <li>
          <strong style={{ color: '#fc01cb' }}>Design Tools:</strong> Comprehensive tools for drawing, adding text, and incorporating graphics into your cards.
        </li>
        <li>
          <strong style={{ color: '#fc01cb' }}> Layer Management:</strong> Organize your designs with layer management features, making it easier to edit and manipulate various elements.
        </li>
        <li>
          <strong style={{ color: '#fc01cb' }}>Collaboration Features:</strong> Share your designs with others for feedback or collaborative editing, enhancing teamwork and creativity.
        </li>
        <li>
          <strong style={{ color: '#fc01cb' }}>Print-Ready Designs:</strong> Ensure that your pop-up cards are formatted correctly for high-quality printing.
        </li>
        <li>
          <strong style={{ color: '#fc01cb' }}>Learning Resources:</strong> Access to tutorials and guides to help you master the software and improve your design skills.
        </li>
      </ul>

      <h2 style={{ paddingLeft: '20px' , color: '#fc01cb' }}>System Requirements</h2>
      <h3 style={{ paddingLeft: '20px' , color: '#fc01cb' }}>Minimum Requirements:</h3>
      <ul>
        <li>Operating System: Windows 10 or later; macOS 10.14 (Mojave) or later.</li>
        <li>Processor: Multi-core processor (Intel or AMD).</li>
        <li>RAM: 4 GB (8 GB recommended).</li>
        <li>Graphics Card: GPU with OpenGL support.</li>
        <li>Display: 1280 x 800 resolution or higher.</li>
        <li>Hard Disk Space: 2 GB of available hard-disk space for installation.</li>
      </ul>

      <h3 style={{ paddingLeft: '20px' , color: '#fc01cb' }}>Recommended Requirements:</h3>
      <ul>
        <li>Operating System: Windows 11 or latest macOS version.</li>
        <li>Processor: Multi-core processor (3.0 GHz or faster).</li>
        <li>RAM: 8 GB or more.</li>
        <li>Graphics Card: Dedicated graphics card with 2 GB of VRAM or more.</li>
        <li>Display: 1920 x 1080 resolution or greater.</li>
        <li>Hard Disk Space: SSD recommended for improved performance.</li>
      </ul>


      <img 
        src='/images/GraphicDesign/Before-After-Topaz-Studio-2.jpg' 
        alt="Windows 11 Logo" 
        style={{
          width: '600px',
          height: '400px',
          display: 'block', // Ensures block-level behavior for centering
          marginLeft: 'auto', 
          marginRight: 'auto' ,
        }} 
      />




      <h2 style={{ paddingLeft: '20px' , color: '#fc01cb' }}>Conclusion</h2>
      <p style={{ paddingLeft: '5px' }}>
        Pop-Up Card Designer is a versatile tool for creating intricate and personalized pop-up cards. 
        With its user-friendly interface and extensive features, it caters to both casual crafters and serious designers. 
        Ensure your system meets the required specifications for optimal performance.
      </p>
 

      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}> 

      <Button
      variant="contained"
      color="primary"
      size="large"
      startIcon={<DownloadIcon />}
      onClick={handleDownload}
      style={{
        backgroundColor: '#0078D4',
        color: '#fff',
        padding: '10px 20px',
        fontSize: '16px',
        borderRadius: '8px',
        textTransform: 'none',
        marginBottom: '20px',
      }}
    >
  Free DownLoad Pop-Up Card Designe 2024
    </Button>

</div>



<p  style={{ paddingLeft: '5px' }}>
              If the application is not installed on your Windows system, click the button below to proceed with the installation.
            </p>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                startIcon={<DownloadIcon />}
               
                style={{
                  backgroundColor: 'green',
                  color: '#fff',
                  padding: '10px 20px',
                  marginLeft: '10px',
                  fontSize: '16px',
                  borderRadius: '8px',
                  textTransform: 'none',
                  marginBottom: '20px',
                }}
              >
                How to download and install 
              </Button>

              </div>




      </div>
      </div>
      <div className="empty-area"></div>
    </div>
  <Footer />
  </div>
  );
}

export default PopcardDesigner24 ;
