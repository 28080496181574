import React from 'react';

import { Button } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import TopNavbar from '../../HeaderAndFooter/TopHeader';
import LogoComponent from '../../HeaderAndFooter/PageLogo';
import SocialMediaSidebar from '../../MenuBar/SocialMediaSidebar';
import MenuBar from '../../MenuBar/MenuBar';
import Footer from '../../HeaderAndFooter/Footer';



const SonicGames= () => {



    const handleDownload = () => {
      // Your download logic here
      window.location.href = 'https://filehippo.com/download_sonic-games/';


      // Example link
    };


   



  return (
    <div class="background-image">
   <TopNavbar />
   <LogoComponent/>
   <SocialMediaSidebar />


    <div  className="main-layout" >
    <MenuBar />
    </div>

    <div className="container" style={{ marginTop: '50px' }}>
      <div className="text-area">
    <div className='text-mainArea'> 

    <h2   style={{ paddingLeft: '30px' , color: '#b46808', marginTop: '20px'  }}>Overview of Sonic Games for Windows</h2>
      <p style={{ paddingLeft: '5px' }} >
        Sonic Games for Windows encompasses a variety of titles featuring Sonic the Hedgehog, Sega's iconic mascot. These games offer fast-paced platforming experiences, blending speed, action, and adventure. Available on Windows, players can enjoy classic and modern Sonic titles with enhanced graphics and gameplay mechanics.
      </p>
      
      <h2 style={{ paddingLeft: '20px' , color: '#97e209' }}>Key Features</h2>
      <ul>
        <li><strong style={{  color: '#0b6eae' }}>Variety of Titles:</strong> A rich library of Sonic games, including classic releases like "Sonic the Hedgehog" and newer entries like "Sonic Mania" and "Sonic Forces."</li>
        <li><strong style={{  color: '#0b6eae' }}>Fast-Paced Gameplay:</strong> Experience high-speed platforming with responsive controls, allowing players to navigate intricate levels quickly.</li>
        <li><strong style={{  color: '#0b6eae' }}>Diverse Environments:</strong> Each game features unique levels and settings, from lush green hills to futuristic cities, providing a visually engaging experience.</li>
        <li><strong style={{  color: '#0b6eae' }}>Character Abilities:</strong> Play as various characters, each with unique abilities, such as Sonic's speed, Tails' flight, and Knuckles' climbing.</li>
        <li><strong style={{  color: '#0b6eae' }}>Multiplayer Options:</strong> Many titles include multiplayer modes, allowing friends to race or compete against each other.</li>
        <li><strong style={{  color: '#0b6eae' }}>Achievements and Unlockables:</strong> Players can earn achievements and unlock new content by completing challenges and exploring levels.</li>
        <li><strong style={{  color: '#0b6eae' }}>Mod Support:</strong> Some games in the series support mods, allowing players to customize their experiences further.</li>
      </ul>
      
      <h2 style={{ paddingLeft: '20px' , color: '#97e209' }} >System Requirements</h2>
      <p style={{ paddingLeft: '5px' }}>
        While requirements may vary depending on the specific game, here are general system requirements for Sonic games on Windows:
      </p>
      <ul>
        <li><strong style={{  color: '#0b6eae' }} >Operating System:</strong> Windows 10 (64-bit) or later</li>
        <li><strong style={{  color: '#0b6eae' }}>Processor:</strong> Intel Core i3 or equivalent</li>
        <li><strong style={{  color: '#0b6eae' }}>RAM:</strong> At least 4 GB</li>
        <li><strong style={{  color: '#0b6eae' }}>Storage:</strong> Minimum of 5 GB free disk space</li>
        <li><strong style={{  color: '#0b6eae' }}>Graphics:</strong> NVIDIA GeForce GTX 660 or equivalent</li>
        <li><strong style={{  color: '#0b6eae' }}>DirectX:</strong> Version 11</li>
        <li><strong style={{  color: '#0b6eae' }}>Internet Connection:</strong> Required for multiplayer features and online content</li>
      </ul>

      <img 
        src='/images/Games/Sonic Games for Windowspic.avif' 
        alt="Windows 11 Logo" 
        style={{
          width: '600px',
          height: '400px',
          display: 'block', // Ensures block-level behavior for centering
          marginLeft: 'auto', 
          marginRight: 'auto' ,
        }} 
      />




      <h2 style={{ paddingLeft: '20px' , color: '#1fa28a' }}>Conclusion</h2>
      <p style={{ paddingLeft: '5px' }}>
        Sonic Games for Windows provide an exciting and nostalgic gaming experience for fans of all ages. With a diverse array of titles, fast-paced gameplay, and engaging environments, Sonic continues to be a beloved character in the gaming world. The combination of classic and modern games ensures that there’s something for everyone, making it a great addition to any gaming library. Whether you're a long-time fan or new to the series, Sonic Games on Windows offer hours of fun and adventure.
      </p>


      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}> 
      <Button
      variant="contained"
      color="primary"
      size="large"
      startIcon={<DownloadIcon />}
      onClick={handleDownload}
      style={{
        backgroundColor: '#08c725',
        color: '#fff',
        padding: '10px 20px',
        fontSize: '16px',
        borderRadius: '8px',
        textTransform: 'none',
        marginBottom: '20px',
      }}
    >
  Free DownLoad Sonic Games for Windows
    </Button>
</div>
 

<p  style={{ paddingLeft: '5px' }}>
              If the application is not installed on your Windows system, click the button below to proceed with the installation.
            </p>


<div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}> 

    <Button
      variant="contained"
      color="primary"
      size="large"
      startIcon={<DownloadIcon />}
      
      style={{
        backgroundColor: '#0078D4',
        color: '#fff',
        padding: '10px 20px',
        marginLeft: '5px',
        fontSize: '16px',
        borderRadius: '8px',
        textTransform: 'none',
        marginBottom: '20px',
      }}
    >
  Offical Buy Games Teaser AEP
    </Button>

</div>


      </div>
      </div>
      <div className="empty-area"></div>
    </div>
  <Footer />
  </div>
  );
}

export default SonicGames;
