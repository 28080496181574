import React from 'react';
import TopNavbar from '../../HeaderAndFooter/TopHeader';
import LogoComponent from '../../HeaderAndFooter/PageLogo';
import SocialMediaSidebar from '../../MenuBar/SocialMediaSidebar';
import MenuBar from '../../MenuBar/MenuBar';
import Footer from '../../HeaderAndFooter/Footer';
import '../css/NukeStudioOverview.css';
import { Button } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';



const CyberLinkColorDirector2024= () => {



    const handleDownload = () => {
      // Your download logic here
      window.location.href = 'https://getintopc.com/softwares/multimedia/cyberlink-colordirector-ultra-2024-free-download/'; // Example link
    };





  return (
    <div class="background-image">
   <TopNavbar />
   <LogoComponent />
   <SocialMediaSidebar />


    <div  className="main-layout" >
    <MenuBar />
    </div>

    <div className="container" style={{ marginTop: '50px' }}>
      <div className="text-area">
    <div className='text-mainArea'> 
    <h1 style={{ paddingLeft: '30px' , color: '#0fbf5f', marginTop: '20px'  }}>Overview of CyberLink ColorDirector 2024</h1>
      <p style={{ paddingLeft: '5px' }}>
      CyberLink ColorDirector 2024 is a powerful color adjustment and correction software. Designed for professionals and video editing enthusiasts. This application helps users enhance the visual appeal of their videos by using advanced color correction techniques to create a stunning look.
      </p>

      <h2 style={{ paddingLeft: '20px' , color: '#f49c0b' }}>Key Features</h2>
      <ul className="features-list">
        <li>
          <strong style={{  color: '#0c6dc9' }}>Advanced Color Correction:</strong> Comprehensive tools for adjusting exposure, contrast, saturation, and more.
        </li>
        <li>
          <strong style={{  color: '#0c6dc9' }}>Color Wheels and Curves:</strong> Precise control over color grading with shadow, midtone, and highlight adjustments.
        </li>
        <li>
          <strong style={{  color: '#0c6dc9' }}>Color Presets and LUTs:</strong> A library of professional color presets and lookup tables (LUTs) for cinematic looks.
        </li>
        <li>
          <strong style={{  color: '#0c6dc9' }}>Motion Tracking:</strong> Apply color effects to specific objects or areas within a moving scene.
        </li>
        <li>
          <strong style={{  color: '#0c6dc9' }}>Skin Tone Enhancement:</strong> Tools designed for natural skin tone adjustments in every shot.
        </li>
        <li>
          <strong style={{  color: '#0c6dc9' }}>Selective Color Adjustment:</strong> Adjust colors in specific areas to highlight or de-emphasize elements.
        </li>
        <li>
          <strong style={{  color: '#0c6dc9' }}>Real-Time Preview:</strong> View changes in real-time for an efficient workflow.
        </li>
        <li>
          <strong style={{  color: '#0c6dc9' }}>Multi-Cam Support:</strong> Grade and correct colors across multiple camera angles in a single project.
        </li>
        <li>
          <strong style={{  color: '#0c6dc9' }}>Integration with Other CyberLink Products:</strong> Seamless integration with applications like PowerDirector.
        </li>
        <li>
          <strong style={{  color: '#0c6dc9' }}>Export Options:</strong> Variety of export formats for easy sharing across platforms.
        </li>
      </ul>

      <h2 style={{ paddingLeft: '20px' , color: '#f49c0b' }}>Use Cases</h2>
      <ul className="use-cases-list">
        <li><strong style={{  color: '#0c6dc9' }}>Film Production:</strong> Achieve professional-level color grading in film projects.</li>
        <li><strong style={{  color: '#0c6dc9' }}>YouTube and Content Creation:</strong> Enhance the visual quality of videos for better engagement.</li>
        <li><strong style={{  color: '#0c6dc9' }}>Corporate Videos:</strong> Produce polished promotional materials and training videos.</li>
        <li><strong style={{  color: '#0c6dc9' }}>Music Videos:</strong> Create visually stunning music videos that stand out.</li>
      </ul>


      <img 
        src='/images/MultiMedia/CyberLink ColorDirector 2024pic1.jpg' 
        alt="Windows 11 Logo" 
        style={{
          width: '600px',
          height: '400px',
          display: 'block', // Ensures block-level behavior for centering
          marginLeft: 'auto', 
          marginRight: 'auto' ,
        }} 
      />





      <h2 style={{ paddingLeft: '20px' , color: '#f49c0b', marginTop: '20px' }}>Conclusion</h2>
      <p style={{ paddingLeft: '5px' }}>
      CyberLink ColorDirector 2024 is an essential tool for video editing and creation. Advanced color grading features and an easy-to-use interface make it a powerful choice for improving the visual quality of your video projects. Be it professional filmmaking or personal content. ColorDirector Gives you the tools you need for stunning visual results.
      </p>

      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}> 
      <Button
      variant="contained"
      color="primary"
      size="large"
      startIcon={<DownloadIcon />}
      onClick={handleDownload}
      style={{
        backgroundColor: '#0078D4',
        color: '#fff',
        padding: '10px 20px',
        fontSize: '16px',
        borderRadius: '8px',
        textTransform: 'none',
      }}
    >
      Free Download CyberLink ColorDirector 2024
    </Button>


</div>

<p style={{ paddingLeft: '5px' }}>
              If the application is not installed on your Windows system, click the button below to proceed with the installation.
            </p>


<div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}> 

    <Button
      variant="contained"
      color="primary"
      size="large"
      startIcon={<DownloadIcon />}
     
      style={{
        backgroundColor: '#47f40b',
        color: '#fff',
        padding: '10px 20px',
        marginLeft: '5px',
        fontSize: '16px',
        borderRadius: '8px',
        textTransform: 'none',
        marginBottom: '20px',
      }}
    >
  Offical Buy Games Teaser AEP
    </Button>

</div>



      </div>
      </div>
      <div className="empty-area"></div>
    </div>
  <Footer />
  </div>
  );
}

export default CyberLinkColorDirector2024;
