import React, { useState } from 'react';
import '../css/ContactUs.css'; // Import the CSS file for styling
import TopNavbar from './TopHeader';
import LogoComponent from './PageLogo';
import SocialMediaSidebar from '../MenuBar/SocialMediaSidebar';
import MenuBar from '../MenuBar/MenuBar';
import Footer from './Footer';




const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here (e.g., send to API or email)
    console.log('Form submitted:', formData);
    // Reset form after submission
    setFormData({ name: '', email: '', message: '' });
  };

  return (

    <div class="background-image">
    <TopNavbar />
    <LogoComponent/>
    <SocialMediaSidebar />
 
 
     <div  className="main-layout" style={{ marginTop: '10px' }}>
     <MenuBar />
     </div>
    
    <div className="contact-container">
      <h1 className="contact-title">Contact Us</h1>
      <form className="contact-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="message">Message:</label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
          ></textarea>
        </div>
        <button type="submit" className="submit-button">Send Message</button>
      </form>
    </div>

    <Footer />
    </div>
  );
};

export default ContactUs;
